import { Phone, List, MessageCircle, Mail, FileText } from 'react-feather';
import { CLIENT_INTERACTIONS_TYPES, COMMUNICATION_TYPES } from 'core/utils/constants';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCommunication, visitInteraction } from 'redux/ui/clientInteractionDrawer/reducer';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { loadCallById } from 'redux/ui/clientInteractionPage/operations';
import { getCommunicationType, getLink } from '../core/utils/getCommunicationType';

const StyledLink = styled(Link)`
  color: ${props => (props.visited ? 'var(--magenta_primary)' : 'var(--blue_6)')};
`;

const typeToIcon = {
  [COMMUNICATION_TYPES.TEXT]: MessageCircle,
  [COMMUNICATION_TYPES.PHONE_CALL]: Phone,
  [COMMUNICATION_TYPES.CUSTOM]: List,
  [CLIENT_INTERACTIONS_TYPES.TICKET]: FileText,
  [CLIENT_INTERACTIONS_TYPES.EMAIL]: Mail,
  [CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN]: List,
  [CLIENT_INTERACTIONS_TYPES.CHAT]: MessageCircle,
  empty: null
};

export const typeToRoute = {
  [CLIENT_INTERACTIONS_TYPES.CHAT]: 'text-communications',
  [CLIENT_INTERACTIONS_TYPES.TICKET]: 'text-communications',
  [CLIENT_INTERACTIONS_TYPES.EMAIL]: 'text-communications',
  [COMMUNICATION_TYPES.TEXT]: 'text-communications',
  [COMMUNICATION_TYPES.PHONE_CALL]: 'calls',
  [COMMUNICATION_TYPES.COMMUNICATION_CHAIN]: 'calls',
  [COMMUNICATION_TYPES.CUSTOM]: 'review',
  empty: ''
};

const ClientInteractionLink = ({
  id,
  isReview,
  communication,
  useDrawer = true,
  checklistDefinitionId,
  link = null,
  style = {},
  showIcon = true,
  showChild = false,
  children = null,
  source = null,
  isNewPage = false // open url in new tab
}) => {
  const dispatch = useDispatch();
  const { communicationType, clientInteractionType } = communication;

  let isReview_;

  const Icon =
    clientInteractionType === COMMUNICATION_TYPES.CUSTOM
      ? typeToIcon[COMMUNICATION_TYPES.CUSTOM]
      : typeToIcon[communicationType || clientInteractionType || COMMUNICATION_TYPES.PHONE_CALL];

  const query = checklistDefinitionId ? `checklistDefinitionId=${checklistDefinitionId}` : '';

  const currentLocation = window.location.href;
  const isCalibrationsPage =
    currentLocation.includes('/calibrations/folders') || currentLocation.includes('/calibration/');

  if (source && source.includes('/calibration/')) {
    isReview_ = 1;
  }

  const visitedInteractions = useSelector(
    state => state.uiClientInteractionDrawer.visitedInteractions
  );

  const clientInteractionsResource = useSelector(state => state.clientInteractionsResource.byIds);

  const visited = visitedInteractions.includes(id);

  const navigationLink = getLink(
    link,
    communicationType,
    clientInteractionType,
    id,
    isReview,
    query
  );

  const redirectTo = () => {
    if (communicationType === 'communication_chain' && !isReview) {
      return `/communication-chains/${id}`;
    }
    if (communicationType === 'communication_chain' && isReview) {
      return `reviews/${id}`;
    }
    if (clientInteractionType === 'custom_communication' && isCalibrationsPage) {
      return `/text-communications/${id}`;
    }
    return navigationLink;
  };

  const onLinkClick = useCallback(
    e => {
      if (isNewPage) {
        dispatch(visitInteraction(id));
        return window.open(navigationLink, '_blank');
      }
      if (!e.ctrlKey && !e.metaKey && useDrawer) {
        e.preventDefault();

        if (!isEmpty(clientInteractionsResource)) {
          for (const key in clientInteractionsResource) {
            if (clientInteractionsResource.hasOwnProperty(key)) {
              const innerObject = clientInteractionsResource[key];
              if (!!innerObject?.reviewsIds && innerObject.reviewsIds.includes(id)) {
                isReview_ = null;
              }
            }
          }
        } else {
          loadCallById(id);
        }

        const type = getCommunicationType(
          communicationType,
          clientInteractionType,
          isReview,
          isReview_
        );

        dispatch(setCommunication({ type, id }));
      }
      dispatch(visitInteraction(id));
    },
    [dispatch, isReview, useDrawer, id]
  );

  return (
    <StyledLink
      to={redirectTo}
      onClick={onLinkClick}
      visited={visited ? 'true' : undefined}
      style={style}
      target={isNewPage ? '_blank' : '_self'}
    >
      {showIcon && <Icon />}
      {showChild && children}
    </StyledLink>
  );
};

export default ClientInteractionLink;
