export default {
  communicationChain: {
    createChainModal: {
      title: 'Catene di comunicazione',
      cancel: 'Annulla',
      create: 'Crea',
      ok: 'OK',
      successCreate: 'Catena di comunicazione creata con successo',
      add: 'Aggiungi',
      successAdd: 'Catena di comunicazione aggiornata con successo',
      selectedCommunications: 'Comunicazioni selezionate',
      resultIsCalculated: 'Il risultato del controllo sta essere calcolato',
      bulkActionCheckModal: {
        title: 'Crea catena di comunicazione',
        cancelTextModal: 'Sei sicuro di voler annullare la creazione della catena di comunicazione?'
      },
      columns: {
        operator: {
          title: 'Nome completo'
        },
        direction: {
          title: 'Direzione'
        },
        duration: {
          title: 'Durata'
        },
        clientPhoneNumber: {
          title: 'Numero di telefono'
        },
        score: {
          title: 'Punteggio'
        }
      },
      fields: {
        communicationChainId: {
          tooltip:
            'Questo campo è facoltativo. Se necessario, puoi selezionare la CC richiesta a cui verranno aggiunte le comunicazioni selezionate.',
          placeholder: 'Seleziona CC'
        },
        clientPhoneNumber: {
          placeholder: 'Numero di catena',
          emptyFieldMessage: 'Per favore, inserisci il numero di catena'
        },
        operator: {
          placeholder: 'Impiegato',
          emptyFieldMessage: 'Per favore, seleziona un impiegato'
        },
        startedAt: {
          placeholder: 'Data della comunicazione',
          emptyFieldMessage: 'Per favore, seleziona la data della comunicazione'
        },
        duration: {
          placeholder: 'Durata',
          emptyFieldMessage: 'Per favore, seleziona la durata'
        }
      }
    },
    infoMessages: {
      notEnoughCommunications:
        'Per creare una catena di comunicazione, sono necessarie almeno due comunicazioni.',
      numberAlreadyExists: 'Una catena di comunicazione con questo numero esiste già.'
    },
    chainPage: {
      removeCommunicationModal: {
        title: 'Rimuovere la comunicazione dalla catena?',
        ok: 'Elimina',
        cancel: 'Annulla',
        messages: {
          deleteSuccess: 'Comunicazione eliminata'
        }
      },
      columns: {
        direction: {
          title: 'Direzione'
        },
        duration: {
          title: 'Durata'
        }
      }
    }
  }
};
