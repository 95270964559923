import { Alert, Col, Row, Select } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useEffect, useRef, useState } from 'react';
import { SYNC_DATA_PERIODS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import SButton from 'components/Standard/SButton';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Option } = Select;

const SPECIAL_TYPES = new Set(['amocrm', 'naumen', 'mango', 'edna', 's2', 'pyrus', 'sipuni']);

const SyncPeriod = ({
  disabled,
  defaultValue,
  onOk,
  okText,
  type = null,
  okProps = {},
  syncAtTime = '',
  integrationId = null
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const integrationsResource = useSelector(state => state.integrationsResource.byIds);
  const integration = Object.values(integrationsResource).find(item => item.id === integrationId);
  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);
  const handleOk = async () => {
    setLoading(true);
    try {
      await onOk(value);
    } finally {
      if (mounted.current) {
        setLoading(false);
        if (integrationId) {
          history.push(
            `/user/${currentUserId}/integrations-settings/${integration?.integrationType}/${integrationId}`
          );
        }
      }
    }
  };

  const getAlertMessage = () => {
    if (disabled) {
      return SPECIAL_TYPES.has(type)
        ? {
            message: t('integrationsSettingsPage.infoAlert.syncAlreadyInProgress')
          }
        : {
            message: t('integrationsSettingsPage.infoAlert.message'),
            description: t('integrationsSettingsPage.infoAlert.description', { time: syncAtTime })
          };
    }
    if (SPECIAL_TYPES.has(type)) {
      return {
        message: t('integrationsSettingsPage.infoAlert.setTheRange')
      };
    }
    return null;
  };

  const infoMessage = getAlertMessage();

  return (
    <SCard>
      {infoMessage && (
        <Alert
          style={{ marginBottom: '16px' }}
          showIcon
          type="info"
          message={infoMessage.message}
          description={infoMessage.description}
        />
      )}

      <Row align="middle" justify="space-between">
        <Col>
          <Select
            size="large"
            style={{ width: '380px' }}
            disabled={disabled}
            onChange={setValue}
            value={value}
            placeholder={t('integrationsSettingsPage.selectSyncPeriod')}
          >
            {Object.values(SYNC_DATA_PERIODS).map(period => (
              <Option value={period.key} key={period.key}>
                {t(period.name)}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <SButton
            width="230px"
            type="primary"
            size="big"
            onClick={handleOk}
            loading={loading}
            disabled={!value || disabled}
            {...okProps}
          >
            {okText}
          </SButton>
        </Col>
      </Row>
    </SCard>
  );
};

export default SyncPeriod;
