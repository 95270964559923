import createListReducer from 'core/redux/lists/listReducer';
import { userFieldsAnalyticsCommunicationsResource } from 'redux/resources/userFieldsAnalyticsCommunications';

const defaultState = {
  page: {
    size: '10',
    number: '1'
  }
};

export const listParams = {
  name: 'userFieldsAnalyticsCommunicationsList',
  loader: userFieldsAnalyticsCommunicationsResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer({ ...listParams, defaultState });

const userFieldsAnalyticsCommunicationsListReducer = reducer;

export { actions, userFieldsAnalyticsCommunicationsListReducer, operations };
