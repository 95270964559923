import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SCol from 'components/Standard/SCol';
import { actions } from 'redux/lists/datasetList';
import { selectSearch } from 'core/utils/selectSearch';
import SCard from './SCard';
import SSelect from './SSelect';
import { HeaderItems } from './styled';
import SButton from './SButton';
import ModalDataset from './ModalDataset';
import QolioAiIcon from '../../components/QolioAiIcon';

const { onDatasetChangePagination } = actions;

const { Option } = SSelect;

const HeaderDatasetAi = ({
  isModalOpen,
  setIsModalOpen,
  searchText,
  setSearchText,
  setCreatedByText
}) => {
  const { t } = useTranslation();
  const usersByIds = useSelector(state => state.usersResource.byIds);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleInputChange = e => {
    dispatch(onDatasetChangePagination());
    setSearchText(e.target.value.toLowerCase());
  };

  return (
    <>
      <SCol span={24}>
        <SCard shadowed bordered>
          <HeaderItems>
            <div>
              <Input
                value={searchText}
                onChange={handleInputChange}
                // onKeyPress={handleKeyPress}
                style={{ width: 180, height: 32, marginRight: '24px' }}
                placeholder={t('datasetAiPage.placeholders.name')}
              />
              <SSelect
                size="middle"
                allowClear
                showSearch
                optionLabelProp="label"
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
                placeholder={t('datasetAiPage.placeholders.createdBy')}
                onChange={createdBy => {
                  setCreatedByText(createdBy);
                  dispatch(onDatasetChangePagination());
                }}
              >
                {Object.values(usersByIds).map(item => (
                  <Option value={item.id} key={item.id} label={item.name}>
                    {item.name}
                  </Option>
                ))}
              </SSelect>
            </div>
            <div>
              <SButton
                type="primary"
                size="large"
                onClick={showModal}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {t('datasetAiPage.buttons.create')}
                <QolioAiIcon styles={{ marginLeft: '5px', paddingBottom: '3px' }} alwaysShow />
              </SButton>
            </div>
          </HeaderItems>
        </SCard>
      </SCol>
      <ModalDataset isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default HeaderDatasetAi;
