import { post } from 'redux-bees';

const communicationChainsV3Endpoints = apiPath => ({
  getCommunicationChains: {
    method: post,
    path: `${apiPath}/bulk_action/get`
  }
});

export default communicationChainsV3Endpoints;
