import { createAction, createReducer } from '@reduxjs/toolkit';
import { moveItemInArray } from 'core/utils/dnd';
import { findIndex, uniq } from 'lodash';
import { listParams } from 'redux/lists/clientInteractionsList';
import { getCollapsedGroups } from 'redux/ui/clientInteractions/operations';

const defaultState = {
  expandedTableRowsKeys: [],
  searchCategory: undefined,
  searchState: { results: {}, length: 0, showResult: false, value: '' },
  isVisibleDrawer: false,
  isVisibleShareSettings: false,
  isVisibleAddColumnBlock: false,
  tableColumns: [],
  tableSharedUsers: [],
  tableRows: [],
  tableFilters: listParams.defaultState.filters,
  tableCustomFieldFilters: listParams.defaultState.customFieldFilters,
  tableLoading: false,
  collapsedFilterGroups: getCollapsedGroups(),
  meta: {},
  loadingMeta: false,
  axiosToken: null
};

export const toogleVisibleDrawer = createAction('uiClientInteractionsTable/toogleVisibleDrawer');
export const toogleVisibleShareSettings = createAction(
  'uiClientInteractionsTable/toogleVisibleShareSettings'
);
export const toogleVisibleAddColumnBlock = createAction(
  'uiClientInteractionsTable/toogleVisibleAddColumnBlock'
);
export const setTableRows = createAction('uiClientInteractionsTable/setTableRows');
export const setTableLoading = createAction('uiClientInteractionsTable/setTableLoading');
export const updateTableRow = createAction('uiClientInteractionsTable/updateTableRow');

export const setActiveTable = createAction('uiClientInteractionsTable/setActiveTable');
export const updateActiveTable = createAction('uiClientInteractionsTable/updateActiveTable');

export const addColumn = createAction('uiClientInteractionsTable/addColumn');
export const deleteColumn = createAction('uiClientInteractionsTable/deleteColumn');

export const moveTableColumn = createAction('uiClientInteractionsTable/moveTableColumn');
export const setUsersIdsToShare = createAction('uiClientInteractionsTable/setUsersIdsToShare');
export const expandTableRow = createAction('uiClientInteractionsTable/expandTableRow');
export const saveTableFilters = createAction('uiClientInteractionsTable/saveTableFilters');
export const setSearchCategory = createAction('uiClientInteractionsTable/setSearchCategory');
export const setSearchState = createAction('uiClientInteractionsTable/setSearchState');

export const setDefaultState = createAction('uiClientInteractionsTable/setDefaultState');

export const toogleFilterGroup = createAction('uiClientInteractionsTable/toogleFilterGroup');

export const setTableMeta = createAction('uiClientInteractionsTable/setTableMeta');

export const updateAxiosToken = createAction('uiClientInteractionPage/updateAxiosToken');
export const setLoadingMeta = createAction('uiClientInteractionPage/setLoadingMeta');

export const uiClientInteractionsReducer = createReducer(defaultState, {
  [toogleVisibleDrawer]: state => ({
    ...state,
    isVisibleDrawer: !state.isVisibleDrawer
  }),
  [toogleVisibleShareSettings]: state => ({
    ...state,
    isVisibleShareSettings: !state.isVisibleShareSettings
  }),
  [toogleVisibleAddColumnBlock]: state => ({
    ...state,
    isVisibleAddColumnBlock: !state.isVisibleAddColumnBlock
  }),
  [setTableRows]: (state, { payload }) => ({ ...state, tableRows: payload }),
  [updateTableRow]: (state, { payload }) => {
    const rows = [...state.tableRows];
    const index = findIndex(rows, { id: payload.id });
    rows.splice(index, 1, { ...rows[index], ...payload });
    return { ...state, tableRows: rows };
  },

  [setTableLoading]: (state, { payload }) => ({ ...state, tableLoading: payload }),
  [setActiveTable]: (state, { payload }) => {
    const { id, name, columns, usersIds, filters, customFieldFilters } = payload;
    return {
      ...state,
      tableId: id,
      tableName: name,
      tableColumns: columns || [],
      tableSharedUsers: usersIds || [],
      tableFilters: filters || {},
      tableCustomFieldFilters: customFieldFilters || {}
    };
  },
  [updateActiveTable]: (state, { payload }) => ({
    ...state,
    ...payload
  }),
  [addColumn]: (state, { payload }) => {
    return {
      ...state,
      tableColumns:
        payload === 'checkboxColumn'
          ? [payload, ...state.tableColumns]
          : state.tableColumns.concat(payload)
    };
  },
  [deleteColumn]: (state, { payload }) => {
    const tableColumns = [...state.tableColumns];
    return {
      ...state,
      tableColumns: tableColumns.filter(column => column !== payload)
    };
  },
  [moveTableColumn]: (state, { payload }) => {
    const tableColumns = moveItemInArray(state.tableColumns, payload.from, payload.to);
    return {
      ...state,
      tableColumns
    };
  },
  [expandTableRow]: (state, { payload }) => {
    const id = payload;
    const rowsKeys = !state.expandedTableRowsKeys.includes(id)
      ? uniq([...state.expandedTableRowsKeys, id])
      : [...state.expandedTableRowsKeys].filter(target => target !== id);

    return { ...state, expandedTableRowsKeys: rowsKeys };
  },
  [saveTableFilters]: (state, { payload }) => ({
    ...state,
    tableFilters: payload.filters,
    tableCustomFieldFilters: payload.customFieldFilters
  }),
  [setSearchCategory]: (state, { payload }) => ({ ...state, searchCategory: payload }),
  [setSearchState]: (state, { payload }) => ({
    ...state,
    searchState: payload
  }),
  [setDefaultState]: () => defaultState,
  [toogleFilterGroup]: (state, { payload }) => {
    const activeGroups = state.collapsedFilterGroups;
    const groups = activeGroups.includes(payload)
      ? activeGroups.filter(item => item !== payload)
      : [...activeGroups, payload];

    try {
      localStorage.setItem('collapsedFilterGroups', JSON.stringify(groups));
    } catch (error) {
      console.log(error);
    }

    return {
      ...state,
      collapsedFilterGroups: groups
    };
  },
  [setTableMeta]: (state, { payload = {} }) => ({
    ...state,
    loadingMeta: false,
    meta: { ...state.meta, ...payload }
  }),
  [updateAxiosToken]: (state, { payload }) => ({ ...state, axiosToken: payload }),
  [setLoadingMeta]: (state, { payload }) => ({ ...state, loadingMeta: payload })
});
