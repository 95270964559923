import { useSelections } from 'ahooks';
import { Skeleton, Tooltip, Col } from 'antd';
import Search from 'components/Inputs/Search';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import { SListItem } from 'components/Standard/SList';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SButton from 'components/Standard/SButton';
import { FixedSizeList } from 'react-window';
import {
  connectCustomField,
  disconnectCustomField,
  getEntityCustomFields,
  connectAllCustomFields
} from 'redux/entities/amoIntegration/operations';
import {
  connectAllCustomFieldsNaumen,
  connectCustomFieldNaumen,
  disconnectCustomFieldNaumen,
  getEntityCustomFieldsNaumen
} from 'redux/entities/naumenIntegration/operations';
import {
  connectAllCustomFieldsEdna,
  connectCustomFieldEdna,
  disconnectCustomFieldEdna,
  getEntityCustomFieldsEdna
} from 'redux/entities/ednaIntegration/operations';
import {
  connectAllCustomFieldsPyrus,
  connectCustomFieldPyrus,
  disconnectCustomFieldPyrus,
  getEntityCustomFieldsPyrus
} from 'redux/entities/pyrus/operations';
import { useLocation } from 'react-router-dom';
import { getCurrentUser } from 'redux/selectors/users';
import { debounce } from 'lodash';

const LIMIT_SELECTED_FIELDS = 99999999;

const AmoFields = ({ integrationId, onOk = null, integration }) => {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const [searchFields, setSearchFields] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUserId = useSelector(state => getCurrentUser(state)?.id);
  const { selected, isSelected, toggle, setSelected } = useSelections([], []);
  const [isAllSelected, setIsAllSelected] = useState(0);
  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const [integrationType, setIntegrationType] = useState(
    integration?.integrationType || urlParams[urlParams.length - 3]
  );
  const integrationFunctions = {
    amocrm: {
      getFields: getEntityCustomFields,
      connect: connectCustomField,
      disconnect: disconnectCustomField,
      connectAll: connectAllCustomFields
    },
    naumen: {
      getFields: getEntityCustomFieldsNaumen,
      connect: connectCustomFieldNaumen,
      disconnect: disconnectCustomFieldNaumen,
      connectAll: connectAllCustomFieldsNaumen
    },
    edna: {
      getFields: getEntityCustomFieldsEdna,
      connect: connectCustomFieldEdna,
      disconnect: disconnectCustomFieldEdna,
      connectAll: connectAllCustomFieldsEdna
    },
    pyrus: {
      getFields: getEntityCustomFieldsPyrus,
      connect: connectCustomFieldPyrus,
      disconnect: disconnectCustomFieldPyrus,
      connectAll: connectAllCustomFieldsPyrus
    },
    default: {
      getFields: getEntityCustomFields,
      connect: connectCustomField,
      disconnect: disconnectCustomField,
      connectAll: connectAllCustomFields
    }
  };
  const integrationTypeFunctions =
    integrationFunctions[integrationType] || integrationFunctions.default;

  useEffect(() => {
    const loadFields = async () => {
      setLoading(true);
      const fields = await dispatch(
        integrationTypeFunctions.getFields({ id: integrationId, location, currentUserId })
      );

      setFields(Object.values(fields));
      setSearchFields(Object.values(fields));
      setSelected(
        Object.values(fields)
          .filter(field => field.connected)
          .map(field => field.id)
      );
      setLoading(false);

      const countConnectedTrue = Object.values(fields).reduce((count, item) => {
        return count + (item.connected ? 1 : 0);
      }, 0);
      setIsAllSelected(countConnectedTrue === Object.keys(fields).length);
    };

    if (integrationId) {
      loadFields();
    }
  }, [integrationId]);

  const getTooltip = field => {
    if (!field.supported) {
      return t('integrationsSettingsPage.unsupportedField');
    }
  };

  const handleToggle = async field => {
    if (!field.supported) return;

    try {
      await dispatch(
        selected.includes(field.id)
          ? integrationTypeFunctions.disconnect({ id: integrationId, fieldId: field.id })
          : integrationTypeFunctions.connect({ id: integrationId, fieldId: field.id })
      );
      toggle(field.id);

      if (selected.length === fields.length) {
        setIsAllSelected(false);
      }
      if (!selected.includes(field.id) && fields.length === selected.length + 1) {
        setIsAllSelected(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const renderItem = (field, style) => {
    return (
      <SListItem style={style} borderBottom="1px solid var(--gray-border)">
        <SCol span={24} style={{ padding: '0' }}>
          <SRow type="flex" justify="space-between" align="middle">
            <SCol span={1}>
              <Tooltip title={getTooltip(field)}>
                <Checkbox
                  disabled={
                    !field.supported ||
                    (!isSelected(field.id) && LIMIT_SELECTED_FIELDS === selected.length)
                  }
                  checked={isSelected(field.id)}
                  onClick={() => handleToggle(field)}
                />
              </Tooltip>
            </SCol>
            <SCol span={9}>
              <SText {...(!field.supported && { type: 'secondary' })}>{field.name}</SText>
            </SCol>
            <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
              <SRow type="flex" style={{ maxWidth: '100%' }}>
                <SText {...(!field.supported && { type: 'secondary' })} className="truncated">
                  {field.type || field.entityType}
                </SText>
              </SRow>
            </SCol>
          </SRow>
        </SCol>
      </SListItem>
    );
  };

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  const toggleAll = async () => {
    await dispatch(integrationTypeFunctions.connectAll({ id: integrationId, all: !isAllSelected }));

    setIsAllSelected(!isAllSelected);
    if (isAllSelected) {
      setSelected([]);
    } else {
      const ids = fields.map(field => field.id);
      setSelected(ids);
      // setSelected(decideSelectedAccountsIds(userAccounts, matchesAndCollisions, true));
    }
  };
  const onValuesChange = debounce(searchDebounce => {
    setSearchFields(
      fields
        .filter(item => item.name.toLowerCase().includes(searchDebounce.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name))
    );
  }, 250);

  return (
    <SCard bordered shadowed bodyPadding="24px">
      <SRow gutter={[0, 16]} style={{ marginBottom: '-16px' }}>
        <SCol span={24}>
          <SRow align="middle" justify="space-between">
            <SCol>
              <SRow align="middle" gutter={[16, 0]}>
                <Col>
                  <Search onSearch={onValuesChange} />
                </Col>
                <Col>
                  <Checkbox checked={isAllSelected === true} onClick={toggleAll}>
                    {t('integrationsSettingsPage.manageUserAccounts.selectAll')}
                  </Checkbox>
                </Col>
                <SCol>
                  <SText type="secondary">{`${t('general.selected')}: ${selected.length}`}</SText>
                </SCol>
                {onOk && (
                  <SButton
                    width="120px"
                    type="primary"
                    size="middle"
                    marginLeft="80px"
                    onClick={onOk}
                    // loading={loading}
                    // disabled={!value || disabled}
                    // {...okProps}
                  >
                    Продолжить
                  </SButton>
                )}
              </SRow>
            </SCol>
          </SRow>
        </SCol>
        <SCol span={24}>
          <SListItem borderBottom="1px solid var(--gray-border)">
            <SCol span={24} style={{ padding: '0' }}>
              <SRow type="flex" justify="space-between" align="middle">
                <SCol span={1} />
                <SCol span={9}>
                  <SText strong>
                    {t('integrationsSettingsPage.integrationPage.integrationFieldsList.name')}
                  </SText>
                </SCol>
                <SCol span={7} display="flex" justifyContent="flex-start" flex="auto">
                  <SText strong>
                    {t('integrationsSettingsPage.integrationPage.integrationFieldsList.type')}
                  </SText>
                </SCol>
              </SRow>
            </SCol>
          </SListItem>

          <FixedSizeList itemSize={56} itemCount={searchFields.length} height={500}>
            {({ index, style }) => renderItem(searchFields[index], style)}
          </FixedSizeList>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default AmoFields;
