import React, { useState } from 'react';
import { Form, Typography, Space, Button, Select, Input, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { useSelections } from 'ahooks';
import { CloseOutlined } from '@ant-design/icons';
import SCol from '../../../../components/Standard/SCol';
import { SCard } from '../../../../components/Standard';
import AddUsersAdminModal from '../../../../components/AddUsersAdminModal';
import SRow from '../../../../components/Standard/SRow';
import SButton from '../../../../components/Standard/SButton';
import NotificationsCustomFieldsTg from './NotificationsCustomFieldsTG/NotificationsCustomFieldsTg';
import { StyledSelect } from '../../../DashboardPage/styled';
import { selectSearch } from '../../../../core/utils/selectSearch';
import RadioItem from './NotificationsCustomFieldsTG/shared/RadioItem';

const { Text } = Typography;

const NotificationsForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { ids } = useSelector(state => state.usersToUnitList, isEqual);
  const { allSelected, selected, isSelected, toggle, setSelected } = useSelections(ids, []);
  const [usersCount, setUserCount] = useState(0);
  const [showUsersModal, setShowUsersModal] = useState(false);

  const onAddUsers = usersIds => {
    setUserCount(usersIds.length);
    setShowUsersModal(false);
  };

  const onFinish = values => {
    console.log(values);
  };

  const onReset = () => {
    form.setFieldsValue({ groups: [] });
    setTimeout(() => form.resetFields(), 0);
  };

  const handleEventChange = () => {
    form.setFieldsValue({ 
      groups: [],
      parametersNotification: []
    });
  };

  return (
    <SCard>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Название конфигурации"
          name="configName"
          style={{ width: '30%' }}
          initialValue="Конфигурация"
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          name="email"
          label={<Text strong>{t('general.email')}</Text>}
          initialValue={false}
        >
          <RadioItem />
        </Form.Item>

        <Form.Item name="telegram" label={<Text strong>Telegram</Text>} initialValue={false}>
          <RadioItem />
        </Form.Item>

        <Form.Item name="event" label="Список событий">
          <SCol span={7}>
            <StyledSelect
              allowClear
              placeholder="Выберите события"
              size="middle"
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
              onChange={handleEventChange}
            >
              <Select.Option value="addReview">Проверка добавлена</Select.Option>
              <Select.Option value="editReview">Проверка изменена</Select.Option>
              <Select.Option value="editCommunication">Коммуникация изменена</Select.Option>
            </StyledSelect>
          </SCol>
        </Form.Item>

        <Form.Item name="users">
          <SRow type="flex" align="middle" justify="space-between" style={{ marginBottom: 8 }}>
            <SCol span={8}>
              <Text>{t('Выбрать сотрудников')}</Text>
            </SCol>
            <SCol span={16}>
              <SCol
                border={`1px solid var(${usersCount === 0 ? '--red_primary' : '--gray-border'})`}
                borderRadius="4px"
                height="40px"
                style={{ padding: '0 4px 0 11px' }}
              >
                <SRow
                  justify="space-between"
                  flexWrap="no-wrap"
                  align="middle"
                  gutter={[20, 0]}
                  height="100%"
                >
                  <SCol style={{ paddingRight: 0 }}>
                    {`${t('general.selected')}: ${usersCount}`}
                  </SCol>
                  <SCol style={{ paddingLeft: 0 }}>
                    <SButton type="primary" onClick={() => setShowUsersModal(true)}>
                      {t('workPlanTaskConfigurationEditor.filters.add')}
                    </SButton>
                  </SCol>
                </SRow>
              </SCol>
            </SCol>
          </SRow>
        </Form.Item>

        <Form.List name="groups">
          {(fields, { add, remove }) => (
            <div
              style={{
                display: 'flex',
                rowGap: 16,
                flexDirection: 'column'
              }}
            >
              {fields.map(field => (
                <React.Fragment key={field.key}>
                  <Card
                    size="small"
                    title={
                      <Form.Item
                        label="Название группы"
                        name={[field.name, 'groupName']}
                        style={{ width: '30%', padding: 0, margin: 0 }}
                        initialValue={`Группа ${field.name + 1}`}
                      >
                        <Input />
                      </Form.Item>
                    }
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <NotificationsCustomFieldsTg field={field} form={form} />
                  </Card>
                  {fields.length > 1 && field?.fieldKey < fields.length - 1 && (
                    <SRow justifyContent="center">ИЛИ</SRow>
                  )}
                </React.Fragment>
              ))}
              <Button type="dashed" onClick={() => add()} block>
                + Добавить группу
              </Button>
            </div>
          )}
        </Form.List>

        <Form.Item style={{ paddingTop: 16 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
            <Button htmlType="button" onClick={onReset}>
              Сбросить
            </Button>
          </Space>
        </Form.Item>
      </Form>
      <AddUsersAdminModal
        visible={showUsersModal}
        onCancel={() => setShowUsersModal(false)}
        onAdd={onAddUsers}
        title="Сотрудники"
        selectedUsersIds={[]}
        allSelected={allSelected}
        selected={selected}
        isSelected={isSelected}
        toggle={toggle}
        setSelected={setSelected}
      />
    </SCard>
  );
};

export default NotificationsForm;
