export function customDecamelize(str) {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/([a-zA-Z])(\d)/g, '$1_$2')
    .replace(/(\d)([a-zA-Z])/g, '$1_$2')
    .toLowerCase();
}

export function customDecamelizeObjectKeys(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [customDecamelize(key), value])
  );
}
