import React from 'react';
import { Button, Select, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { selectSearch } from '../../../../../../core/utils/selectSearch';
import CustomFieldValue from '../CustomFieldValue';
import { customFieldsResource } from '../../../../../../redux/resources/customFields';
import { SCol, SRow } from '../../../../../../components/Standard';

const CustomFieldsNotifications = ({ customFields, field, form }) => {
  const { t } = useTranslation();

  const handleCustomFieldChange = (value, subField) => {
    const currentFields = form.getFieldValue([field.name, 'custom_fields']) || {};
    form.setFieldsValue({
      [field.name]: {
        custom_fields: {
          ...currentFields,
          [subField.name]: {
            id: value || { value: null, label: '', key: null },
            type: value ? customFields[value.value]?.fieldType : null,
            values: { value: null }
          }
        }
      }
    });
  };

  const getUsedCustomFieldIds = () => {
    const fieldsData = form.getFieldValue([field.name, 'custom_fields']) || {};
    return Object.values(fieldsData)
      .filter(item => item?.id?.value)
      .map(item => item.id.value);
  };

  return (
    <Form.List name={[field.name, 'custom_fields']}>
      {(subFields, { add, remove }) => (
        <>
          {subFields.map(subField => {
            const selectedField =
              form.getFieldValue([field.name, 'custom_fields', subField.name]) || {};
            const selectedId = selectedField.id?.value;
            const customField = customFields[selectedId] || {};

            return (
              <SRow key={subField.key} align="middle" gutter={[8, 8]}>
                <SCol span={12}>
                  <Form.Item name={[subField.name, 'id']} noStyle>
                    <Select
                      placeholder={t('Выберите поле')}
                      size="middle"
                      allowClear
                      showSearch
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        selectSearch({ input, option, searchProp: 'children' })
                      }
                      style={{ width: '100%' }}
                      labelInValue
                      onChange={value => handleCustomFieldChange(value, subField)}
                    >
                      {Object.values(customFields)
                        .filter(item => {
                          const usedIds = getUsedCustomFieldIds();
                          return !usedIds.includes(item.id) || item.id === selectedId;
                        })
                        .map(field => (
                          <Select.Option key={field.id} value={field.id} label={field.name}>
                            {field.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </SCol>

                <SCol span={11}>
                  <Form.Item name={[subField.name, 'values', 'value']} noStyle>
                    <CustomFieldValue
                      fieldType={customField.fieldType || 'defaultType'}
                      fieldName={customField.name || 'defaultName'}
                      isVisibleName={false}
                    />
                  </Form.Item>
                </SCol>

                <SCol span={1}>
                  <CloseOutlined onClick={() => remove(subField.name)} />
                </SCol>
              </SRow>
            );
          })}
          <Button
            type="dashed"
            onClick={() =>
              add({
                id: { value: null, label: '', key: null },
                type: null,
                values: { value: null }
              })
            }
            block
          >
            + {t('Добавить кастомное поле')}
          </Button>
        </>
      )}
    </Form.List>
  );
};

const mapStateToProps = state => ({
  customFields: state.customFieldsResource.byIds
});

const mapDispatchToProps = {
  loadCustomFields: customFieldsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFieldsNotifications);
