import React from 'react';
import { Button, Form, Select } from 'antd';
import { CloseOutlined, FlagFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { selectSearch } from '../../../../../../core/utils/selectSearch';
import {
  FLAGS_COLORS_LITERALS_RUS,
  FLAGS_TO_COLORS_RELATIONS
} from '../../../../../../core/utils/constants';
import Icon from '../../../../../../components/Icon';
import { StyledSelect } from '../../../../../DashboardPage/styled';
import { SCol, SRow } from '../../../../../../components/Standard';
import RangeInputNumber from '../shared/RangeInputNumber';

const FlagsNotifications = ({ form, field }) => {
  const { t } = useTranslation();

  const handleFlagChange = (value, subField) => {
    const currentFlags = form.getFieldValue([field.name, 'flags']) || {};
    form.setFieldsValue({
      [field.name]: {
        flags: {
          ...currentFlags,
          [subField.name]: {
            color: value || { value: null, label: '', key: null },
            range: { min: null, max: null }
          }
        }
      }
    });
  };

  const getUsedFlags = () => {
    const flagsData = form.getFieldValue([field.name, 'flags']) || {};
    return Object.values(flagsData)
      .filter(item => item?.color?.value)
      .map(item => item.color.value);
  };

  const flagOptions = [
    {
      key: 'green',
      value: 'green',
      searchValue: t(FLAGS_TO_COLORS_RELATIONS.green),
      color: FLAGS_TO_COLORS_RELATIONS.green
    },
    {
      key: 'yellow',
      value: 'yellow',
      searchValue: t(FLAGS_COLORS_LITERALS_RUS.yellow),
      color: FLAGS_TO_COLORS_RELATIONS.yellow
    },
    {
      key: 'red',
      value: 'red',
      searchValue: t(FLAGS_COLORS_LITERALS_RUS.red),
      color: FLAGS_TO_COLORS_RELATIONS.red
    },
    {
      key: 'empty',
      value: 'empty',
      searchValue: t(FLAGS_COLORS_LITERALS_RUS.gray),
      color: FLAGS_TO_COLORS_RELATIONS.without_flag
    }
  ];

  return (
    <Form.List name={[field.name, 'flags']}>
      {(subFields, { add, remove }) => (
        <>
          {subFields.map(subField => {
            const selectedFlag = form.getFieldValue([field.name, 'flags', subField.name]) || {};
            const selectedColor = selectedFlag.color?.value;

            return (
              <SRow key={subField.key} align="middle" gutter={[8, 8]}>
                <SCol span={12}>
                  <Form.Item noStyle name={[subField.name, 'color']}>
                    <StyledSelect
                      placeholder={t('general.flagsAndComments')}
                      allowClear
                      filterOption={(input, option) =>
                        selectSearch({ input, option, searchProp: 'searchValue' })
                      }
                      labelInValue
                      onChange={value => handleFlagChange(value, subField)}
                    >
                      {flagOptions
                        .filter(flag => {
                          const usedFlags = getUsedFlags();
                          return !usedFlags.includes(flag.value) || flag.value === selectedColor;
                        })
                        .map(flag => (
                          <Select.Option
                            key={flag.key}
                            value={flag.value}
                            searchValue={flag.searchValue}
                            label={<Icon icon={FlagFilled} color={flag.color} />}
                          >
                            <Icon icon={FlagFilled} color={flag.color} />
                          </Select.Option>
                        ))}
                    </StyledSelect>
                  </Form.Item>
                </SCol>
                <SCol span={11}>
                  <RangeInputNumber field={subField} />
                </SCol>
                <SCol span={1}>
                  <CloseOutlined onClick={() => remove(subField.name)} />
                </SCol>
              </SRow>
            );
          })}
          <Button
            type="dashed"
            onClick={() =>
              add({
                color: { value: null, label: '', key: null },
                range: { min: null, max: null }
              })
            }
            block
          >
            + {t('Добавить флаг')}
          </Button>
        </>
      )}
    </Form.List>
  );
};

export default FlagsNotifications;
