import { QuestionCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';
import axios from 'axios';
import { IconButton } from 'components/Buttons/IconButton';
import { exportToExcelEndpoint, exportToExcelTableCommunicationEndpoint } from 'core/api';
import {
  DATE_PICKER_MODE_LITERAL,
  WIDGET_DATE_MODE_TO_STRING,
  DATES_VIEW_FORMAT,
  RATING_MODE,
  SYMBOLIC_TIME_RANGE,
  SYMBOLIC_TIME_RANGE_LITERALS,
  WIDGET_VIEW_MODES,
  ZONE_TO_COLOR_RELATIONS
} from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { timeFormatter } from 'core/utils/timeFormatter';
import { Trans } from 'react-i18next';
import fileDownload from 'js-file-download';
import { get, isEmpty, isNil, snakeCase, keys, keyBy, reduce, sortBy } from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import React from 'react';
import Icon from 'components/Icon';
import widgetsDefinitions from './widgetsDefinitions/widgetsDefinitions';

const moment = extendMoment(Moment);

const excludedAllTimeWidgetsTypes = [...Object.keys(widgetsDefinitions)];

const excludedSymbolicTimeRangePeriods = ['this_year', 'previous_year'];

export const hasConflictFilters = ({ availableReduxFilters, currentFilters }) => {
  for (let i = 0; i < currentFilters.length; i++) {
    if (!availableReduxFilters.includes(currentFilters[i]) && !isEmpty(currentFilters)) return true;
  }
  return false;
};

export const getFilters = filters => {
  const {
    clientInteractionSymbolicTimeRange,
    clientInteractionTimeFrom,
    clientInteractionTimeTo,
    reviewSymbolicTimeRange,
    reviewTimeFrom,
    reviewTimeTo,
    checklistDefinitionsIds = []
  } = filters;

  const getChecklistDefinitionId = () => {
    if (typeof checklistDefinitionsIds === 'string') {
      if (isEmpty(checklistDefinitionsIds)) {
        return 'all';
      }
      return checklistDefinitionsIds;
    }
    if (Array.isArray(checklistDefinitionsIds)) {
      if (checklistDefinitionsIds.length > 1) {
        return 'all';
      }
      if (isEmpty(checklistDefinitionsIds)) {
        return 'all';
      }
      return checklistDefinitionsIds;
    }
  };

  if (clientInteractionSymbolicTimeRange) {
    return {
      clientInteractionSymbolicTimeRange,
      checklistDefinitionsIds: getChecklistDefinitionId()
    };
  }
  if (reviewSymbolicTimeRange) {
    return {
      reviewSymbolicTimeRange,
      checklistDefinitionsIds: getChecklistDefinitionId()
    };
  }
  if (clientInteractionTimeFrom && clientInteractionTimeTo) {
    return {
      clientInteractionTimeFrom:
        clientInteractionTimeFrom && encodeURIComponent(clientInteractionTimeFrom),
      clientInteractionTimeTo:
        clientInteractionTimeTo && encodeURIComponent(clientInteractionTimeTo),
      checklistDefinitionsIds: getChecklistDefinitionId()
    };
  }
  if (reviewTimeFrom && reviewTimeTo) {
    return {
      reviewTimeFrom: reviewTimeFrom && encodeURIComponent(reviewTimeFrom),
      reviewTimeTo: reviewTimeFrom && encodeURIComponent(reviewTimeTo),
      checklistDefinitionsIds: getChecklistDefinitionId()
    };
  }
};

export const getTimeRanges = ({ widgetType }) => {
  if (excludedAllTimeWidgetsTypes.includes(widgetType)) {
    return Object.values(SYMBOLIC_TIME_RANGE).filter(item => item !== 'all');
  }
  return Object.values(SYMBOLIC_TIME_RANGE);
};

export const getDefaultTimeRange = ({ widgetType }) =>
  excludedAllTimeWidgetsTypes.includes(widgetType)
    ? SYMBOLIC_TIME_RANGE.THIS_MONTH
    : SYMBOLIC_TIME_RANGE.ALL_TIME;

export const disabledDayOption = ({
  timeFrom,
  timeTo,
  symbolicTimeRange,
  widgetType,
  currentOption,
  historyGroupingPeriod,
  updateUiWidgetFilters
}) => {
  const start = moment(timeTo);
  const end = moment(timeFrom);
  const diff = start.diff(end, 'days');

  // ! optimize
  const isUnacceptableDaysCount =
    excludedAllTimeWidgetsTypes.includes(widgetType) && diff > 60 && currentOption === 'day';

  const isUnacceptableSymbolicTimeRangeValue =
    excludedAllTimeWidgetsTypes.includes(widgetType) &&
    excludedSymbolicTimeRangePeriods.includes(symbolicTimeRange) &&
    currentOption === 'day';

  if (isUnacceptableDaysCount || isUnacceptableSymbolicTimeRangeValue) {
    if (historyGroupingPeriod === 'day')
      updateUiWidgetFilters({
        historyGroupingPeriod: 'week'
      });

    return true;
  }
};

export const getSortString = ({ sorter, viewMode }) => {
  if (!sorter.order) return '';
  const standardKeys = ['clientInteractionStartedAt', 'createdAt', 'checklistScore'];

  const getSorterKey = () => {
    if (standardKeys.includes(sorter.columnKey)) return snakeCase(sorter.columnKey);
    if (viewMode === WIDGET_VIEW_MODES.QUESTIONS.value) return `answer_score_${sorter.columnKey}`;
    if (viewMode === WIDGET_VIEW_MODES.GROUPS.value) return `group_score_${sorter.columnKey}`;
  };

  return sorter.order === 'ascend' ? getSorterKey() : `-${getSorterKey()}`;
};

export const exportWidgetData = async ({
  body,
  setLoadingExport,
  successMessageKey = 'dashboardPage.widget.messages.analyticsSuccessfullyExported',
  errorMessageKey = 'dashboardPage.widget.messages.exportAnalyticsFailed'
}) => {
  const rows = body?.widgetData?.rows || [];

  if (rows.length === 0 || (rows.length === 1 && rows[0].key === 'footer')) {
    message.warn('Нет данных для экспорта');
    return;
  }

  try {
    setLoadingExport(true);
    const result = await axios.post(
      body.action === 'export' ? exportToExcelTableCommunicationEndpoint : exportToExcelEndpoint,
      body,
      { responseType: 'blob' }
    );

    if (get(result, 'status', 200) === 200) {
      if (body.action !== 'export') {
        fileDownload(result.data, 'export.xlsx');
      }
      setLoadingExport(false);
      message.success(<Trans i18nKey={successMessageKey} />);
    } else {
      message.error(<Trans i18nKey={errorMessageKey} />);
    }
  } catch (error) {
    setLoadingExport(false);
    console.log(error);
    message.error(<Trans i18nKey={errorMessageKey} />);
  }
};

export const getDatesArray = ({
  timeFrom,
  timeTo,
  isWeekendView,
  historyGroupingPeriod = 'day'
}) => {
  if (!timeFrom || !timeTo) return [];

  const range = moment.range(timeFrom, timeTo);

  const datesArray = () => {
    if (historyGroupingPeriod === DATES_VIEW_FORMAT.YEAR.value) {
      const years = Array.from(range.snapTo('year').by('years'));
      return years.map(year => year.startOf('month'));
    }
    if (historyGroupingPeriod === DATES_VIEW_FORMAT.MONTH.value) {
      const months = Array.from(range.by('months'));
      return months.map(month => month.startOf('month'));
    }
    if (historyGroupingPeriod === DATES_VIEW_FORMAT.WEEK.value) {
      const weeks = Array.from(range.snapTo('week').by('weeks'));
      return weeks.map(week => moment(week).startOf('week'));
    }
    if (historyGroupingPeriod === DATES_VIEW_FORMAT.DAY.value) {
      const days = Array.from(range.by('days'));
      return isWeekendView ? days : days.filter(day => day.isoWeekday() < 6);
    }
  };

  return datesArray();
};

export const widgetNameSetter = ({ name, type }) => {
  return isEmpty(name)
    ? get(
        widgetsDefinitions,
        `${type}.name`,
        <Trans i18nKey="dashboardPage.widget.utils.emptyName" />
      )
    : name;
};

export const searchMaxValue = ({ checklistDefinitionsByIds, filters }) => {
  const checklistDefinition = get(checklistDefinitionsByIds, filters.checklistDefinitionsIds);
  const checklistDefinitionRatingMode = get(checklistDefinition, 'ratingMode');

  if (checklistDefinition) {
    return checklistDefinitionRatingMode === RATING_MODE.PERCENTAGE ? 100 : checklistDefinition.max;
  }
};

export const valueToColorZoneColor = ({ value, colorZones }) => {
  if ((!value || !colorZones) && value !== 0) return 'white';

  // * если все зоны на месте
  if (value < get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.low;
  if (value < get(colorZones, 'medium.stop')) return ZONE_TO_COLOR_RELATIONS.medium;
  if (value >= get(colorZones, 'medium.stop')) return ZONE_TO_COLOR_RELATIONS.high;

  // * если есть только low + high зоны
  if (!get(colorZones, 'medium.stop') && get(colorZones, 'high.stop')) {
    if (value <= get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.low;
    if (value === get(colorZones, 'high.stop')) return ZONE_TO_COLOR_RELATIONS.high;
  }

  // * если есть только low зона
  if (!get(colorZones, 'medium.stop') && !get(colorZones, 'high.stop')) {
    if (value < get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.low;
    if (value === get(colorZones, 'low.stop')) return ZONE_TO_COLOR_RELATIONS.high;
  }
};

export const widgetValueTypeSetter = {
  checklist_definition_average_score: (widgetValue, checklistDefinitionRatingMode) =>
    `${beatifyFloat(widgetValue)}${checklistDefinitionRatingMode === 'percentage' ? '%' : ''}`,
  phone_calls_count: widgetValue => beatifyFloat(widgetValue, 0),
  reviewed_client_interactions_percentage: widgetValue =>
    `${beatifyFloat(get(widgetValue, 'percentage', ''))}%`,
  total_phone_calls_duration: widgetValue => timeFormatter({ timeInSeconds: widgetValue }),
  reviewed_client_interactions_duration: widgetValue =>
    timeFormatter({ timeInSeconds: widgetValue }),
  phone_calls_average_duration: widgetValue => moment.utc(widgetValue * 1000).format('mm:ss')
};

const setText = ({
  widgetsFilters,
  symbolicTimeRangeFilterType,
  timeFromFilterType,
  timeToFilterType,
  showDateMode,
  mode
}) => {
  if (!isEmpty(widgetsFilters[symbolicTimeRangeFilterType]))
    return (
      <>
        <Trans
          i18nKey={SYMBOLIC_TIME_RANGE_LITERALS[widgetsFilters[symbolicTimeRangeFilterType]]}
        />
        {showDateMode && <Trans i18nKey={DATE_PICKER_MODE_LITERAL[mode]} />}
      </>
    );

  if (!isEmpty(widgetsFilters[timeFromFilterType]) && !isEmpty(widgetsFilters[timeToFilterType]))
    return (
      <>
        {`${moment(widgetsFilters[timeFromFilterType]).format('DD/MM/YYYY')} - ${moment(
          widgetsFilters[timeToFilterType]
        ).format('DD/MM/YYYY')}`}
        {showDateMode && <Trans i18nKey={DATE_PICKER_MODE_LITERAL[mode]} />}
      </>
    );

  if (
    isEmpty(widgetsFilters[timeFromFilterType]) &&
    isEmpty(widgetsFilters[timeToFilterType]) &&
    isEmpty(widgetsFilters[symbolicTimeRangeFilterType])
  )
    return <Trans i18nKey="dashboardPage.widget.utils.allTime" />;
};

export const appellationsPeriodSetter = widgetsFilters => {
  return setText({
    widgetsFilters,
    symbolicTimeRangeFilterType: WIDGET_DATE_MODE_TO_STRING.appellation.symbolicTimeRange,
    timeFromFilterType: WIDGET_DATE_MODE_TO_STRING.appellation.timeFrom,
    timeToFilterType: WIDGET_DATE_MODE_TO_STRING.appellation.timeTo
  });
};

export const widgetPeriodSetter = ({ widgetsFilters, widgetType, showDateMode = false }) => {
  const dateMode = get(widgetsDefinitions, `${widgetType}.dateMode`);

  // * виджет с дейтпикером с возможностью выбора даты
  if (dateMode === 'default') {
    // * если установлены фильтры по дате коммуникации
    if (
      widgetsFilters?.clientInteractionSymbolicTimeRange ||
      widgetsFilters?.clientInteractionTimeFrom ||
      widgetsFilters?.clientInteractionTimeTo
    ) {
      return setText({
        widgetsFilters,
        symbolicTimeRangeFilterType: 'clientInteractionSymbolicTimeRange',
        timeFromFilterType: 'clientInteractionTimeFrom',
        timeToFilterType: 'clientInteractionTimeTo',
        showDateMode,
        mode: 'clientInteraction'
      });
    }
    return setText({
      widgetsFilters,
      symbolicTimeRangeFilterType: 'reviewSymbolicTimeRange',
      timeFromFilterType: 'reviewTimeFrom',
      timeToFilterType: 'reviewTimeTo',
      showDateMode,
      mode: 'review'
    });
  }

  // * виджет с дейтпикером с возможностью выбора только даты коммуникации
  if (dateMode === 'clientInteraction') {
    return setText({
      widgetsFilters,
      symbolicTimeRangeFilterType: 'clientInteractionSymbolicTimeRange',
      timeFromFilterType: 'clientInteractionTimeFrom',
      timeToFilterType: 'clientInteractionTimeTo',
      showDateMode,
      mode: 'clientInteraction'
    });
  }

  // * виджет с дейтпикером с возможностью выбора только даты проверки
  if (dateMode === 'review') {
    return setText({
      widgetsFilters,
      symbolicTimeRangeFilterType: 'reviewSymbolicTimeRange',
      timeFromFilterType: 'reviewTimeFrom',
      timeToFilterType: 'reviewTimeTo',
      showDateMode,
      mode: 'review'
    });
  }
};

export const widgetInfoTextSetter = ({ type }) => {
  return (
    <IconButton
      tooltip={{
        title: get(
          widgetsDefinitions,
          `${type}.description`,
          <Trans i18nKey="dashboardPage.widget.utils.emptyName" />
        ),
        overlayStyle: { width: 500 }
      }}
      button={{
        icon: <Icon icon={QuestionCircleOutlined} />,
        size: 'icon',
        style: { width: 24, flex: 'none', height: 'inherit' }
      }}
    />
  );
};

export const tooltipSetter = tooltipData => {
  return (
    <>
      <span>
        {isNil(tooltipData.name) ? (
          <Trans i18nKey="dashboardPage.widget.utils.nameIsNotInstalled" />
        ) : (
          `${tooltipData.name}: `
        )}
      </span>
      <strong>{`${tooltipData.value}`}</strong>
    </>
  );
};

export const calcDatesRange = ({ timeTo }) => {
  const timeToStr = timeTo.format('DD/MM/YYYY');

  const to =
    moment(timeToStr, 'DD/MM/YYYY').isoWeekday() > 2
      ? moment(timeToStr, 'DD/MM/YYYY').endOf('week')
      : moment(timeToStr, 'DD/MM/YYYY')
          .subtract(1, 'weeks')
          .endOf('week');
  const from =
    moment(timeToStr, 'DD/MM/YYYY').isoWeekday() > 2
      ? moment(timeToStr, 'DD/MM/YYYY')
          .endOf('week')
          .subtract(4, 'weeks')
          .startOf('week')
      : moment(timeToStr, 'DD/MM/YYYY')
          .subtract(1, 'weeks')
          .endOf('week')
          .subtract(4, 'weeks')
          .startOf('week');

  return {
    timeFrom: from,
    timeTo: to
  };
};

export const requiredChecklistDefinitionsWidgetTypes = [
  'checklist_definition_average_score',
  'checklist_definition_average_score_by_units',
  'checklist_definition_average_score_by_operators',
  'checklist_definition_average_score_by_checklist_definitions',
  'checklist_definition_questions_average_scores',
  'checklist_definition_questions_average_scores_by_question_groups',
  'checklist_definition_question_groups_average_scores',
  'checklist_definition_average_score_by_operators_history',
  'checklist_definition_average_score_by_units_history',
  'average_score_by_questions_history',
  'average_score_by_question_groups_history',
  'checklist_definition_question_groups_average_scores',
  'table_reviews_count_by_operators',
  'table_reviews_count_by_operators_with_kpi',
  'table_checklist_items_by_operators',
  'table_checklist_items_by_operators_multi',
  'table_checklist_items_history',
  'table_checklists_scores_by_operators',
  'table_reviews_with_scores'
];

export const requiredOperatorIdsWidgetTypes = [
  'checklist_definition_average_score_by_operators_history'
];

export const requiredSingleDateWidgetTypes = ['table_reviews_count_by_operators_with_kpi'];

export const requiredKpiWidgetTypes = ['table_reviews_count_by_operators_with_kpi'];

export const customEmptyDashboardRulesWidgetTypes = [
  'rating_flags_count',
  'reviews_count_by_reviewers_history',
  'checklist_definition_average_score_by_operators_history',
  'checklist_definition_average_score_by_units_history',
  'average_score_by_questions_history',
  'average_score_by_question_groups_history',
  'phone_calls_average_duration_by_operators_history',
  'phone_calls_average_duration_by_units_history',
  'client_interactions_count_by_operators_history',
  'client_interactions_count_by_units_history',
  'table_reviews_count_by_operators',
  'table_checklist_items_by_operators',
  'table_checklist_items_by_operators_multi',
  'table_checklist_items_history',
  'flags_and_comments',
  'table_checklists_scores_by_operators',
  'table_reviews_count_by_reviewers'
];

export const preparePieData = ({ pieData, colorTypes = {} }) => {
  return keys(pieData).map(item => ({
    id: item,
    label: item,
    value: pieData[item],
    color: get(colorTypes, item, '')
  }));
};

export const getOrderedGroupQuestionsAndQuestions = ({ checklistItems, viewMode }) => {
  const questionGroups = sortBy(
    checklistItems.filter(item => item.type === 'checklist-question-groups'),
    ['position']
  );

  const getQuestion = ({ questionId }) => keyBy(checklistItems, 'id')?.[questionId];

  const getQuestions = () => {
    reduce(
      questionGroups,
      (acc, { questionsIds }) => [
        ...acc,
        ...sortBy(
          questionsIds?.map(questionId => getQuestion({ questionId })),
          ['binding.position']
        )
      ],
      []
    );
    return questionGroups.length === 0
      ? checklistItems
      : reduce(
          questionGroups,
          (acc, { questionsIds }) => [
            ...acc,
            ...sortBy(
              questionsIds?.map(questionId => getQuestion({ questionId })),
              ['binding.position']
            )
          ],
          []
        );
  };

  return viewMode === WIDGET_VIEW_MODES.QUESTIONS.value ? getQuestions() : questionGroups;
};
