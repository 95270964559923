import React from 'react';
import { Radio } from 'antd';

const RadioItem = () => {
  return (
    <>
      <Radio.Group defaultValue="false">
        <Radio value="true">Вкл</Radio>
        <Radio value="false">Выкл</Radio>
      </Radio.Group>
    </>
  );
};

export default RadioItem;
