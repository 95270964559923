export default {
  communicationChain: {
    createChainModal: {
      title: 'Цепочки коммуникаций',
      cancel: 'Отменить',
      create: 'Создать',
      ok: 'Да',
      successCreate: 'Цепочка коммуникаций успешно создана',
      add: 'Добавить',
      successAdd: 'Цепочка коммуникаций успешно обновлена',
      selectedCommunications: 'Выбранные коммуникации',
      resultIsCalculated: 'Результат проверки рассчитывается',
      bulkActionCheckModal: {
        title: 'Создание цепочки коммуникаций',
        cancelTextModal: 'Вы уверенны что хотите прервать создание цепочки коммуникаций?'
      },
      columns: {
        operator: {
          title: 'ФИО'
        },
        direction: {
          title: 'Направление'
        },
        duration: {
          title: 'Прод-ть'
        },
        clientPhoneNumber: {
          title: 'Номер телефона'
        },
        score: {
          title: 'Оценка'
        }
      },
      fields: {
        communicationChainId: {
          tooltip:
            'Данное поле не обязательное. При необходимости можно выбрать нужную ЦК в которую будут добавлены выбранные коммуникации',
          placeholder: 'Выберите ЦК'
        },
        clientPhoneNumber: {
          placeholder: 'Номер цепочки',
          emptyFieldMessage: 'Пожалуйста введите номер цепочки'
        },
        operator: {
          placeholder: 'Сотрудник',
          emptyFieldMessage: 'Пожалуйста выберите сотрудника'
        },
        startedAt: {
          placeholder: 'Дата коммуникации',
          emptyFieldMessage: 'Пожалуйста выберите дату коммуникации'
        },
        duration: {
          placeholder: 'Продолжительность',
          emptyFieldMessage: 'Пожалуйста выберите продолжительность'
        }
      }
    },
    infoMessages: {
      notEnoughCommunications:
        'Для создания цепочки коммуникации необходимо не менее двух коммуникаций',
      numberAlreadyExists: 'Цепочка коммуникаций с таким номером уже существует'
    },
    chainPage: {
      removeCommunicationModal: {
        title: 'Удалить коммуникацию из цепочки?',
        ok: 'Удалить',
        cancel: 'Отменить',
        messages: {
          deleteSuccess: 'Коммуникация удалена'
        }
      },
      columns: {
        direction: {
          title: 'Направление'
        },
        duration: {
          title: 'Продолжительность'
        }
      }
    }
  }
};
