import React, { useEffect, useState } from 'react';
import { Button, Form, Select, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { selectSearch } from '../../../../../../core/utils/selectSearch';
import { operations } from '../../../../../../redux/lists/questionsList';
import RateValues from '../../../../../../components/Inputs/RateValues';
import { SCol, SRow } from '../../../../../../components/Standard';

const { Text } = Typography;

const QuestionsNotifications = ({ loadQuestions, questions, field, form }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isEmpty(questions)) {
      loadQuestions({ pagination: false });
    }
  }, [questions, loadQuestions]);

  const handleQuestionChange = (value, subField) => {
    const currentQuestions = form.getFieldValue([field.name, 'questions']) || {};
    form.setFieldsValue({
      [field.name]: {
        questions: {
          ...currentQuestions,
          [subField.name]: {
            id: value || { value: null, label: '', key: null },
            values: { value: [] } // Сбрасываем значения при смене вопроса
          }
        }
      }
    });
  };

  const handleValuesChange = (val, subField, selectedQuestion) => {
    const currentQuestions = form.getFieldValue([field.name, 'questions']) || {};
    form.setFieldsValue({
      [field.name]: {
        questions: {
          ...currentQuestions,
          [subField.name]: {
            ...selectedQuestion,
            values: { value: val || [] }
          }
        }
      }
    });
  };

  const getUsedQuestionIds = () => {
    const questionsData = form.getFieldValue([field.name, 'questions']) || {};
    return Object.values(questionsData)
      .filter(item => item?.id?.value)
      .map(item => item.id.value);
  };

  return (
    <Form.List name={[field.name, 'questions']}>
      {(subFields, { add, remove }) => (
        <>
          {subFields.map(subField => {
            const selectedQuestion =
              form.getFieldValue([field.name, 'questions', subField.name]) || {};
            const selectedId = selectedQuestion.id?.value;
            const selectedField = selectedId ? questions[selectedId] : null;
            const selectedValues = selectedQuestion.values?.value || [];

            return (
              <SRow key={subField.key} align="middle" gutter={[8, 8]}>
                <SCol span={12}>
                  <Form.Item name={[subField.name, 'id']} noStyle>
                    <Select
                      placeholder={t('Выберите Критерий')}
                      size="middle"
                      allowClear
                      showSearch
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        selectSearch({ input, option, searchProp: 'children' })
                      }
                      style={{ width: '100%' }}
                      labelInValue
                      onChange={value => handleQuestionChange(value, subField)}
                    >
                      {Object.values(questions)
                        .filter(item => {
                          const usedIds = getUsedQuestionIds();
                          return !usedIds.includes(item.id) || item.id === selectedId;
                        })
                        .map(question => (
                          <Select.Option
                            key={question.id}
                            value={question.id}
                            label={question.name}
                          >
                            {question.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </SCol>

                <SCol span={11}>
                  {selectedField && (
                    <Form.Item name={[subField.name, 'values', 'value']} noStyle>
                      <RateValues
                        ratingValues={selectedField?.ratingValues}
                        ratingValuesViews={selectedField?.ratingValuesViews}
                        valuesDisplayType={selectedField?.valuesDisplayType}
                        scaleType={selectedField?.scaleType}
                        allowMultiple
                        value={selectedValues}
                        onChange={val => handleValuesChange(val, subField, selectedQuestion)}
                      />
                    </Form.Item>
                  )}
                </SCol>

                <SCol span={1}>
                  <CloseOutlined onClick={() => remove(subField.name)} />
                </SCol>
              </SRow>
            );
          })}

          <Button
            type="dashed"
            onClick={() =>
              add({ id: { value: null, label: '', key: null }, values: { value: [] } })
            }
            block
          >
            + {t('Добавить критерий')}
          </Button>
        </>
      )}
    </Form.List>
  );
};

const mapStateToProps = state => ({
  questions: state.questionsResource.byIds
});

const mapDispatchToProps = {
  loadQuestions: operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsNotifications);
