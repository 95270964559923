import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Checkbox, Menu, message } from 'antd';
import { setCheckbox } from '../../../redux/ui/clientInteractionPage/reducer';
import {
  CheckboxUnSelectAllStyled,
  TableSelectHeaderContainerStyled,
  TableSelectHeaderItem,
  TextStyled
} from './styled';
import ModalWindowCheckbox from './ModalWindowCheckbox';

const TableSelectHeader = ({
  selectedLength,
  selection,
  hasConflicts,
  showCommunicationChainsBtn,
  users,
  isOpen,
  setIsOpen,
  selectedModal,
  setSelectedModal,
  activeFilter,
  setActiveFilter
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selected } = selection;

  const unSelectAll = () => {
    selection.unSelectAll();
    dispatch(setCheckbox([]));
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'communicationChains':
        if (selected.length >= 2) {
          setSelectedModal(key);
          showModal();
        } else {
          message.info(
            'Для создания цепочки коммуникации необходимо не менее двух коммуникаций',
            4
          );
        }
        return;
      default:
        console.log();
    }
  };

  return (
    <TableSelectHeaderContainerStyled>
      <ModalWindowCheckbox
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedModal={selectedModal}
        setSelectedModal={setSelectedModal}
        selection={selection}
        hasConflicts={hasConflicts}
        users={users}
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      {selection.allSelected ? (
        <Checkbox
          style={{ marginLeft: '4.3%' }}
          checked={selection.allSelected}
          onClick={unSelectAll}
        />
      ) : (
        // Чекбокс с минусом
        <CheckboxUnSelectAllStyled style={{ marginLeft: '4.3%' }} onClick={unSelectAll} />
      )}
      <Menu>
        <TextStyled
          onClick={() => {
            // if (showCommunicationChainsBtn) {
            //   return message.info(
            //     'Для создания цепочки коммуникаций, нужно снять выделение с других цепочек коммуникаций',
            //     4
            //   );
            // }
            handleMenuClick({ key: 'communicationChains' });
          }}
        >
          Создать ЦК
        </TextStyled>
      </Menu>
      <TableSelectHeaderItem style={{ marginRight: '22px' }}>
        {`${t('general.selectedCommunications')}: ${selectedLength}`}
      </TableSelectHeaderItem>
    </TableSelectHeaderContainerStyled>
  );
};

export default TableSelectHeader;
