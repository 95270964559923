import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import moment from 'moment';
import { StyledAvatarGroup } from 'components/CrmEntityContent/styled';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { useTranslation } from 'react-i18next';
import ClientInteractionLink from 'components/ClientInteractionLink';
import { beatifyFloat } from 'core/utils/convertFloat';
import CommentsPreview from 'components/Comments/CommentsPreview/CommentsPreview';
import { get, isEmpty, isEqual, pick } from 'lodash';
import UserPreview from 'components/UserPreview';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import SRow from 'components/Standard/SRow';

const ReviewRow = ({ review, clientInteraction }) => {
  const { t } = useTranslation();
  const checklistData = useSelector(
    state => state.checklistsResource.byIds[review.checklistId] || {}
  );
  const { score } = checklistData;
  const name = checklistData?.checklistDefinitionData?.name;
  const reviewer = useSelector(state => state.usersResource.byIds[review?.reviewerId]);
  const comments = useSelector(state =>
    Object.values(pick(state.commentsResource.byIds, review.commentsIds))
  );
  const tasksByIds = useSelector(state => state.tasksResource.byIds, isEqual);
  const taskDefinitionsByIds = useSelector(state => state.taskDefinitionsResource.byIds, isEqual);
  const tasks = get(review, 'tasksIds', []).map(taskId => {
    const task = tasksByIds[taskId];
    return {
      ...task,
      taskDefinition: taskDefinitionsByIds[task?.taskDefinitionId]
    };
  });

  return (
    <SRow style={{ border: '1px solid #DFE1E8', borderRadius: '6px' }} padding="16px">
      <SCol md={8} xs={24} padding="0 16px 0 0" margin="auto 0">
        <SRow marginBottom="8px" lineHeight="20px">
          <ClientInteractionLink
            showIcon={false}
            style={{
              fontSize: 14
            }}
            showChild
            isReview
            type={false}
            chain
            id={review?.id}
            communication={clientInteraction}
            checklistDefinitionId={checklistData?.checklistDefinitionId}
          >
            {`${t('general.score')}: ${beatifyFloat(score)}`}
          </ClientInteractionLink>
        </SRow>
        <SRow lineHeight="20px">
          <SText title={name} type="secondary" fontSize={14} ellipsis width="100%">
            {name}
          </SText>
        </SRow>
        {!isEmpty(review?.formulaResult) && review.formulaResult.map(formula =>
          <SRow lineHeight="20px">
            <SText title={name} type="secondary" fontSize={14} ellipsis width="100%">
              {formula.name}: {`${beatifyFloat(formula.result)}`}
            </SText>
          </SRow>
        )}
      </SCol>
      <SCol md={8} xs={24} margin={comments.length > 0 ? 'auto 0' : 'none'} paddingRight="8px">
        {comments.length > 0 && (
          <SRow marginBottom="8px" lineHeight="20px">
            <CommentsPreview comments={comments} checklistData={checklistData} disabledUserLink />
          </SRow>
        )}
        <SRow lineHeight="20px">
          <SText ellipsis type="secondary" fontSize={14}>
            {moment(review.createdAt).format('DD/MM/YYYY, HH:mm')}
          </SText>
        </SRow>
      </SCol>
      <SCol md={8} xs={24} margin={tasks.length > 0 ? 'auto 0' : 'none'} paddingRight="8px">
        {tasks.length > 0 && (
          <SRow marginBottom="8px" lineHeight="20px">
            <TagsDisplay tasks={tasks} truncate={16} />
          </SRow>
        )}
        <SRow lineHeight="20px">
          <StyledAvatarGroup lineHeight="20px" fontSize="14px">
            <UserPreview
              disabled
              size="littleSmall"
              user={reviewer}
              userId={reviewer?.id}
              showAvatar
            />
          </StyledAvatarGroup>
        </SRow>
      </SCol>
    </SRow>
  );
};

const mapDispatchToProps = {
  loadChecklistDefinitionById: checklistDefinitionsResource.operations.loadById
};

export default connect(null, mapDispatchToProps)(ReviewRow);
