import { get, put, destroy } from 'redux-bees';

const communicationChainsEndpoints = apiPath => ({
  // getCommunicationChains: { method: get, path: `${apiPath}/communication_chains` },
  getCommunicationChain: { method: get, path: `${apiPath}/communication_chains/:id` },
  deleteCommunicationChain: { method: destroy, path: `${apiPath}/communication_chains/:id` },
  reduceCommunicationChain: {
    method: put,
    path: `${apiPath}/communication_chains/delete_communication`
  },
  extendCommunicationChain: {
    method: put,
    path: `${apiPath}/communication_chains/add_communication`
  }
});

export default communicationChainsEndpoints;
