import { BarsOutlined, FileDoneOutlined, SwapOutlined } from '@ant-design/icons';
import { Checkbox, Col, Modal, Row, Tooltip, Typography } from 'antd';
import { COLUMN_TYPES } from 'core/utils/constants';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QolioAiIcon from './QolioAiIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setAllCheckbox, setCheckbox } from 'redux/ui/clientInteractionPage/reducer';
import {
  ButtonStyledActiveFilter,
  ButtonStyledCurrentPage,
  ModalTitle
} from '../pages/ClientInteractionsPage/components/styled';
import ModalWindowCheckbox from '../pages/ClientInteractionsPage/components/ModalWindowCheckbox';
import ChecklistDefinitionStatusTag from './Checklist/ChecklistDefinitionStatusTag';

const checklistDefinitionStyles = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};
const defaultCustomFields = {};

const checkboxColumnStyles = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-around'
};

// TODO: prefix
const ColumnTitle = ({
  columnType,
  customFieldsByKeys = defaultCustomFields,
  checklistDefinitionsIds = {},
  questionsChecklist = {},
  tableView,
  width,
  selection = false,
  thisIsCheckbox = false,
  ids,
  tableId,
  activeFilter,
  setActiveFilter,
  isOpen,
  setIsOpen,
  selectedModal,
  setSelectedModal,
  users,
  hasConflicts
}) => {
  const isChecklistDefinition = checklistDefinitionsIds[columnType];
  const { Text } = Typography;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [modalSelectFilter, setModalSelectFilter] = useState(false);

  const clientInteractions = useSelector(
    state => state.uiClientInteractions.meta.clientInteractions
  );
  const getOrganizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  const Icon = questionsChecklist[columnType]
    ? FileDoneOutlined
    : get(
        COLUMN_TYPES,
        `${columnType}.icon`,
        checklistDefinitionsIds[columnType] ? BarsOutlined : SwapOutlined
      );

  const columnTitle = questionsChecklist[columnType]
    ? questionsChecklist[columnType]?.name
    : get(
        COLUMN_TYPES,
        `${columnType}.name`,
        customFieldsByKeys[columnType]?.name || checklistDefinitionsIds[columnType]?.name || ''
      );

  const customField = columnType && customFieldsByKeys[columnType];

  // Для текущей страницы
  const handleSelectCurrentPage = () => {
    if (selection.allSelected) {
      selection.unSelectAll();
      dispatch(setCheckbox([]));
    } else {
      // Собираем массив всех идентификаторов записей текущей страницы с учетом пагинации(10, 25, 50)
      const currentPageIds = ids.filter(id => {
        return clientInteractions.some(item => item.id === id);
      });
      // Переключаем/выделяем все чекбоксы
      currentPageIds.forEach(id => {
        selection.toggle(id);
      });

      // Собираем объекты нужного вида
      const selectedAll = clientInteractions.map(clientIntegration => {
        return {
          organization_id: getOrganizationId,
          operator_id: clientIntegration.operatorId,
          communications: [clientIntegration.id]
        };
      });

      const filteredArray = [];

      // Объединяем собранные объекты в один и объединяем все значения communications в одну
      selectedAll.forEach(selectedItem => {
        const existingObject = filteredArray.find(
          obj => obj.operator_id === selectedItem.operator_id
        );

        if (existingObject) {
          existingObject.communications.push(...selectedItem.communications);
        } else {
          filteredArray.push({
            organization_id: selectedItem.organization_id,
            operator_id: selectedItem.operator_id,
            communications: [...selectedItem.communications]
          });
        }
      });
      // console.log('filteredArray', filteredArray);
      dispatch(setAllCheckbox(filteredArray));
      setModalSelectFilter(false);
    }
  };

  // Для активного фильтра
  const handleSelectActiveFilter = async () => {
    setModalSelectFilter(false);
    setSelectedModal('communicationChains');
    setActiveFilter(true);
    setIsOpen(true);
  };

  const closeModal = () => {
    setModalSelectFilter(false);
  };

  const renderColumnTitle = () => {
    // Выставляем для всех колонок их название кроме колонки с чекбоксом
    if (tableView && columnType !== 'checkboxColumn') {
      return t(get(COLUMN_TYPES, `${columnType}.columnName`, columnTitle))
    }
    if (!tableView) {
      return t(columnTitle);
    }
  };

  return (
    <Row
      type="flex"
      align="middle"
      gutter={[4, 0]}
      style={{ flexWrap: 'nowrap', padding: '8px', width }}
    >
      <Col style={columnType === 'checkboxColumn' && !thisIsCheckbox ? checkboxColumnStyles : ''}>
        {columnType !== 'checkboxColumn' ? (
          <Icon style={{ marginTop: 4 }} />
        ) : thisIsCheckbox ? (
          <Icon style={{ marginTop: 4 }} />
        ) : (
          <>
            {modalSelectFilter && (
              <Modal
                visible={modalSelectFilter}
                maskClosable
                destroyOnClose
                onCancel={closeModal}
                footer={[
                  <>
                    <ButtonStyledCurrentPage onClick={handleSelectCurrentPage}>
                      Только для текущей страницы
                    </ButtonStyledCurrentPage>
                    <ButtonStyledActiveFilter onClick={handleSelectActiveFilter}>
                      Для активного фильтра
                    </ButtonStyledActiveFilter>
                  </>
                ]}
              >
                <ModalTitle>
                  Применить действие для всех коммуникаций по активному фильтру или только для
                  текущей страницы?
                </ModalTitle>
              </Modal>
            )}
            <ModalWindowCheckbox
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectedModal={selectedModal}
              setSelectedModal={setSelectedModal}
              selection={selection}
              hasConflicts={hasConflicts}
              users={users}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              tableId={tableId}
            />
            <Checkbox checked={selection.allSelected} onClick={() => setModalSelectFilter(true)} />
          </>
        )}
      </Col>
      <Col style={isChecklistDefinition ? checklistDefinitionStyles : ''}>
        <Tooltip
          title={
            tableView
              ? t(get(COLUMN_TYPES, `${columnType}.columnName`, columnTitle))
              : t(columnTitle)
          }
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%'
            }}
          >
            <Text>{renderColumnTitle()}</Text>
          </div>
        </Tooltip>
        {isChecklistDefinition && (
          <ChecklistDefinitionStatusTag
            style={{ marginLeft: '10px' }}
            status={checklistDefinitionsIds[columnType]?.status}
          />
        )}
      </Col>
      {customField?.usedForAi === true && (
        <Col>
          <QolioAiIcon />
        </Col>
      )}
    </Row>
  );
};

export default ColumnTitle;
