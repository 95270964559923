import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Button, Cascader, Col, Input, Row, Select } from 'antd';
import styled from 'styled-components';
import { find, isEmpty, isNil, some, sortBy, uniqBy } from 'lodash';
import { actions } from 'redux/lists/clientInteractionsList';
import { useTranslation } from 'react-i18next';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import SRow from '../../../components/Standard/SRow';
import SCol from '../../../components/Standard/SCol';
import QolioAiIcon from '../../../components/QolioAiIcon';
import { FilterContainer, StyledSelect } from './styled';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from '../../../core/utils/constants';
import FilterName from './FilterName';
import RangeInputs from '../../../components/Inputs/RangeInputs';
import { selectSearch } from '../../../core/utils/selectSearch';
import CustomDatePicker from '../../../components/DatePicker/DatePicker';
import DurationPicker from '../../../components/Inputs/DurationPicker';
import Icon from '../../../components/Icon';
import { SText } from '../../../components/Standard';

const { Option } = Select;

const AiIconInputStyles = styled.div`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const customFields = {
  pole_string: {
    id: '1',
    type: 'custom-fields',
    name: 'Инпут',
    key: 'pole_string',
    fieldType: 'string'
  },
  pole_number: {
    id: '2',
    type: 'custom-fields',
    name: 'От до',
    key: 'pole_number',
    fieldType: 'number'
  },
  pole_string_array_2: {
    id: '5',
    type: 'custom-fields',
    name: 'Список',
    key: 'pole_string_array_2',
    fieldType: 'string_array',
    options: ['Option 1', 'Option 2', 'Option 3', 'Option 4']
  },
  pole_multipleselect: {
    id: '9',
    type: 'custom-fields',
    name: 'Мульти список',
    key: 'pole_multipleselect',
    fieldType: 'multiple_select',
    options: ['Option 1', 'Option 2', 'Option 3', 'Option 4', 'Option 5', 'Option 6', 'Option 7']
  },
  pole_datetime: {
    id: '7',
    type: 'custom-fields',
    name: 'Дата и время',
    key: 'pole_datetime',
    fieldType: 'datetime'
  },
  pole_duration: {
    id: '9',
    type: 'custom-fields',
    name: 'Продолжительность',
    key: 'pole_duration',
    fieldType: 'duration'
  }
};

const options = [
  {
    label: 'Light',
    value: 'light',
    children: Array.from({
      length: 20,
    }).map((_, index) => ({
      label: `Number ${index}`,
      value: index,
    })),
  },
  {
    label: 'Bamboo',
    value: 'bamboo',
    children: [
      {
        label: 'Little',
        value: 'little',
        children: [
          {
            label: 'Toy Fish',
            value: 'fish',
            disableCheckbox: true,
          },
          {
            label: 'Toy Cards',
            value: 'cards',
          },
          {
            label: 'Toy Bird',
            value: 'bird',
          },
        ],
      },
    ],
  },
];

const { updateCustomFieldFilters } = actions;

const AllUiFields = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customFieldFilters } = useSelector(state => state.clientInteractionsList);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const renderFieldName = name => {
    return (
      <FilterName>
        {name}
        <QolioAiIcon styles={{ marginLeft: '5px', width: '18px', height: '18px' }} />
      </FilterName>
    );
  };

  const renderCustomFieldByType = field => {
    const { id, name, key, fieldType, options, settings, dependsOnId } = field;

    // @todo вывести select мультисписок

    if (
      fieldType === CUSTOM_FIELDS_TYPES.STRING ||
      (fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY && isEmpty(options)) ||
      fieldType === CUSTOM_FIELDS_TYPES.STRING_URL
    ) {
      return (
        <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
          <Input
            value={customFieldFilters[key]?.contains || undefined}
            onChange={e =>
              dispatch(updateCustomFieldFilters({ [key]: { contains: e.target.value } }))
            }
            placeholder={name}
          />
          {/* Плашка справа */}
          <AiIconInputStyles>
            <QolioAiIcon styles={{ width: '16px', height: '16px' }} />
          </AiIconInputStyles>
        </div>
      );
    }

    if (
      fieldType === CUSTOM_FIELDS_TYPES.NUMBER ||
      fieldType === CUSTOM_FIELDS_TYPES.NUMBER_ARRAY
    ) {
      return (
        <>
          {renderFieldName(name)}
          <RangeInputs
            // disabled={disabled}
            value={customFieldFilters[key]}
            precision={0}
            onChange={({ from, to }) => dispatch(updateCustomFieldFilters({ [key]: { from, to } }))}
          />
        </>
      );
    }

    if (fieldType === CUSTOM_FIELDS_TYPES.DATETIME) {
      return (
        <>
          {renderFieldName(name)}
          <CustomDatePicker
            onChange={value => dispatch(updateCustomFieldFilters({ [key]: value }))}
            value={customFieldFilters[key] || {}}
            mode={DATE_PICKER_MODES.custom.customFields}
            isClientInteractionPage
            allowClear
            // loading={disabled}
            // disabled={disabled}
          />
        </>
      );
    }

    if (fieldType === CUSTOM_FIELDS_TYPES.BOOLEAN) {
      return (
        <>
          {renderFieldName(name)}
          <StyledSelect
            // disabled={disabled}
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            value={customFieldFilters[key]?.eq || undefined}
            onChange={value => dispatch(updateCustomFieldFilters({ [key]: { eq: value } }))}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {[
              { label: t('general.yes'), value: 'true' },
              { label: t('general.no'), value: 'false' }
            ].map(({ label, value }) => (
              <Option key={label} value={value} label={label}>
                {label}
              </Option>
            ))}
          </StyledSelect>
        </>
      );
    }

    if (fieldType === CUSTOM_FIELDS_TYPES.DURATION) {
      return (
        <>
          {renderFieldName(name)}
          <DurationPicker
            from={customFieldFilters[key]?.from}
            to={customFieldFilters[key]?.to}
            // disabled={disabled}
            size="medium"
            style={{ width: '100%' }}
            allowClear
            onChange={({ from, to }) => {
              dispatch(
                updateCustomFieldFilters({
                  [key]: {
                    from: isNil(from) ? undefined : `${from}`,
                    to: isNil(to) ? undefined : `${to}`
                  }
                })
              );
            }}
          />
        </>
      );
    }

    if (fieldType === 'multiple_select') {
      return (
        <>
          {renderFieldName(name)}
          <StyledSelect
            // disabled={disabled}
            mode="multiple"
            showSearch
            allowClear
            optionLabelProp="label"
            placeholder={name}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {options.map(option => (
              <Option key={option} value={option} label={option}>
                {option}
              </Option>
            ))}
          </StyledSelect>
        </>
      );
    }

    if (!isEmpty(settings)) {
      // id, fieldType, key, name, options, dependsOnId, settings
      const parentKey = customFields[dependsOnId]?.key;
      const parentSelectedValue = customFieldFilters[parentKey]?.eq;

      // * allow options on parent key or allow all
      const options = parentSelectedValue
        ? settings.filter(({ parentKey }) => parentKey === parentSelectedValue)
        : uniqBy(settings, 'key');

      const setControlledValueToUndefined = () => {
        if (customFieldFilters[key]?.eq) {
          updateCustomFieldFilters({ [key]: { eq: undefined } });
        }

        const childKey = find(customFields, field => field.dependsOnId === id)?.key;
        if (childKey && customFieldFilters[childKey]?.eq) {
          updateCustomFieldFilters({ [childKey]: { eq: undefined } });
        }

        return undefined;
      };

      // * if value should be controlled - check if that option exists. if not - set to undefined
      const controlledValue =
        some(options, ({ key: allowedKey }) => allowedKey === customFieldFilters[key]?.eq) ||
        !parentKey
          ? customFieldFilters[key]?.eq
          : undefined;

      if (controlledValue !== customFieldFilters[key]?.eq) {
        setControlledValueToUndefined();
      }

      return (
        <StyledSelect
          // disabled={disabled}
          showSearch
          allowClear
          optionLabelProp="label"
          placeholder={name}
          value={parentSelectedValue ? controlledValue : customFieldFilters[key]?.eq || undefined}
          onChange={value => dispatch(updateCustomFieldFilters({ [key]: { eq: value } }))}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {sortBy(options, ['text']).map(({ key, text }) => (
            <Option key={key} value={key} label={text}>
              {text}
            </Option>
          ))}
        </StyledSelect>
      );
    }

    if (!isEmpty(options)) {
      const compareType = fieldType === CUSTOM_FIELDS_TYPES.STRING_ARRAY ? 'contains' : 'eq';
      return (
        <StyledSelect
          showSearch
          allowClear
          optionLabelProp="label"
          placeholder={name}
          value={customFieldFilters[key]?.[compareType] || undefined}
          onChange={value =>
            dispatch(updateCustomFieldFilters({ [key]: { [compareType]: value } }))
          }
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {options.map(option => (
            <Option key={option} value={option} label={option}>
              {option}
            </Option>
          ))}
        </StyledSelect>
      );
    }
  };

  const renderField = field => (
    <FilterContainer key={field.id}>{renderCustomFieldByType(field)}</FilterContainer>
  );

  const toggleDropdown = () => {
    setIsDropdownVisible(prev => !prev);
  };

  // console.log('customFields filters', customFieldFilters);
  // console.log('customFields list', customFields);

  return (
    <SRow gutter={[0, 8]} style={{ width: '100%' }}>
      <SCol span={24}>
        <Row justify="space-between" align="middle">
          <Col>
            <SText fontWeight={600}>Все UI поля</SText>
          </Col>
          <Col>
            <Button type="text" onClick={toggleDropdown} style={{ padding: 0 }}>
              {isDropdownVisible ? <Icon icon={DownOutlined} /> : <Icon icon={RightOutlined} />}
            </Button>
          </Col>
        </Row>
      </SCol>
      {isDropdownVisible && (
        <SCol span={24}>
          <SRow>
            <SCol span={24}>
              {Object.values(customFields).map(field => renderField(field))}
              <Cascader
                style={{ width: '100%' }}
                options={options}
                // onChange={onChange}
                multiple
                maxTagCount="responsive"
                placeholder={'Каскадный список'}
              />
            </SCol>
          </SRow>
        </SCol>
      )}
    </SRow>
  );
};

export default AllUiFields;
