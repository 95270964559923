export default {
  communicationChain: {
    createChainModal: {
      title: 'Communication Chains',
      cancel: 'Cancel',
      create: 'Create',
      ok: 'OK',
      successCreate: 'Communication chain successfully created',
      add: 'Add',
      successAdd: 'Communication chain successfully updated',
      selectedCommunications: 'Selected communications',
      resultIsCalculated: 'The check result is being calculated',
      bulkActionCheckModal: {
        title: 'Create a Communication Chain',
        cancelTextModal: 'Are you sure you want to cancel creating the communication chain?'
      },
      columns: {
        operator: {
          title: 'Full Name'
        },
        direction: {
          title: 'Direction'
        },
        duration: {
          title: 'Duration'
        },
        clientPhoneNumber: {
          title: 'Phone Number'
        },
        score: {
          title: 'Score'
        }
      },
      fields: {
        communicationChainId: {
          tooltip:
            'This field is optional. If necessary, you can select the required CC to which the selected communications will be added.',
          placeholder: 'Select CC'
        },
        clientPhoneNumber: {
          placeholder: 'Chain number',
          emptyFieldMessage: 'Please enter the chain number'
        },
        operator: {
          placeholder: 'Employee',
          emptyFieldMessage: 'Please select an employee'
        },
        startedAt: {
          placeholder: 'Communication date',
          emptyFieldMessage: 'Please select the communication date'
        },
        duration: {
          placeholder: 'Duration',
          emptyFieldMessage: 'Please select the duration'
        }
      }
    },
    infoMessages: {
      notEnoughCommunications:
        'To create a communication chain, at least two communications are required.',
      numberAlreadyExists: 'A communication chain with this number already exists.'
    },
    chainPage: {
      removeCommunicationModal: {
        title: 'Remove communication from chain?',
        ok: 'Delete',
        cancel: 'Cancel',
        messages: {
          deleteSuccess: 'Communication deleted'
        }
      },
      columns: {
        direction: {
          title: 'Direction'
        },
        duration: {
          title: 'Duration'
        }
      }
    }
  }
};
