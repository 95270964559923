import { isEmpty } from 'lodash';
import { CACHE_EXPIRATION_TIME } from '../redux/resources/createResourceReducer';

export const loadData = resource => {
  const { loading, byIds, lastSuccessfullyLoadTime, dataNeedsUpdated } = resource;
  return (
    (isEmpty(byIds) && !loading) ||
    dataNeedsUpdated ||
    !lastSuccessfullyLoadTime ||
    Date.now() - lastSuccessfullyLoadTime > CACHE_EXPIRATION_TIME
  );
};
