import { Col, Space, Collapse, Breadcrumb } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useEffect, useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import { useTranslation } from 'react-i18next';
import UserPreview from 'components/UserPreview';
import {
  CollapseButton,
  LabelText,
  StyledBreadcrumb,
  StyledCollapse,
  TextWithTooltip
} from 'components/CrmEntityContent/styled';
import moment from 'moment';
import CopyLinkButton from 'components/new/CopyLinkButton';
import IconButton from 'components/new/IconButton';
import { ChevronsDown, ChevronsUp, ExternalLink } from 'react-feather';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';
import { useDispatch, useSelector } from 'react-redux';
import Users from 'components/CrmEntityContent/Users';
import { getClientInteractionsCrmEntityIds } from 'redux/selectors/clientInteractions';
import { compact, isEqual, get, orderBy, keys, chunk, snakeCase, reduce, omit } from 'lodash';
import { getUnitsByIds } from 'redux/selectors/units';
import ClientInteractionLink from 'components/ClientInteractionLink';
import ReviewRow from 'components/CrmEntityContent/ReviewRow';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import Icon from 'components/Icon';
import { customFieldsResource } from 'redux/resources/customFields';
import { getCustomFieldsByKeys } from 'redux/selectors/customFields';
import { Info } from 'components/Info';
import CustomFieldValueView from 'components/CustomFieldValueView';
import { getCustomFieldByKey, isCustomFieldVisible } from 'core/utils/isCustomFieldVisible';
import { CRM_ENTITY_CUSTOM_FIELDS_COLUMN_COUNT } from 'core/utils/constants';

const { Panel } = Collapse;

const CrmEntity = ({ crmEntityId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [open, setOpen] = useState(['1']);
  const [loading, setLoading] = useState(false);

  const handleCollapse = e => {
    e.preventDefault();
    if (open.length === 0) setOpen(['1']);
    else setOpen([]);
  };

  const crmEntitiesByIds = useSelector(state => state.crmEntitiesResource.byIds);
  const clientInteractions = useSelector(state =>
    orderBy(getClientInteractionsCrmEntityIds(state, crmEntityId), 'startedAt')
  );

  const customFieldsByKeys = useSelector(state => getCustomFieldsByKeys(state));

  const reviewsByIds = useSelector(state => state.reviewsResource.byIds);
  const crmEntity = crmEntitiesByIds[crmEntityId];
  const unitsByIds = useSelector(getUnitsByIds, isEqual);

  const initialLoad = async () => {
    setLoading(true);
    await Promise.all([
      await dispatch(
        taskDefinitionsResource.operations.load({
          pagination: false
        })
      ),
      await dispatch(
        clientInteractionsResource.operations.load({
          filters: { crmEntityId },
          pagination: false,
          include:
            'crm_entity,reviews.checklist,reviews.comments,operator,crm_entity.responsible_user,unit,reviews.reviewer,reviews.tasks'
        })
      ),
      await dispatch(
        customFieldsResource.operations.load({ organization_id: organizationId, pagination: false })
      )
    ]);
    setLoading(false);
  };

  useEffect(() => {
    initialLoad().then(r => r);
  }, []);

  const clientInteractionsCustomFields = clientInteractions.reduce(
    (acc, { customFields }) => ({
      ...acc,
      ...reduce(customFields, (acc, val, key) => ({ ...acc, [snakeCase(key)]: val }), {})
    }),
    {}
  );

  const customFields = omit(
    {
      ...clientInteractionsCustomFields,
      ...reduce(crmEntity?.customFields, (acc, val, key) => ({ ...acc, [snakeCase(key)]: val }), {})
    },
    ['sales-pipeline', 'sales-pipeline-status', 'sales_pipeline', 'sales_pipeline_status']
  );

  return (
    <SRow padding="0 16px 16px 16px">
      <Col span={24}>
        <SCard
          bordered="1px solid #e4e6ed"
          shadowed="0px 0px 12px rgba(0, 0, 0, 0.08)"
          headPadding="8px 24px"
          bodyPadding="0px"
          loading={loading}
          marginBottom="12px"
          rounded="0 0 8px 8px"
          title={
            !loading && (
              <>
                <SRow>
                  <StyledBreadcrumb>
                    <Breadcrumb.Item href="/client-interactions">
                      <SText color="var(--blue_6)" fontWeight={500}>
                        {t('menu.appMenu.communications')}
                      </SText>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <SText fontWeight={500} color="#82858c">
                        {crmEntity?.title || crmEntity?.integrationUid}
                      </SText>
                    </Breadcrumb.Item>
                  </StyledBreadcrumb>
                </SRow>
                <SRow paddingTop="4px" wrap={false}>
                  <SCol flex="auto">
                    <TextWithTooltip title={`${crmEntity?.title} #${crmEntity?.integrationUid}`}>
                      <SText fontSize="21px" color="#1F2026" strong style={{ lineHeight: '24px' }}>
                        {crmEntity?.title}
                      </SText>
                      <SText
                        marginLeft="8px"
                        type="secondary"
                        strong
                        fontSize="12px"
                        color="#82858c"
                      >
                        {`#${crmEntity?.integrationUid}`}
                      </SText>
                    </TextWithTooltip>
                  </SCol>

                  <SCol flex="none" marginRight="12px">
                    <CopyLinkButton destinationId={crmEntityId} destinationType="crm-entity" />
                  </SCol>
                  <SCol flex="none" margin="auto">
                    <IconButton
                      icon={<ExternalLink />}
                      tooltipProps={{
                        title: t('components.recordPlayer.externalLink'),
                        placement: 'topRight'
                      }}
                      buttonProps={{
                        onClick: () => window.open(crmEntity?.externalUrl || '/#')
                      }}
                    />
                  </SCol>
                </SRow>
              </>
            )
          }
        >
          <StyledCollapse bordered={false} activeKey={open} accordion={false}>
            <Panel key="1" showArrow={false} collapsible="disabled">
              <SRow>
                <SCol md={8} xs={24}>
                  <LabelText>{t('crmEntity.responsible.title')}</LabelText>
                  <UserPreview showAvatar={false} userId={crmEntity?.responsibleUserId} />
                  {compact([crmEntity?.firstName, crmEntity?.lastName]).join(' ')}
                </SCol>
                <SCol md={8} xs={24}>
                  <LabelText>{t('general.unit')}</LabelText>
                  <TextWithTooltip>
                    {unitsByIds?.[crmEntity?.unitId]?.name || '-/-'}
                  </TextWithTooltip>
                </SCol>
                <SCol md={8} xs={24}>
                  <LabelText>{t('crmEntity.dateOfApplication')}</LabelText>
                  <TextWithTooltip>
                    {crmEntity?.createdAt
                      ? moment(crmEntity.createdAt).format('DD/MM/YYYY, HH:mm')
                      : null}
                  </TextWithTooltip>
                </SCol>
              </SRow>

              {chunk(
                compact(
                  keys(customFields).map(customFieldName => {
                    const customField = getCustomFieldByKey(customFieldsByKeys, customFieldName);
                    return (
                      isCustomFieldVisible(customField) && (
                        <SCol key={customField?.key} md={8} xs={24}>
                          {!customField && (
                            <Info
                              tooltip={{
                                title: t('components.communicationInfoModal.emptyFieldDescription')
                              }}
                              iconProps={{ style: { color: 'var(--red_6)', marginRight: 4 } }}
                            />
                          )}
                          <LabelText>{customField?.name}</LabelText>

                          <CustomFieldValueView
                            customFields={customFields}
                            customField={customField}
                          />
                        </SCol>
                      )
                    );
                  })
                ),
                CRM_ENTITY_CUSTOM_FIELDS_COLUMN_COUNT
              ).map(items => (
                <SRow paddingTop="12px">{items.map(cell => cell)}</SRow>
              ))}
            </Panel>
          </StyledCollapse>

          <CollapseButton
            shape="round"
            icon={
              open.length === 0 ? (
                <Icon icon={ChevronsDown} size={14} style={{ strokeWidth: '3' }} />
              ) : (
                <Icon icon={ChevronsUp} size={14} style={{ strokeWidth: '3' }} />
              )
            }
            onClick={handleCollapse}
          >
            {t('pagesMeta.crmEntityPage.buttons.info')}
          </CollapseButton>
        </SCard>

        <SCard
          bordered="1px solid #e4e6ed"
          shadowed="0px 0px 12px rgba(0, 0, 0, 0.08)"
          headPadding="8px 24px"
          rounded="8px"
          loading={loading}
          title={
            !loading && (
              <SText strong fontSize="21px">
                {t('crmEntity.communications.title')}
              </SText>
            )
          }
        >
          <Space direction="vertical" style={{ display: 'block' }} size={24}>
            {clientInteractions.map(clientInteraction => {
              const reviews = get(clientInteraction, 'reviewsIds', []).map(id => reviewsByIds[id]);

              return (
                <React.Fragment key={clientInteraction?.id}>
                  <Space direction="vertical" style={{ display: 'block' }} size={8}>
                    <SRow
                      style={{ border: '1px solid #DFE1E8', borderRadius: '6px' }}
                      padding="16px"
                      backgroundColor="#f2f5ff"
                    >
                      <SCol md={8} xs={24} margin="auto 0">
                        <SRow>
                          <SCol margin="auto 0" style={{ lineHeight: 0 }}>
                            <ClientInteractionLink
                              id={clientInteraction?.id}
                              communication={clientInteraction}
                            />
                          </SCol>
                          <SCol margin="auto 0" flex="auto" display="flex">
                            <SText
                              title={clientInteraction?.title}
                              ellipsis
                              marginLeft="8px"
                              marginTop="auto"
                              marginBottom="auto"
                              fontSize="18px"
                            >
                              {clientInteraction?.title}
                            </SText>
                          </SCol>
                        </SRow>
                      </SCol>
                      <SCol md={8} xs={24} margin="auto 0" fontSize="18px" paddingRight="8px">
                        {moment(clientInteraction?.startedAt).format('DD/MM/YYYY, HH:mm')}
                      </SCol>
                      <SCol md={8} xs={24} margin="auto 0" paddingRight="8px">
                        <Users
                          fontSize={18}
                          key={clientInteraction?.operatorId}
                          operatorId={clientInteraction?.operatorId}
                        />
                      </SCol>
                    </SRow>

                    {reviews.map(review => {
                      return (
                        <ReviewRow
                          key={review.id}
                          review={review}
                          clientInteraction={clientInteraction}
                        />
                      );
                    })}
                  </Space>
                </React.Fragment>
              );
            })}
          </Space>
        </SCard>
      </Col>
    </SRow>
  );
};

export default CrmEntity;
