import { Col, Empty, Row, Typography } from 'antd';
import CustomFieldValueView from 'components/CustomFieldValueView';
import { Info } from 'components/Info';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCustomFieldByKey, isCustomFieldVisible } from 'core/utils/isCustomFieldVisible';
import SlicedTextWithTooltip from '../../SlicedTextWithTooltip';
import QolioAiIcon from '../../QolioAiIcon';

const { Text } = Typography;

const CustomFieldsTab = ({ customFields, customFieldsByKeys }) => {
  const { t } = useTranslation();

  if (isEmpty(customFields))
    return (
      <Row justify="center" align="middle">
        <Col>
          <Empty />
        </Col>
      </Row>
    );

  return (
    <Row>
      {Object.keys(customFields).map(key => {
        const customField = getCustomFieldByKey(customFieldsByKeys, key);
        if (!isCustomFieldVisible(customField)) {
          return '';
        }

        return (
          <Col key={key} span={24}>
            {!customField && (
              <Info
                tooltip={{
                  title: t('components.communicationInfoModal.emptyFieldDescription')
                }}
                iconProps={{ style: { color: 'var(--red_6)', marginRight: 4 } }}
              />
            )}
            {customField?.usedForAi && (
              <QolioAiIcon styles={{ marginRight: '5px', paddingBottom: '2px' }} />
            )}
            {/* Название кастомного поля */}
            <Text strong>
              <SlicedTextWithTooltip value={customField?.name} length={40} template="... " />
            </Text>
            {': '}
            {/* Значение кастомного поля */}
            <CustomFieldValueView
              customFields={customFields}
              customField={customField}
              maxLength={60}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export default CustomFieldsTab;
