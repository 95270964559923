import { Col, message, Row, Space, Typography } from 'antd';
import { OutlinedButton } from 'components/Buttons';
import SCard from 'components/Standard/SCard';
import { handlerEndpoint } from 'core/api';
import { INTEGRATIONS } from 'core/utils/constants';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { integrationsResource } from 'redux/resources/integrations';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const integrationTypeToUpdateTokenHandler = {
  [INTEGRATIONS.custom_http_telephony.type]:
    integrationsResource.operations.updateCustomHttpTelephonyAuthorizationToken,
  [INTEGRATIONS.custom_http_text.type]:
    integrationsResource.operations.updateCustomHttpTextAuthorizationToken
};

const UrlAndToken = ({ integration }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isCustomPhoneApi = integration?.integrationType === INTEGRATIONS.custom_http_telephony.type;

  const handleUpdateToken = async () => {
    setLoading(true);
    try {
      await dispatch(
        integrationTypeToUpdateTokenHandler[integration.integrationType]({ id: integration.id })
      );
      message.success(t('messages.success.newTokenGenerated'));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const chainUrl =
    integration?.url && isCustomPhoneApi
      ? `${integration.url
          .split('/')
          .slice(0, 3)
          .join('/')}/api/v1/communication_chains`
      : null;

  return (
    <SCard bordered shadowed>
      <Row
        align="middle"
        justify="space-between"
        gutter={[0, 32]}
        style={{ marginBottom: '-16px' }}
      >
        <Col>
          <Row align="middle" gutter={[0, 20]} style={{ marginBottom: '-10px' }}>
            {isCustomPhoneApi && (
              <Col span={24}>
                <Space direction="vertical" value={4}>
                  <Text>ID интеграции</Text>
                  <Text code copyable>
                    {integration?.id}
                  </Text>
                </Space>
              </Col>
            )}
            {isCustomPhoneApi && chainUrl && (
              <Col>
                <Space direction="vertical" value={4}>
                  <Text>URL для создания цепочек</Text>
                  <Text code copyable>
                    {chainUrl}
                  </Text>
                </Space>
              </Col>
            )}
            <Col>
              <Space direction="vertical" value={4}>
                <Text>URL для загрузки коммуникаций</Text>
                <Text code copyable>
                  {integration?.url}
                </Text>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical" value={4}>
                <Text>Token</Text>
                <Text code copyable>
                  {integration?.authorizationToken}
                </Text>
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="end">
            <Col>
              <OutlinedButton
                width="230px"
                loading={loading}
                size="big"
                onClick={handleUpdateToken}
              >
                {t('integrationsSettingsPage.urlAndToken.generate')}
              </OutlinedButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

export default UrlAndToken;
