import React from 'react';
import { CUSTOM_FIELDS_TYPES, DATE_PICKER_MODES } from '../../../../../core/utils/constants';
import FilterName from '../../../../ClientInteractionsPage/components/FilterName';
import DurationPicker from '../../../../../components/Inputs/DurationPicker';
import { isEmpty, isNil } from 'lodash';
import { StyledSelect } from '../../../../ClientInteractionsPage/components/styled';
import { selectSearch } from '../../../../../core/utils/selectSearch';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'antd';
import RangeInputs from '../../../../../components/Inputs/RangeInputs';
import CustomDatePicker from '../../../../../components/DatePicker/DatePicker';
const { Option } = Select;
const CustomFieldValue = ({ fieldName, fieldType, disabled, value, onChange, isVisibleName = true }) => {
  const { t } = useTranslation();

switch (fieldType) {
  case CUSTOM_FIELDS_TYPES.DURATION:
    return (
      <>
        {isVisibleName&&<FilterName>{fieldName}</FilterName>}
        <DurationPicker
          disabled={disabled}
          value={value}
          onChange={onChange}
          style={{ width: '100%' }}
          allowClear
        />
      </>
    );

  case CUSTOM_FIELDS_TYPES.BOOLEAN:
    return (
      <>
        {isVisibleName&&<FilterName>{fieldName}</FilterName>}
        <StyledSelect
          disabled={disabled}
          value={value}
          onChange={onChange}
          showSearch
          allowClear
          placeholder={fieldName}
        >
          {[
            { label: t('general.yes'), value: 'true' },
            { label: t('general.no'), value: 'false' }
          ].map(({ label, value }) => (
            <StyledSelect.Option key={value} value={value} label={label}>
              {label}
            </StyledSelect.Option>
          ))}
        </StyledSelect>
      </>
    );

  case CUSTOM_FIELDS_TYPES.STRING:
  case CUSTOM_FIELDS_TYPES.STRING_ARRAY:
    return (
      <>
        {isVisibleName&&<FilterName>{fieldName}</FilterName>}
        <Input disabled={disabled} value={value} onChange={onChange} placeholder={fieldName} />
      </>
    );

  case CUSTOM_FIELDS_TYPES.NUMBER:
  case CUSTOM_FIELDS_TYPES.NUMBER_ARRAY:
    return (
      <>
        {isVisibleName&&<FilterName>{fieldName}</FilterName>}
        <RangeInputs disabled={disabled} value={value} onChange={onChange} precision={0} />
      </>
    );

  case CUSTOM_FIELDS_TYPES.DATETIME:
    return (
      <>
        {isVisibleName&&<FilterName>{fieldName}</FilterName>}
        <CustomDatePicker
          mode={DATE_PICKER_MODES.custom.customFields}
          isClientInteractionPage
          allowClear
          disabled={disabled}
          value={value}
          onChange={onChange}
        />
      </>
    );

  default:
    return <div />;
}
};

export default CustomFieldValue;
