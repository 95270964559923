import React, { useState } from 'react';
import { Form, Select, InputNumber } from 'antd';
import { SCol, SRow } from '../../../../../../components/Standard';

const { Option } = Select;

const RangeInputNumber = ({ field }) => {
  const [operator, setOperator] = useState('=');
  const fieldName = Array.isArray(field.name) ? field.name : [field.name];
  return (
    <SRow align="middle" gutter={[16, 0]} alignItems="space-between">
      <SCol span={8}>
        <Form.Item noStyle name={[...fieldName, 'operator']}>
          <Select style={{ width: '100%' }} placeholder="Оператор" value={operator} onChange={setOperator}>
            <Option value="=">=</Option>
            <Option value=">">{'>'}</Option>
            <Option value="<">{'<'}</Option>
            <Option value="BETWEEN">Между</Option>
          </Select>
        </Form.Item>
      </SCol>
      {operator === 'BETWEEN' ? (
        <SCol span={16}>
          <SRow gutter={[16, 0]}>
            <SCol span={12}>
              <Form.Item noStyle name={[...fieldName, 'values', 'before']}>
                <InputNumber placeholder="От" />
              </Form.Item>
            </SCol>
            <SCol span={12}>
              <Form.Item noStyle name={[...fieldName, 'values', 'after']}>
                <InputNumber placeholder="До" />
              </Form.Item>
            </SCol>
          </SRow>
        </SCol>
      ) : (
        <SCol span={16}>
          <Form.Item noStyle name={[...fieldName, 'values', 'value']}>
            <InputNumber placeholder="Значение" />
          </Form.Item>
        </SCol>
      )}
    </SRow>
  );
};
export default RangeInputNumber;
