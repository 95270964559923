import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import { isParentHidden as checkIsParentHidden } from 'redux/selectors/comments';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import CommentText from '../Components/CommentText';
import CommentInfo from '../Components/CommentInfo';
import CreateReplyForm from '../Components/CreateReplyForm';

const marginForComment = type => {
  return type === 'review_comment' ? '8px' : '4px';
};

const ReplyComment = ({
  replyCommentId,
  commentsByIds,
  onDelete,
  onUpdate,
  allowActions,
  clientInteractionType,
  isCommentsModal = false
}) => {
  const isParentHidden = useSelector(state =>
    checkIsParentHidden({
      commentsByIds: state.commentsResource.byIds,
      commentId: replyCommentId
    })
  );
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);
  const [commentState, setCommentState] = useState({
    ...commentsByIds[replyCommentId],
    uploadedFiles: commentsByIds[replyCommentId]?.uploadedFilesIds?.map(
      id => uploadedFilesByIds[id]
    )
  });
  const [isEditing, setEditing] = useState(false);
  const [isEditReply, setEditReply] = useState(false);
  const currentCommentReply = get(commentsByIds, replyCommentId, {});

  useEffect(() => {
    if (!isEditing) {
      setCommentState({
        ...commentsByIds[replyCommentId],
        uploadedFiles: commentsByIds[replyCommentId]?.uploadedFilesIds?.map(
          id => uploadedFilesByIds[id]
        )
      });
    }
  }, [isEditing]);

  const editingCommentId = useSelector(state => state.uiClientInteractionPage.editingCommentId);

  // * используется для хранения состояния которое нужно применить если при редактировании комментария была нажата кнопка отмены
  const [initialCommentState, setInitialCommentState] = useState(null);

  return (
    <StyledCol commentType={currentCommentReply.commentType} span={23}>
      <SCard
        bordered={isEditing ? '2px solid #C5D2FB' : false}
        shadowed={
          isEditing
            ? 'inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7'
            : false
        }
      >
        <SRow type="flex" justify="space-between">
          {editingCommentId && editingCommentId === replyCommentId ? (
            <SCol span={24}>
              <CommentText
                commentState={commentState}
                setCommentState={setCommentState}
                isEditing={isEditing}
                setEditing={setEditing}
                onUpdate={onUpdate}
                initialCommentState={initialCommentState}
                allowActions={allowActions}
                setInitialCommentState={setInitialCommentState}
              />
            </SCol>
          ) : (
            <>
              <SCol span={24}>
                <CommentInfo
                  commentState={commentState}
                  isEditing={isEditing}
                  setEditing={setEditing}
                  isEditReply={isEditReply}
                  setEditReply={setEditReply}
                  editorText={commentState.text}
                  currentComment={currentCommentReply}
                  isParentHidden={isParentHidden}
                  onDelete={onDelete}
                  allowActions={allowActions}
                  setInitialCommentState={setInitialCommentState}
                  clientInteractionType={clientInteractionType}
                  isCommentsModal={isCommentsModal}
                />
              </SCol>
              <SCol span={24}>
                <CommentText
                  commentState={commentState}
                  setCommentState={setCommentState}
                  isEditing={isEditing}
                  setEditing={setEditing}
                  onUpdate={onUpdate}
                  setInitialCommentState={setInitialCommentState}
                  initialCommentState={initialCommentState}
                  allowActions={allowActions}
                />
              </SCol>
            </>
          )}
        </SRow>
      </SCard>
      <CreateReplyForm
        parentId={currentCommentReply.id}
        isEditReply={isEditReply}
        setEditReply={setEditReply}
      />
    </StyledCol>
  );
};

const StyledCol = styled(Col)`
  &.ant-col {
    margin-top: ${({ commentType }) => `${marginForComment(commentType)}`};
  }
`;

export default ReplyComment;
