import { setCheckbox } from 'redux/ui/clientInteractionPage/reducer';

let startId = null;
let endId = null;

export const handleCheckboxChange = (
  communicationId,
  operatorId,
  organizationId,
  clientInteractions,
  dispatch,
  selectedCheckBox,
  selection
) => {
  selection.toggle(communicationId);
  startId = null;
  startId = communicationId;
  const isSelected = selection.isSelected(communicationId);
  const hasOperatorId = selectedCheckBox.some(item => item.operator_id === operatorId);
  if (isSelected) {
    if (!hasOperatorId) {
      const newCheckboxItem = {
        organization_id: organizationId,
        operator_id: operatorId,
        communications: [communicationId]
      };

      dispatch(setCheckbox([...selectedCheckBox, newCheckboxItem]));
    } else {
      const updatedCheckBox = selectedCheckBox.map(item => {
        if (item.operator_id === operatorId) {
          const communications = [...item.communications, communicationId];
          return {
            ...item,
            communications
          };
        }
        return item;
      });
      dispatch(setCheckbox(updatedCheckBox));
    }
  } else {
    const deleteObjectFromState = [...selectedCheckBox].filter(
      item => item.communications.length !== 0
    );
    const deleteCheckBox = selectedCheckBox.map(item => {
      if (item.operator_id === operatorId) {
        const communications = [...item.communications].filter(id => id !== communicationId);
        return {
          ...item,
          communications
        };
      }
      return item;
    });
    dispatch(setCheckbox(deleteCheckBox));
  }
};

export const handleShiftCheckboxChange = (
  communicationId,
  organizationId,
  clientInteractions,
  dispatch,
  selectedCheckBox,
  selection,
  ids
) => {
  const collectedIds = [];
  selection.toggle(communicationId);
  if (selectedCheckBox.length >= 1) {
    endId = communicationId;
  }
  let isCollectingIds = false;
  const reverseSelect = ids.indexOf(startId) > ids.indexOf(endId);
  // Выбор чекбоксов в обратную сторону и наоборот
  if (reverseSelect) {
    for (let i = ids.length - 1; i >= 0; i--) {
      const currentId = ids[i];
      if (currentId === startId) {
        isCollectingIds = true;
      }
      if (isCollectingIds) {
        collectedIds.push(currentId);
        if (!selection.isSelected(currentId)) {
          selection.toggle(currentId);
        }
        if (!selection.isSelected(startId) && !selection.isSelected(endId)) {
          selection.toggle(startId);
          selection.toggle(endId);
        }
      }
      if (currentId === endId) {
        break;
      }
    }
  } else {
    for (let i = 0; i < ids.length; i++) {
      const currentId = ids[i];
      if (currentId === startId) {
        isCollectingIds = true;
      }
      if (isCollectingIds) {
        collectedIds.push(currentId);
        if (!selection.isSelected(currentId)) {
          selection.toggle(currentId);
        }
        if (!selection.isSelected(startId) && !selection.isSelected(endId)) {
          selection.toggle(startId);
          selection.toggle(endId);
        }
      }
      if (currentId === endId) {
        break;
      }
    }
  }
  // Изначальный массив с выбранными чекбоксами
  const clinetInteractionsSelected = clientInteractions.filter(item =>
    collectedIds.includes(item.id)
  );
  // Фильтруем и создаём нужного вида объекты
  const communicationItem = clinetInteractionsSelected.map(item => {
    return {
      organization_id: organizationId,
      operator_id: item.operatorId,
      communications: [item.id]
    };
  });
  // Объединяем communications
  const updatedCheckBox = communicationItem.reduce((acc, obj) => {
    const existingObj = acc.find(o => o.operator_id === obj.operator_id);
    if (existingObj) {
      existingObj.communications.push(...obj.communications);
    } else {
      acc.push(obj);
    }
    return acc;
  }, []);
  if (selectedCheckBox.length === 0) {
    return dispatch(setCheckbox(updatedCheckBox));
  }
  updatedCheckBox.push(...selectedCheckBox);
  // Избавляемся от дублей
  const filteredCheckbox = updatedCheckBox.reduce((acc, obj) => {
    const existingObj = acc.find(o => o.operator_id === obj.operator_id);
    if (existingObj) {
      const uniqueCommunications = [
        ...new Set([...existingObj.communications, ...obj.communications])
      ];
      existingObj.communications = uniqueCommunications;
    } else {
      acc.push(obj);
    }
    return acc;
  }, []);
  dispatch(setCheckbox(filteredCheckbox));
};
