import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';

const NotificationTable = () => {
  const history = useHistory();
  const dataSource = [
    {
      key: '1',
      name: 'Конфигурация 1',
      event: 'Проврка создана',
      channel: 'Канал 1'
    },
    {
      key: '2',
      name: 'Конфигурация 2',
      event: 'Проврка удалена',
      channel: 'Канал 2'
    }
  ];

  const columns = [
    {
      title: 'Название конфигурации',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Событие',
      dataIndex: 'event',
      key: 'event'
    },
    {
      title: 'Канал',
      dataIndex: 'channel',
      key: 'channel'
    }
  ];

  const handleRowClick = record => {
    const notificationId = record.key;
    const searchParams = new URLSearchParams();
    searchParams.set('notificationId', notificationId);
    history.push(`${history.location.pathname}:${notificationId}?${searchParams.toString()}`);
  };

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        onRow={record => ({
          onClick: () => handleRowClick(record)
        })}
      />
    </>
  );
};

export default NotificationTable;
