import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Input,
  Checkbox,
  Radio,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Form,
  message,
  Divider,
  DatePicker
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { flatMap, isEmpty, isEqual, isFunction, isNil, trim } from 'lodash';
import { actions as actionsClientInteractionsList } from 'redux/lists/clientInteractionsList';
import Text from 'antd/lib/typography/Text';
import {
  CHECKLIST_DEFINITION_STATUS,
  DATE_PICKER_MODES,
  SYMBOLIC_TIME_RANGE
} from 'core/utils/constants';
import { setDefaultState } from 'redux/ui/clientInteractions/reducer';
import { usersResource } from 'redux/resources/users';
import { customFieldsResource } from 'redux/resources/customFields';
import { statusesResource } from 'redux/resources/status';
import { rolesResource } from 'redux/resources/roles';
import { unitsResource } from 'redux/resources/units';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { actions } from 'redux/lists/appellationsListReducer';
import { aiSettingsResource } from 'redux/resources/aiSettings';
import api from 'core/api';
import { selectSearch } from 'core/utils/selectSearch';
import { operations as promptsListOperations } from 'redux/lists/promptsList';
import moment from 'moment';
import CustomDatePicker from '../../components/DatePicker/DatePicker';
import FunnelStatusAndStage from './communicationFilters/FunnelStatusAndStage';
import CommunicationTypes from './communicationFilters/CommunicationTypes';
import CustomFields from './communicationFilters/CustomFields';
import SText from '../../components/Standard/SText';
import { CustomOption } from '../../components/Inputs/ChoiceSelect';
import DurationPicker from '../../components/Inputs/DurationPicker';
import { loadData } from '../../core/utils/loadData';

const ModalTask = ({
  isModalOpen,
  setIsModalOpen,
  selectedRows = null,
  isEditingData = false,
  setIsEditingData,
  loadStatuses,
  loadCustomFields,
  loadRoles,
  loadUsers,
  loadPrompts,
  loadChecklistDefinitions,
  isCommunicationCard = false,
  startTask,
  idCommunicationCard = null,
  filterClientInteractions,
  customFieldFilters,
  clearFilters,
  updateFilters,
  salePipelines,
  updateCustomFieldFilters,
  setCustomFieldFilters,
  newTaskModal,
  setNewTaskModal = () => {},
  isTextCommunicationPage = false,
  customFieldsResourceState
}) => {
  const { t } = useTranslation();
  const status = useSelector(state => state.statusesResource.byIds, isEqual);
  const users = useSelector(state => state.usersResource.byIds, isEqual);
  const roles = useSelector(state => state.rolesResource.byIds, isEqual);
  const units = useSelector(state => state.unitsResource.byIds, isEqual);
  const checklistDefinitions = useSelector(
    state => state.checklistDefinitionsResource.byIds,
    isEqual
  );
  const [datePickerType, setDatePickerType] = useState('disposable');
  const [loading, setLoading] = useState(false);
  const [selectAllCustomFields, setSelectAllCustomFields] = useState(false);
  const customFields = useSelector(state => state.customFieldsResource.byIds, isEqual);
  const promptsResource = useSelector(state => state.promptsResource);
  const promptsResourceByIds = useSelector(state => state.promptsResource.byIds);
  const currentTimeZone = moment().format('Z');
  // Промпты с привязкой к кастомным полям
  const promptsWithCustomFields = Object.values(promptsResourceByIds).filter(
    prompt => prompt?.customFields?.length
  );
  // Идентификаторы кастомных полей
  const customFieldsIdsFromPromptsBindings = promptsWithCustomFields.flatMap(prompt =>
    (prompt.customFields || []).map(field => field.custom_field_id)
  );
  const customFieldsIdsSet = new Set(customFieldsIdsFromPromptsBindings);
  // Находим все сущности кастомных полей который привязаны к промпту по идентификаторам кастомноных полей
  const customFieldsWithBindings = Object.values(customFields).filter(customField =>
    customFieldsIdsSet.has(customField?.id)
  );
  const [activeConditionSlector, setActiveConditionSlector] = useState(false);
  const [duration, setDuration] = useState({ from: 25, to: 25 })

  const [conditionsDrawingSelect, setConditionsDrawingSelect] = useState([
    { value: 'Статус проверки', label: 'Статус проверки', isActive: true },
    { value: 'Операторы', label: 'Операторы', isActive: true },
    { value: 'Длительность звонка', label: 'Длительность звонка', isActive: true },
    { value: 'Уровни', label: 'Уровни', isActive: true },
    { value: 'Отдел', label: 'Отдел', isActive: true },
    { value: 'Период', label: 'Дата сбора коммуникаций для анализа', isActive: true },
    { value: 'Статус контакта', label: 'Статус контакта', isActive: true },
    { value: 'По статусу и этапу воронки', label: 'По статусу и этапу воронки', isActive: true },
    { value: 'Тип коммуникации', label: 'Тип коммуникации', isActive: true },
    { value: 'По кастомным полям', label: 'По кастомным полям', isActive: true }
  ]);

  const { filters } = useSelector(state => state.appellationsList);
  const [selectedDataset, setSelectedDataset] = useState([]);
  const currentUserAttributes = useSelector(state => state.reduxTokenAuth.currentUser.attributes);
  const organizationId = currentUserAttributes?.user['organization-id'];
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const disableEditBtn = selectedRows && selectedRows.taskType === 'disposable';
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(users)) {
      loadUsers({
        pagination: 'false',
        include: 'unit,role.levels',
        sort: 'last_name',
        'filters[with_inactive]': 'true'
      });
    }
    if (isEmpty(status)) {
      loadStatuses({ pagination: 'false' });
    }
    if (loadData(customFieldsResourceState)) {
      loadCustomFields({
        organization_id: organizationId,
        pagination: false
      });
    }
    if (isEmpty(roles)) {
      loadRoles();
    }
    if (!isTextCommunicationPage) {
      loadChecklistDefinitions({ pagination: 'false' });
    }
    if (loadData(promptsResource)) {
      loadPrompts({ organization_id: organizationId, pagination: 'false' });
    }
    if (isEmpty(selectedDataset)) {
      api.getDataSet({ organization_id: organizationId }).then(response => {
        setSelectedDataset(response.body.data);
      });
    }
    // Сбрасываем фильтр даты при открытии (Новая задача), для того чтобы дата не устанавливалась с предыдущей открытой задачи
    dispatch(
      actions.updateFilters({
        appellationCreatedAtSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH,
        appellationCreatedAtTimeFrom: undefined,
        appellationCreatedAtTimeTo: undefined
      })
    );
    form.setFieldsValue({
      taskName: isCommunicationCard ? idCommunicationCard : '',
      taskEnable: false,
      taskType: 'disposable',
      checklistDefinition: null,
      dataset: null,
      prompts: [],
      operators: [],
      contactStatus: [],
      departments: [],
      levels: [],
      checkedStatus: null,
      funnelStatusAndStage: [],
      communicationType: [],
      byCustomFields: []
    });
    setDuration({ from: 25, to: 25 })
    return setDefaultState;
  }, []);

  const initEditDataModal = initData => {
    const filter = JSON.parse(initData.filter);
    form.setFieldsValue({
      taskName: initData.name || '',
      taskEnable: initData.isActive || false,
      taskType: initData.taskType || 'disposable',
      checklistDefinition: initData.checklistId || null,
      dataset: initData.dataset || null,
      prompts: JSON.parse(initData.customFieldsIds) || [],
      operators: filter.operators || [],
      contactStatus: filter.contactStatus || [],
      departments: filter.departments || [],
      levels: filter.levels || [],
      checkedStatus: filter.checkedStatus || null,
      communicationType: filter.communication_type || [],
      byCustomFields: filter.custom_fields || []
    });
    setDuration(filter.duration || { from: 25, to: 25 })
    dispatch(
      actions.updateFilters({
        appellationCreatedAtSymbolicTimeRange: filter?.period?.symbolicTimeRange,
        appellationCreatedAtTimeFrom: filter?.period?.timeFrom,
        appellationCreatedAtTimeTo: filter?.period?.timeTo
      })
    );

    const communicationsTypes = filter?.communication_type;
    // Устанавливаем типы коммуникаций для карточки
    if (communicationsTypes) {
      updateFilters({ communicationsTypes });
    }

    const pipelinesIds = filter?.sales_pipelines;
    // Устанавливаем воронки коммуникаций для карточки
    if (pipelinesIds) {
      const allowedPipelinesStatusesIds = flatMap(
        salePipelines.filter(({ id }) => pipelinesIds.includes(id)),
        'statusesIds'
      );

      updateFilters({
        pipelinesIds,
        pipelineStatusesIds:
          isEmpty(filters.pipelineStatusesIds) || isEmpty(pipelinesIds)
            ? filters.pipelineStatusesIds
            : filters.pipelineStatusesIds.filter(statusId => {
                return allowedPipelinesStatusesIds.includes(statusId);
              })
      });
    }

    const pipelineStatusesIds = filter?.sales_pipeline_statuses;
    // Устанавливаем этапы воронки коммуникаций для карточки
    if (pipelineStatusesIds) {
      updateFilters({ pipelineStatusesIds });
    }

    const customFields = filter?.custom_fields;
    // Устанавливаем кастомные поля для карточки
    if (customFields) {
      Object.entries(customFields).forEach(([key, { from, to }]) => {
        updateCustomFieldFilters({
          [key]: {
            from: isNil(from) ? undefined : `${from}`,
            to: isNil(to) ? undefined : `${to}`
          }
        });
      });
    }

    setConditionsDrawingSelect(prevState => {
      return prevState.map(condition => {
        switch (condition.value) {
          case 'Статус проверки':
            condition.isActive = !filter?.checkedStatus;
            break;
          case 'Операторы':
            condition.isActive = !filter?.operators;
            break;
          case 'Уровни':
            condition.isActive = !filter?.levels;
            break;
          case 'Отдел':
            condition.isActive = !filter?.departments;
            break;
          case 'Длительность звонка (в секундах)':
            condition.isActive = !filter?.duration;
            break;
          case 'Период':
            condition.isActive = !filter?.period;
            break;
          case 'Статус контакта':
            condition.isActive = !filter?.contactStatus;
            break;
          case 'По статусу и этапу воронки':
            condition.isActive = !filter?.sales_pipeline_statuses;
            condition.isActive = !filter?.sales_pipelines;
            break;
          case 'Тип коммуникации':
            condition.isActive = !filter?.communication_type;
            break;
          case 'По кастомным полям':
            condition.isActive = !filter?.custom_fields;
            break;
          default:
            break;
        }
        return condition;
      });
    });
  };

  useEffect(async () => {
    if (isEditingData) {
      await initEditDataModal(selectedRows);
    }
  }, [selectedRows]);

  const resetDataModal = async () => {
    clearFilters();
    setCustomFieldFilters({});
    setIsEditingData(false);
    setActiveConditionSlector(false);
    setNewTaskModal(false);
    setDuration({ from: 25, to: 25 })
    dispatch(
      actions.updateFilters({
        appellationCreatedAtSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH,
        appellationCreatedAtTimeFrom: null,
        appellationCreatedAtTimeTo: null
      })
    );
    form.setFieldsValue({
      taskName: isCommunicationCard ? idCommunicationCard : '',
      taskEnable: false,
      taskType: 'disposable',
      checklistDefinition: null,
      dataset: null,
      prompts: [],
      operators: [],
      contactStatus: [],
      departments: [],
      levels: [],
      checkedStatus: null,
      byCustomFields: []
    });
    const updatedConditions = conditionsDrawingSelect.map(condition => {
      return { ...condition, isActive: true };
    });
    setConditionsDrawingSelect(updatedConditions);
    await setActiveConditionSlector(false);
    setIsModalOpen(false);
    setLoading(false);
  };

  const handleSaveTask = async value => {
    setLoading(true);
    let dataPeriod = null;
    conditionsDrawingSelect.forEach(condition => {
      if (!condition.isActive) {
        const timeFrom = filters.appellationCreatedAtTimeFrom
          ? filters.appellationCreatedAtTimeFrom.split('+')[0]
          : undefined;
        const timeTo = filters.appellationCreatedAtTimeTo
          ? filters.appellationCreatedAtTimeTo.split('+')[0]
          : undefined;
        switch (condition.value) {
          case 'Период':
            dataPeriod = {
              symbolicTimeRange: filters.appellationCreatedAtSymbolicTimeRange,
              timeFrom,
              timeTo
            };
            break;
        }
      }
    });

    const filter = {};
    const keys = ['levels', 'operators', 'departments', 'checkedStatus', 'contactStatus'];

    // Обработка duration
    if (duration) {
      filter.duration = {
        from: duration.from ?? undefined,
        to: duration.to ?? undefined
      };
    }

    // Если это регулярная задача и юзер не выбирал дату устанавливаем по умолчанию сегоднящнюю
    if (value?.taskType === 'constant' && isEmpty(dataPeriod?.timeFrom)) {
      conditionsDrawingSelect.forEach(condition => {
        if (!condition.isActive) {
          switch (condition.value) {
            case 'Период':
              const currentDateTime = moment().format('YYYY-MM-DD');
              dataPeriod = {
                timeFrom: currentDateTime
              };
              break;
          }
        }
      });
    }

    if (!isEmpty(dataPeriod)) {
      filter.period = dataPeriod;
    }
    if (idCommunicationCard) {
      filter.id = idCommunicationCard;
    }
    if (filterClientInteractions?.pipelineStatusesIds) {
      filter.sales_pipeline_statuses = filterClientInteractions.pipelineStatusesIds;
    }

    if (filterClientInteractions?.pipelinesIds) {
      filter.sales_pipelines = filterClientInteractions.pipelinesIds;
    }

    if (filterClientInteractions?.communicationsTypes) {
      filter.communication_type = filterClientInteractions.communicationsTypes;
    }

    if (!isEmpty(customFieldFilters)) {
      filter.custom_fields = customFieldFilters;
    }

    keys.forEach(key => {
      if (!isEmpty(value?.[key])) {
        filter[key] = value[key];
      }
    });

    if (isEditingData) {
      await dispatch(
        aiSettingsResource.operations.updateById({
          id: selectedRows.id,
          actions: {
            update_name: value.taskName,
            update_activity: value.taskEnable,
            update_filter: JSON.stringify(filter),
            update_checklist_id: value.checklistDefinition,
            update_custom_fields_ids: value.prompts,
            update_dataset_id: value.dataset ? value.dataset : 'null',
            timezone: currentTimeZone
          }
        })
      ).then(response => {
        if (response?.id) {
          message.success(t('aiSettingsPage.operations.operationSuccessfully'));
        }
      });
    } else {
      await dispatch(
        aiSettingsResource.operations.create({
          organization_id: organizationId,
          name: value.taskName,
          checklist_id: value.checklistDefinition,
          custom_fields_ids: value.prompts,
          task_type: isCommunicationCard ? 'immediate' : value.taskType,
          filter: JSON.stringify(filter),
          dataset_id: value.dataset ? value.dataset : 'null',
          timezone: currentTimeZone
        })
      ).then(response => {
        if (response?.id) {
          if (isFunction(startTask)) {
            startTask(response?.id);
          }
          Modal.warning({
            content: t('aiSettingsPage.operations.saveAiTask')
          });
        }
      });
    }
    resetDataModal();
  };

  const handleConditionChange = value => {
    const updatedConditions = conditionsDrawingSelect.map(condition => {
      if (condition.value === value) {
        if (value === 'По кастомным полям') {
          setCustomFieldFilters({}); // Очистка выбранных кастомных полей из стейта
        }
        if (value === 'Тип коммуникации') {
          clearFilters('communicationsTypes'); // Очистка выбранных типов коммуникаций из стейта
        }
        if (value === 'По статусу и этапу воронки') {
          clearFilters('pipelineStatusesIds');
          clearFilters('pipelinesIds');
        }
        if (value === 'Уровни') {
          form.setFieldsValue({ levels: [] });
        }
        if (value === 'Операторы') {
          form.setFieldsValue({ operators: [] });
        }
        if (value === 'Отдел') {
          form.setFieldsValue({ departments: [] });
        }
        if (value === 'Статус контакта') {
          form.setFieldsValue({ contactStatus: [] });
        }
        if (value === 'Статус проверки') {
          form.setFieldsValue({ checkedStatus: null });
        }
        if (value === 'Период') {
          dispatch(
            actions.updateFilters({
              appellationCreatedAtSymbolicTimeRange: 'this_month',
              appellationCreatedAtTimeFrom: undefined,
              appellationCreatedAtTimeTo: undefined
            })
          );
        }
        return { ...condition, isActive: !condition.isActive };
      }
      return condition;
    });
    setConditionsDrawingSelect(updatedConditions);
    setActiveConditionSlector(false);
  };

  const handleAddCondition = () => {
    setActiveConditionSlector(true);
  };

  const allInactive = conditionsDrawingSelect.every(item => !item.isActive);

  const handleSelectAllCustomFields = () => {
    const allValues = selectAllCustomFields ? [] : customFieldsWithBindings.map(unit => unit.id);
    form.setFieldsValue({
      prompts: allValues
    });
    setSelectAllCustomFields(!selectAllCustomFields);
  };
  const renderTaskFilterTitle = value => {
    return (
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: '10px'
        }}
      >
        <Col>
          <Text style={{ fontWeight: '700' }}>{value}</Text>
        </Col>
        <Col>
          <DeleteOutlined
            style={{
              color: 'red',
              marginLeft: '8px',
              fontSize: '20px',
              cursor: isEditingData && disableEditBtn ? 'not-allowed' : 'pointer'
            }}
            onClick={() =>
              isEditingData && disableEditBtn ? console.log('') : handleConditionChange(value)
            }
          />
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setDatePickerType(form.getFieldValue('taskType'));
  }, [datePickerType, selectedRows]);

  return (
    <Modal
      title={
        isEditingData
          ? t('aiSettingsPage.taskSettings.edit')
          : t('aiSettingsPage.taskSettings.newTask')
      }
      centered
      visible={isModalOpen}
      footer={null}
      width={550}
      onCancel={resetDataModal}
    >
      <div style={{ maxHeight: '700px', overflowY: 'auto', padding: '0 15px 0 0' }}>
        <Form
          ref={formRef}
          form={form}
          layout="vertical"
          onFinish={values => {
            if (!values.checklistDefinition && values.prompts.length === 0) {
              message.error(t('aiSettingsPage.operations.checkAIAssessmentFormOrPromptsValues'));
            } else {
              handleSaveTask(values);
            }
          }}
        >
          <Form.Item
            label={t('aiSettingsPage.taskSettings.enterName')}
            name="taskName"
            rules={[
              { required: true, message: t('aiSettingsPage.taskSettings.pleaseEnterTaskName') }
            ]}
          >
            <Input
              placeholder={t('aiSettingsPage.taskSettings.enterName')}
              disabled={isEditingData && disableEditBtn}
            />
          </Form.Item>
          {!isCommunicationCard && (
            <>
              <Form.Item name="taskEnable" valuePropName="checked">
                <Checkbox disabled={!isEditingData || (isEditingData && disableEditBtn)}>
                  {t('aiSettingsPage.taskSettings.disableEnable')}
                </Checkbox>
              </Form.Item>
              <Form.Item name="taskType" label={t('aiSettingsPage.taskSettings.taskType')}>
                <Radio.Group
                  disabled={isEditingData}
                  onChange={e => setDatePickerType(e.target.value)}
                >
                  <Radio value="disposable">{t('aiSettingsPage.taskSettings.oneTime')}</Radio>
                  <Radio value="constant">{t('aiSettingsPage.taskSettings.regular')}</Radio>
                </Radio.Group>
              </Form.Item>
              <Row style={{ margin: '16px 0' }}>
                <Col>
                  <Typography.Text>
                    {t('aiSettingsPage.taskSettings.communicationFilter')}
                  </Typography.Text>
                </Col>
              </Row>
              {conditionsDrawingSelect.map(
                condition =>
                  !condition.isActive && (
                    <Row
                      style={{
                        width: '100%',
                        display: 'flex',
                        // flexWrap: 'nowrap',
                        alignItems: 'center',
                        marginTop: '8px'
                      }}
                    >
                      {renderTaskFilterTitle(condition.value)}
                      <Col style={{ width: '100%' }}>
                        {condition.value === 'Операторы' && (
                          <Form.Item name="operators" style={{ marginBottom: '0' }}>
                            <Select
                              allowClear
                              mode="multiple"
                              placeholder={t('aiSettingsPage.taskSettings.operators')}
                              size="middle"
                              maxTagCount={0}
                              maxTagPlaceholder={selectedKeys =>
                                `${t('aiSettingsPage.taskSettings.operators')}: ${
                                  selectedKeys.length
                                }`
                              }
                              defaultOpen={isEditingData ? false : !!isModalOpen}
                              filterOption={(input, option) =>
                                selectSearch({ input, option, searchProp: 'children' })
                              }
                            >
                              {Object.values(users).map(user => {
                                const userName = user?.name;
                                const email = user?.email;
                                if (userName && !isEmpty(trim(userName))) {
                                  return (
                                    <Select.Option key={user.id} value={user.id}>
                                      {user.name}
                                    </Select.Option>
                                  );
                                }
                                return (
                                  <Select.Option key={user.id} value={user.id}>
                                    {email}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        )}
                        {condition.value === 'Статус контакта' && (
                          <Form.Item name="contactStatus" style={{ marginBottom: '0' }}>
                            <Select
                              allowClear
                              mode="multiple"
                              placeholder={t('aiSettingsPage.taskSettings.contactStatus')}
                              size="middle"
                              maxTagCount={0}
                              maxTagPlaceholder={selectedKeys =>
                                `${t('aiSettingsPage.taskSettings.contactStatus')}: ${
                                  selectedKeys.length
                                }`
                              }
                              defaultOpen={isEditingData ? false : !!isModalOpen}
                              filterOption={(input, option) =>
                                selectSearch({ input, option, searchProp: 'children' })
                              }
                            >
                              {Object.values(status).map(unit => (
                                <Select.Option key={unit.id} value={unit.id}>
                                  {unit.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                        {condition.value === 'Отдел' && (
                          <Form.Item name="departments" style={{ marginBottom: '0' }}>
                            <Select
                              allowClear
                              mode="multiple"
                              placeholder={t('aiSettingsPage.taskSettings.department')}
                              size="middle"
                              maxTagCount={0}
                              maxTagPlaceholder={selectedKeys =>
                                `${t('aiSettingsPage.taskSettings.department')}: ${
                                  selectedKeys.length
                                }`
                              }
                              defaultOpen={isEditingData ? false : !!isModalOpen}
                              filterOption={(input, option) =>
                                selectSearch({ input, option, searchProp: 'children' })
                              }
                            >
                              {Object.values(units).map(unit => (
                                <Select.Option key={unit.id} value={unit.id}>
                                  {unit.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                          {condition.value === 'Длительность звонка' && (
                        <Form.Item style={{ marginBottom: '0' }} name="duration">
                          <DurationPicker
                            allowClear
                            from={duration?.from}
                            to={duration?.to}
                            onChange={({ from, to }) => {
                              setDuration({
                                  from: isNil(from) ? undefined : `${from < 25 ? 25 : from}`,
                                  to: isNil(to) ? undefined : `${to < 25 ? 25 : to}`
                              });
                            }}
                          />
                        </Form.Item>
                         )}
                        {condition.value === 'Уровни' && (
                          <Form.Item name="levels" style={{ marginBottom: '0' }}>
                            <Select
                              allowClear
                              mode="multiple"
                              placeholder={t('aiSettingsPage.taskSettings.levels')}
                              size="middle"
                              maxTagCount={0}
                              maxTagPlaceholder={selectedKeys =>
                                `${t('aiSettingsPage.taskSettings.levels')}: ${selectedKeys.length}`
                              }
                              defaultOpen={isEditingData ? false : !!isModalOpen}
                              filterOption={(input, option) =>
                                selectSearch({ input, option, searchProp: 'children' })
                              }
                            >
                              {Object.values(roles).map(unit => (
                                <Select.Option key={unit.id} value={unit.id}>
                                  {unit.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                        {condition.value === 'Период' && datePickerType === 'disposable' && (
                          <Form.Item style={{ marginBottom: '0' }}>
                            <CustomDatePicker
                              onChange={filters => {
                                dispatch(actions.updateFilters({ ...filters }));
                              }}
                              value={{
                                symbolicTimeRange: filters?.appellationCreatedAtSymbolicTimeRange,
                                timeFrom: filters?.appellationCreatedAtTimeFrom,
                                timeTo: filters?.appellationCreatedAtTimeTo
                              }}
                              position="left"
                              mode={DATE_PICKER_MODES.custom.appellation}
                              isAiSettingsPage
                            />
                          </Form.Item>
                        )}
                        {condition.value === 'Период' && datePickerType === 'constant' && (
                          <Form.Item style={{ marginBottom: '0' }}>
                            <DatePicker
                              onChange={dates => {
                                if (dates) {
                                  const formattedDate = dates.endOf('day').format('YYYY-MM-DD');

                                  dispatch(
                                    actions.updateFilters({
                                      appellationCreatedAtTimeFrom: formattedDate
                                    })
                                  );
                                }
                              }}
                              value={moment(filters?.appellationCreatedAtTimeFrom)}
                              allowClear={false}
                            />
                          </Form.Item>
                        )}
                        {condition.value === 'Статус проверки' && (
                          <Form.Item name="checkedStatus" style={{ marginBottom: '0' }}>
                            <Select
                              allowClear
                              placeholder={t('aiSettingsPage.taskSettings.checkStatus')}
                              size="middle"
                              defaultValue="all"
                              optionLabelProp="label"
                              defaultOpen={isEditingData ? false : !!isModalOpen}
                            >
                              <Select.Option
                                key="all"
                                value="all"
                                label={`${t(
                                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                                )}: ${t('constants.reviewStatuses.all')}`}
                              >
                                {t('constants.reviewStatuses.all')}
                              </Select.Option>
                              <Select.Option
                                key="reviewed"
                                value="true"
                                label={`${t(
                                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                                )}: ${t('constants.reviewStatuses.reviewed')}`}
                              >
                                {t('constants.reviewStatuses.reviewed')}
                              </Select.Option>
                              <Select.Option
                                key="notReviewed"
                                value="false"
                                label={`${t(
                                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.reviewStatus'
                                )}: ${t('constants.reviewStatuses.notReviewed')}`}
                              >
                                {t('constants.reviewStatuses.notReviewed')}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        )}
                        {condition.value === 'По статусу и этапу воронки' && (
                          <Form.Item name="funnelStatusAndStage" style={{ marginBottom: '0' }}>
                            <FunnelStatusAndStage />
                          </Form.Item>
                        )}
                        {condition.value === 'Тип коммуникации' && (
                          <Form.Item name="communicationType" style={{ marginBottom: 0 }}>
                            <CommunicationTypes />
                          </Form.Item>
                        )}
                        {condition.value === 'По кастомным полям' && (
                          <Form.Item name="byCustomFields" style={{ marginBottom: '0' }}>
                            <CustomFields
                              isEditingData={isEditingData}
                              disableEditBtn={disableEditBtn}
                              selectedRows={selectedRows}
                              isVisibleName={false}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  )
              )}

              <Form.Item name="condition">
                {!activeConditionSlector && !allInactive && (
                  <Col
                    style={{
                      color: '#85a5ff',
                      cursor: isEditingData && disableEditBtn ? 'not-allowed' : 'pointer'
                    }}
                    onClick={isEditingData && disableEditBtn ? console.log('') : handleAddCondition}
                  >
                    {t('aiSettingsPage.taskSettings.addCondition')}
                  </Col>
                )}
                {activeConditionSlector && conditionsDrawingSelect.length !== 0 && (
                  <Select
                    placeholder={t('aiSettingsPage.taskSettings.addCondition')}
                    size="middle"
                    onChange={handleConditionChange}
                    // open={activeConditionSlector && conditionsDrawingSelect.length !== 0}
                    style={{ marginTop: '16px' }}
                  >
                    {conditionsDrawingSelect.map(
                      conditionDrawingSelect =>
                        conditionDrawingSelect.isActive && (
                          <Select.Option value={conditionDrawingSelect.value}>
                            {conditionDrawingSelect.label}
                          </Select.Option>
                        )
                    )}
                  </Select>
                )}
              </Form.Item>
            </>
          )}
          <Form.Item
            label={t('aiSettingsPage.taskSettings.selectAiRatingForm')}
            name="checklistDefinition"
          >
            <Select
              placeholder={t('aiSettingsPage.taskSettings.rating')}
              size="middle"
              allowClear
              showSearch
              optionLabelProp="label"
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
            >
              {Object.values(checklistDefinitions)
                .filter(
                  checklistDefinition =>
                    checklistDefinition.status === CHECKLIST_DEFINITION_STATUS.PUBLISHED.value
                )
                .map(unit => (
                  <Select.Option
                    key={unit.id}
                    value={unit.id}
                    label={unit.name}
                    disabled={isEditingData && disableEditBtn}
                  >
                    {unit.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {/* Выберите кастомные поля */}
          <Form.Item label={t('aiSettingsPage.taskSettings.selectCustomFields')} name="prompts">
            <Select
              mode="multiple"
              placeholder={t('aiSettingsPage.taskSettings.customField')}
              size="middle"
              maxTagCount={0}
              maxTagPlaceholder={selectedKeys =>
                `${t('aiSettingsPage.taskSettings.customField')}: ${selectedKeys.length}`
              }
              allowClear
              showSearch
              optionLabelProp="label"
              filterOption={(input, option) =>
                selectSearch({ input, option, searchProp: 'children' })
              }
              dropdownRender={menu => (
                <>
                  {menu}
                  <Divider type="horizontal" style={{ margin: 0 }} />
                  <CustomOption
                    className="ant-select-item ant-select-item-option"
                    onClick={handleSelectAllCustomFields}
                  >
                    <SText strong>{selectAllCustomFields ? 'Убрать все' : 'Выбрать все'}</SText>
                  </CustomOption>
                </>
              )}
            >
              {Object.values(customFieldsWithBindings).map(unit => (
                <Select.Option
                  key={unit.id}
                  value={unit.id}
                  label={unit.name}
                  disabled={isEditingData && disableEditBtn}
                >
                  {unit.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={t('aiSettingsPage.taskSettings.selectDataset')} name="dataset">
            <Select
              placeholder={t('components.timeSpecificSelector.selectButton')}
              size="middle"
              allowClear
            >
              {Array.isArray(selectedDataset) &&
                Object.values(selectedDataset).map(unit => (
                  <Select.Option
                    key={unit.id}
                    value={unit?.attributes?.openai_id}
                    disabled={isEditingData && disableEditBtn}
                  >
                    {unit?.attributes?.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div
        style={{
          padding: '12px 12px 0 0',
          display: 'flex',
          justifyContent: 'flex-end',
          borderTop: '1px solid #f0f0f0'
        }}
      >
        <Button onClick={resetDataModal}>
          {isEditingData ? t('general.closeText') : t('general.cancel')}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={() => {
            formRef.current.submit();
          }}
          loading={loading}
          disabled={isEditingData && disableEditBtn}
          style={{ marginLeft: 8 }}
        >
          {isEditingData ? t('general.edit') : t('general.save')}
        </Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { filters: filterClientInteractions } = state.clientInteractionsList;
  const { customFieldFilters } = state.clientInteractionsList;
  const { byIds: salePipelinesByIds } = state.salePipelinesResource;
  return {
    filters: ownProps?.hasConflicts ? {} : filterClientInteractions,
    customFields: state.customFieldsResource.byIds,
    filterClientInteractions,
    customFieldFilters,
    salePipelines: Object.values(salePipelinesByIds),
    customFieldsResourceState: state.customFieldsResource
  };
};

const mapDispatchToProps = {
  loadCustomFields: customFieldsResource.operations.load,
  loadStatuses: statusesResource.operations.load,
  loadRoles: rolesResource.operations.load,
  loadUsers: usersResource.operations.load,
  loadUnits: unitsResource.operations.loadUnits,
  loadPrompts: promptsListOperations.load,
  setDefaultState,
  loadChecklistDefinitions: checklistDefinitionsResource.operations.loadWithInclude,
  clearFilters: actionsClientInteractionsList.clearFilters,
  updateFilters: actionsClientInteractionsList.updateFilters,
  updateCustomFieldFilters: actionsClientInteractionsList.updateCustomFieldFilters,
  setCustomFieldFilters: actionsClientInteractionsList.setCustomFieldFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalTask);
