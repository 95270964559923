import React, { useEffect } from 'react';
import { Modal } from 'antd';
import moment from 'moment/moment';
import Text from 'antd/lib/typography/Text';
import { useDispatch, useSelector } from 'react-redux';
import { actions, operations } from 'redux/lists/userFieldsAnalyticsCommunicationsList';
import ClientInteractionLink from '../../../../components/ClientInteractionLink';
import STable from '../../../../components/Standard/STable';
import UserPreview from '../../../../components/UserPreview';

const loadUserFieldsAnalyticsCommunications = operations.load;

const ClientInteractionsLinks = ({ visible, setVisible, data, widgetData }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  useEffect(() => {
    if (visible) {
      dispatch(
        loadUserFieldsAnalyticsCommunications({
          widget_id: widgetData?.id,
          widget_type: widgetData?.type,
          organization_id: organizationId,
          pagination: false,
          operator_id: data?.data?.operatorId
        })
      );
    }
  }, [visible]);

  const reviewsResource = useSelector(state => state.reviewsResource.byIds);
  const { loading, byIds: communications } = useSelector(
    state => state.userFieldsAnalyticsCommunicationsResource
  );
  const { page, ids } = useSelector(state => state.userFieldsAnalyticsCommunicationsList);

  const filteredCommunications = Object.values(communications).filter(item =>
    ids.includes(item.id)
  );

  const filteredReviews = Object.values(reviewsResource).filter(
    item => item?.operatorId === data?.data?.operatorId
  );

  const communicationsMap = new Map(
    filteredCommunications.map(item => [
      item.id,
      {
        clientInteractionType: item.clientInteractionType,
        communicationType: item.communicationType,
        direction: item.direction,
        clientPhoneNumber: item.clientPhoneNumber
      }
    ])
  );

  const reviews = filteredReviews.map(review => {
    const communicationData = communicationsMap.get(review.clientInteractionId);
    if (communicationData) {
      return {
        ...review,
        clientInteractionType: communicationData.clientInteractionType,
        communicationType: communicationData.communicationType,
        direction: communicationData.direction,
        clientPhoneNumber: communicationData.clientPhoneNumber
      };
    }
    return review;
  });

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 50,
      render: (text, record) => {
        const communication = filteredCommunications.find(
          item => item.id === record?.clientInteractionId
        );
        return (
          <ClientInteractionLink
            id={text}
            isReview
            communication={communication || []}
            isNewPage
            useDrawer={false}
          />
        );
      }
    },
    {
      title: 'ФИО',
      dataIndex: 'operatorId',
      key: 'operatorId',
      width: 250,
      render: text => <UserPreview disabled userId={text} showAvatar truncateSize={25} />
    },
    {
      title: 'Дата и время',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: text => moment(text).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Номер телефона',
      dataIndex: 'clientPhoneNumber',
      key: 'clientPhoneNumber',
      width: 150,
      render: text => <Text>{text}</Text>
    },
    {
      title: 'Направление',
      dataIndex: 'direction',
      key: 'direction',
      width: 120,
      render: text => <Text>{text}</Text>
    }
  ];

  const handleTableChange = pagination => {
    const { pageSize, current } = pagination;
    dispatch(
      actions.updatePage({
        size: pageSize.toString(),
        number: current.toString()
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      actions.updatePage({
        size: 10,
        number: 1
      })
    );
    setVisible(false);
  };

  return (
    <>
      {visible && (
        <Modal
          title="Список коммуникаций"
          centered
          visible
          footer={null}
          width={900}
          onCancel={() => handleCloseModal()}
        >
          <STable
            rowKey="id"
            size="small"
            loading={loading}
            dataSource={reviews}
            columns={columns}
            border
            scroll={{ y: window.innerWidth > 1320 ? 'calc(100vh - 286px)' : 'calc(100vh - 356px)' }}
            pagination={{
              pageSize: parseInt(page.size),
              current: parseInt(page.number),
              total: reviews.length,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50']
            }}
            onChange={handleTableChange}
          />
        </Modal>
      )}
    </>
  );
};

export default ClientInteractionsLinks;
