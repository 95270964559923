import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Tooltip, Popconfirm } from 'antd';
import UserPreview from 'components/UserPreview';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from 'redux/selectors/users';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import Icon from 'components/Icon';
import moment from 'moment';
import { isEqual, get } from 'lodash';
import SButton from 'components/Standard/SButton';
import { COMMUNICATION_TYPES, PERMISSIONS } from 'core/utils/constants';
import { setEditingCommentId } from 'redux/ui/clientInteractionPage/reducer';
import { selectComment } from 'redux/ui/comments/reducer';
import styled from 'styled-components';
import { Trash, Edit3, CornerUpLeft } from 'react-feather';
import TimestampButton from './TimestampButton';

const CommentInfo = ({
  isParentHidden,
  currentComment,
  isEditing,
  setIsEditing,
  setEditReply,
  isEditReply,
  onDelete,
  commentType,
  commentState,
  commentTimeStamp,
  allowActions,
  isQuestionView,
  setInitialCommentState,
  clientInteractionType,
  isCommentsModal = false
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isHiddenComment = currentComment?.hidden || isParentHidden;
  const currentUserId = useSelector(state => getCurrentUser(state)?.id);
  const currentUser = useSelector(state => getCurrentUser(state), isEqual);
  const isCommentCreator = currentUserId === currentComment?.authorId;

  const handleEditClick = () => {
    dispatch(setEditingCommentId(currentComment?.id));
    setIsEditing(!isEditing);
    setInitialCommentState(commentState);
  };

  const toogleReplyEditor = () => {
    setEditReply(!isEditReply);
    dispatch(selectComment(currentComment?.id));
  };

  const confirmDelete = (id, commentType) => {
    onDelete({ id, commentType });
  };

  const renderDeleteBtn = () => {
    // render delete btn
    if (isCommentCreator) {
      return (
        <Popconfirm
          cancelButtonProps={{ fontSize: '14px', size: 'middle' }}
          okButtonProps={{ fontSize: '14px', size: 'middle' }}
          title={t('components.commentList.comment.commentEditButton.confirmDelete.title')}
          okText={t('components.commentList.comment.commentEditButton.confirmDelete.ok')}
          cancelText={t('components.commentList.comment.commentEditButton.confirmDelete.cancel')}
          placement="leftTop"
          onConfirm={() => confirmDelete(currentComment?.id, currentComment?.commentType)}
        >
          <StyledButton
            size="icon"
            icon={<Icon icon={Trash} size="20px" />}
            color="var(--black_6)"
            _hover="color: var(--red_primary)"
          />
        </Popconfirm>
      );
    }
  };

  const renderCommentButtons = () => {
    if (!allowActions) return;
    if (!isCommentsModal) return;
    if (clientInteractionType === COMMUNICATION_TYPES.CUSTOM) return;

    const canReplyComments = get(currentUser, 'role.permissions', []).includes(
      PERMISSIONS.CAN_REPLY_COMMENTS
    );

    // render edit btn
    if (isCommentCreator) {
      return (
        <SCol alignItems="top" justifyContent="end" flex="none">
          {renderDeleteBtn()}
          <StyledButton
            size="icon"
            icon={<Icon icon={Edit3} size="20px" />}
            color="var(--black_6)"
            backgroundColor="transparent"
            onClick={() => handleEditClick()}
          />
        </SCol>
      );
    }
    // render reply comment btn
    return (
      canReplyComments && (
        <SCol alignItems="top" justifyContent="end" flex="none">
          {renderDeleteBtn()}
          <StyledButton
            onClick={toogleReplyEditor}
            size="icon"
            icon={<Icon icon={CornerUpLeft} size="20px" />}
            color="var(--black_6)"
          />
        </SCol>
      )
    );
  };

  if (isEditing && isCommentCreator) return null;

  return (
    <SRow type="flex" justify="space-between" align="middle" wrap={false}>
      <SCol flex="auto">
        <SRow type="flex" display="flex" wrap={false}>
          <SCol marginRight="6px">
            <UserPreview
              userId={currentComment?.authorId}
              disabled
              showAvatar
              hidden={currentComment?.authorId !== currentUserId}
              size="extraSmall"
            />
          </SCol>
          {isHiddenComment && (
            <SCol flex="none" display="flex" justify="center" alignItems="center">
              <Tooltip title={t('components.commentList.comment.commentInfo.hiddenCommentTooltip')}>
                <Icon color="var(--black_6)" icon={EyeInvisibleOutlined} />
              </Tooltip>
            </SCol>
          )}
          <SText
            ellipsis
            color="var(--black_6)"
            marginLeft="6px"
            title={moment(currentComment?.updatedAt).format('DD/MM/YYYY, HH:mm')}
          >
            {moment(currentComment?.updatedAt).format('DD/MM/YYYY, HH:mm')}
          </SText>

          <SCol flex="none" marginRight={isQuestionView ? '47px' : ''}>
            <TimestampButton timestamp={commentTimeStamp || 0} commentType={commentType} />
          </SCol>
        </SRow>
      </SCol>

      {renderCommentButtons()}
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

export default CommentInfo;
