import React, { useEffect, useState } from 'react';
import { Modal, Tooltip, Typography } from 'antd';
import moment from 'moment/moment';
import Text from 'antd/lib/typography/Text';
import { useDispatch, useSelector } from 'react-redux';
import { actions, operations } from 'redux/lists/userFieldsAnalyticsCommunicationsList';
import ClientInteractionLink from '../../../../components/ClientInteractionLink';
import STable from '../../../../components/Standard/STable';
import UserPreview from '../../../../components/UserPreview';

const { Link } = Typography;

const loadUserFieldsAnalyticsCommunications = operations.load;

const ClientInteractionsLinks = ({ communicationsCount, fieldId, widgetData, operatorId }) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [visible, setVisible] = useState(false);
  const { loading, byIds: communications } = useSelector(
    state => state.userFieldsAnalyticsCommunicationsResource
  );
  const { page, ids, totalCount } = useSelector(
    state => state.userFieldsAnalyticsCommunicationsList
  );
  const filteredCommunications = Object.values(communications).filter(item =>
    ids.includes(item.id)
  );

  useEffect(() => {
    if (visible) {
      dispatch(
        loadUserFieldsAnalyticsCommunications({
          cs: fieldId,
          widget_id: widgetData?.id,
          widget_type: widgetData?.type,
          organization_id: organizationId,
          page: page.number,
          count: page.size,
          operator_id: operatorId
        })
      );
    }
  }, [visible, page.number, page.size]);

  const columns = [
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      width: 50,
      render: (text, record) => (
        <ClientInteractionLink id={text} communication={record} isNewPage useDrawer={false} />
      )
    },
    {
      title: 'ФИО',
      dataIndex: 'operatorId',
      key: 'operatorId',
      width: 250,
      render: text => <UserPreview disabled userId={text} showAvatar truncateSize={25} />
    },
    {
      title: 'Дата и время',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: text => moment(text).format('DD/MM/YYYY HH:mm')
    },
    {
      title: 'Номер телефона',
      dataIndex: 'clientPhoneNumber',
      key: 'clientPhoneNumber',
      width: 150,
      render: text => <Text>{text}</Text>
    },
    {
      title: 'Направление',
      dataIndex: 'direction',
      key: 'direction',
      width: 120,
      render: text => {
        const dataDirection = text === 'incoming' ? 'Входящий' : 'Исходящий';
        return <Tooltip title={dataDirection}>{dataDirection}</Tooltip>;
      }
    },
    {
      title: 'Прод-ть',
      dataIndex: 'duration',
      key: 'duration',
      width: 80,
      render: text => <Text>{text}</Text>
    }
  ];

  const handleTableChange = pagination => {
    const { pageSize, current } = pagination;
    dispatch(
      actions.updatePage({
        size: pageSize.toString(),
        number: current.toString()
      })
    );
  };

  const handleCloseModal = () => {
    dispatch(
      actions.updatePage({
        size: 10,
        number: 1
      })
    );
    setVisible(false);
  };

  return (
    <>
      <Tooltip style={{ cursor: 'pointer' }}>
        <Link
          href="#"
          onClick={e => {
            e.preventDefault();
            setVisible(true);
          }}
        >
          {communicationsCount}
        </Link>
      </Tooltip>
      {visible && (
        <Modal
          title="Список коммуникаций"
          centered
          visible
          footer={null}
          width={900}
          onCancel={() => handleCloseModal()}
        >
          <STable
            rowKey="id"
            size="small"
            loading={loading}
            dataSource={filteredCommunications}
            columns={columns}
            border
            scroll={{ y: window.innerWidth > 1320 ? 'calc(100vh - 286px)' : 'calc(100vh - 356px)' }}
            pagination={{
              pageSize: parseInt(page.size),
              current: parseInt(page.number),
              total: totalCount,
              showSizeChanger: true,
              pageSizeOptions: ['10', '25', '50']
            }}
            onChange={handleTableChange}
          />
        </Modal>
      )}
    </>
  );
};

export default ClientInteractionsLinks;
