import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, Col, Collapse } from 'antd';
import { ChevronsDown, ChevronsUp } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import { SButton, SCard, SRow } from '../Standard';
import CustomFieldsModal from '../CommunicationInfoModal/CustomFieldsModal';
import CustomFieldsView from '../CommunicationInfoModal/CustomFieldsView';

const { Panel } = Collapse;

const CollapseButton = styled(Button)`
  &.ant-btn {
    text-transform: capitalize;
    bottom: -20px;
    z-index: 2;
    position: absolute;
    min-width: max-content;
    height: 20px;
    color: #82858c;
    display: flex;
    font-weight: bold;
    font-size: 12px;
    padding: 0 5px 0 4px;
    flex-direction: row-reverse;
    right: 50%;
    transform: translate(50%, -50%);
    & > span {
      margin-top: auto;
      padding: 0px 3px 0;
    }
    &.ant-btn:hover,
    :active {
      color: #839df2 !important;
    }
    &.ant-btn:focus {
      color: rgb(130, 133, 140);
      border-color: rgb(232, 232, 232);
    }

    & > svg {
      margin: auto;
    }
  }
`;

const StyledCollapse = styled(Collapse)`
  && {
    background-color: inherit;
    & > .ant-collapse-item {
      border: 0;
      & > .ant-collapse-header {
        display: none !important;
      }
      &.ant-collapse-item-active {
        padding: 0px;
      }
      & > .ant-collapse-content {
        & > .ant-collapse-content-box {
          padding: 24px;
        }
      }
    }
  }
`;

const customFields = {
  pole_string: {
    id: '1',
    type: 'custom-fields',
    name: 'type string',
    key: 'pole_string',
    fieldType: 'string',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_number: {
    id: '2',
    type: 'custom-fields',
    name: 'type number',
    key: 'pole_number',
    fieldType: 'number',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_number_array: {
    id: '3',
    type: 'custom-fields',
    name: 'type number_array',
    key: 'pole_number_array',
    fieldType: 'number_array',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_string_array: {
    id: '4',
    type: 'custom-fields',
    name: 'type string_array without options',
    key: 'pole_string_array',
    fieldType: 'string_array',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_string_array_2: {
    id: '5',
    type: 'custom-fields',
    name: 'type string_array with options',
    key: 'pole_string_array_2',
    fieldType: 'string_array',
    options: ['Option 1', 'Option 2', 'Option 3', 'Option 4'],
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_string_url: {
    id: '6',
    type: 'custom-fields',
    name: 'type string_url',
    key: 'pole_string_url',
    fieldType: 'string_url',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_datetime: {
    id: '7',
    type: 'custom-fields',
    name: 'type datetime',
    key: 'pole_datetime',
    fieldType: 'datetime',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_boolean: {
    id: '8',
    type: 'custom-fields',
    name: 'type boolean',
    key: 'pole_boolean',
    fieldType: 'boolean',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_duration: {
    id: '9',
    type: 'custom-fields',
    name: 'type duration',
    key: 'pole_duration',
    fieldType: 'duration',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_without_type: {
    id: '10',
    type: 'custom-fields',
    name: 'no type with settings',
    key: 'pole_without_type',
    fieldType: null,
    settings: [
      { key: 'option1', text: 'Option 1', parentKey: 'parentValue1' },
      { key: 'option2', text: 'Option 2', parentKey: 'parentValue2' }
    ],
    dependsOnId: 'pole_string_array_2',
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  },
  pole_select_with_settings: {
    id: '11',
    type: 'custom-fields',
    name: 'type select with settings',
    key: 'pole_select_with_settings',
    fieldType: 'select',
    settings: [
      {
        key: '960170',
        text: 'Ожидает внедрения'
      },
      {
        key: '960172',
        text: 'Идет внедрение'
      },
      {
        key: '960174',
        text: 'Внедрение на паузе'
      },
      {
        key: '960176',
        text: 'Завершено внедрение'
      },
      {
        key: '960250',
        text: 'Без глубины внедрения'
      },
      {
        key: '960400',
        text: 'Без внедрения'
      },
      {
        key: '967000',
        text: 'Внедрение не пройдено'
      }
    ],
    usedForFilters: true,
    usedForReviews: true,
    usedForAi: true
  }
};

const callCustomFields = {
  pole_string: 'Text field 123',
  pole_number: '1234567',
  pole_number_array: '123456789',
  pole_string_array: '657834543',
  pole_string_array_2: "'Option 1', 'Option 2'",
  pole_string_url: 'https:/5435',
  pole_datetime: '2025-03-18 14:48:00',
  pole_boolean: 'true',
  pole_duration: '183',
  pole_select_with_settings: [
    ['960170'],
    ['960172'],
    ['960174'],
    ['960176'],
    ['960250'],
    ['960400'],
    ['967000']
  ]
};

const AllCustomFieldsView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [open, setOpen] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isActiveModeForSave, setIsActiveModeForSave] = useState(false);

  const customFieldsByKeys = customFields;
  const { loading } = useSelector(state => state.customFieldsResource);

  const communicationChainCustomFields = callCustomFields;
  console.log('callCustomFields', callCustomFields);

  const [fieldValues, setFieldValues] = useState(communicationChainCustomFields);

  const handleCollapse = e => {
    e.preventDefault();
    if (open.length === 0) setOpen(['1']);
    else setOpen([]);
  };

  return (
    <>
      {!isEmpty(communicationChainCustomFields) && (
        <Col span={24} style={{ padding: '0' }}>
          <SCard
            bordered="1px solid #e4e6ed"
            borderTop="none"
            headPadding="8px 24px"
            bodyPadding="0px"
            loading={loading}
            marginBottom="15px"
            rounded="8px"
            position="relative"
            zIndex="1"
          >
            <StyledCollapse bordered={false} activeKey={open} accordion={false}>
              <Panel key="1" showArrow={false} collapsible="disabled" header="customFields2">
                {isEditMode ? (
                  <CustomFieldsModal
                    customFields={communicationChainCustomFields}
                    customFieldsByKeys={customFieldsByKeys}
                    isEditMode={isActiveModeForSave}
                    setIsEditMode={setIsActiveModeForSave}
                    fieldValues={fieldValues}
                    setFieldValues={setFieldValues}
                  />
                ) : (
                  <CustomFieldsView
                    customFieldsByKeys={customFieldsByKeys}
                    communicationChainCustomFields={communicationChainCustomFields}
                  />
                )}
                <SRow justifyContent="flex-end">
                  {!isEditMode ? (
                    <SButton onClick={() => setIsEditMode(true)} showLoad type="primary">
                      {t('general.edit')}
                    </SButton>
                  ) : isActiveModeForSave ? (
                    <SButton onClick={() => console.log()} showLoad type="primary">
                      {t('general.save')}
                    </SButton>
                  ) : (
                    <SButton onClick={() => setIsEditMode(false)} showLoad type="primary">
                      {t('general.cancel')}
                    </SButton>
                  )}
                </SRow>
              </Panel>
            </StyledCollapse>
            <CollapseButton
              shape="round"
              icon={
                open.length === 0 ? (
                  <Icon icon={ChevronsDown} size={14} style={{ strokeWidth: '3' }} />
                ) : (
                  <Icon icon={ChevronsUp} size={14} style={{ strokeWidth: '3' }} />
                )
              }
              onClick={handleCollapse}
            >
              Все типы кастомных полей
            </CollapseButton>
          </SCard>
        </Col>
      )}
    </>
  );
};

export default AllCustomFieldsView;
