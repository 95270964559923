import { apiUrl } from 'core/config';
import { handleError } from 'core/services/errors';
import { buildApi, invalidateRequests } from 'redux-bees';
import moment from 'moment';
import amoEndpoints from './endpoints/amocrm';
import analyticsEndpoints from './endpoints/analytics';
import assignmentsEndpoints from './endpoints/assignments';
import authPasswordEndpoints from './endpoints/authPassword';
import backgroundJobsEndpoints from './endpoints/backgroundJobs';
import bitrixEndpoints from './endpoints/bitrix';
import calibrationSessionsEndpoints from './endpoints/calibrationSessions';
import phoneCallsEndpoints from './endpoints/calls';
import checklistDefinitionsEndpoints from './endpoints/checklistDefinitions';
import checklistsEndpoints from './endpoints/checklists';
import mangoEndpoints from './endpoints/mango';
import clientInteractionsEndpoints from './endpoints/clientInteractions';
import clientsEndpoints from './endpoints/clients';
import commentsEndpoints from './endpoints/comments';
import communicationTablesEndpoints from './endpoints/communicationTables';
import customCommunicationsEndpoints from './endpoints/customCommunication';
import customFieldsEndpoints from './endpoints/customFields';
import dashboardAnalyticsEndpoints from './endpoints/dashboardAnalytics';
import dashboardRowsEndpoints from './endpoints/dashboardRows';
import dashboardEndpoints from './endpoints/dashboards';
import dashboardWidgetsEndpoints from './endpoints/dashboardWidgets';
import exportsEndpoints from './endpoints/exports';
import feedbackEndpoints from './endpoints/feedback';
import foldersEndpoints from './endpoints/folders';
import integrationsEndpoints from './endpoints/integrations';
import folderItemsEndpoints from './endpoints/items';
import levelsEndpoints from './endpoints/levels';
import notificationSettingsEndpoints from './endpoints/notificationSettings';
import organizationEndpoints from './endpoints/organization';
import paymentEndpoints from './endpoints/payment';
import phoneCallChainsEndpoints from './endpoints/phoneCallChains';
import questionGroupsEndpoints from './endpoints/questionGroups';
import questionsEndpoints from './endpoints/questions';
import questionToGroupBindingsEndpoints from './endpoints/questionToGroupBindings';
import reviewLibrariesEndpoints from './endpoints/reviewLibraries';
import reviewLibraryItemsEndpoints from './endpoints/reviewLibraryItems';
import reviewsEndpoints from './endpoints/reviews';
import rolesEndpoints from './endpoints/roles';
import salePipelinesEndpoints from './endpoints/salePipelines';
import searchEndpoints from './endpoints/search';
import sipuniEndpoints from './endpoints/sipuni';
import statusesEndpoints from './endpoints/statuses';
import taskDefinitionsEndpoints from './endpoints/taskDefinitions';
import taskPriorityEndpoints from './endpoints/taskPriority';
import tasksEndpoints from './endpoints/tasks';
import textCommunicationsEndpoints from './endpoints/textCommunications';
import unitsEndpoints from './endpoints/units';
import uploadedFilesEndpoints from './endpoints/uploadedFiles';
import userAccountBindingsEndpoints from './endpoints/userAccountBindings';
import usersEndpoints from './endpoints/users';
import workPlanTaskConfigurationsEndpoints from './endpoints/workPlanTaskConfigurations';
import workPlanTaskAssignmentsEndpoints from './endpoints/workPlanTaskAssignments';
import commentTemplatesEndpoints from './endpoints/commentTemplates';
import appellationsEndpoints from './endpoints/appellations';
import appellationsSettingsEndpoints from './endpoints/appellationsSettings';
import { resourceApiRequestsBuilder } from './resourceApiRequestsBuilder';
import appellationCommentsEndpoints from './endpoints/appellationComments';
import usedeskEndpoints from './endpoints/usedesk';
import binotelEndpoints from './endpoints/binotel';
import zendeskEndpoints from './endpoints/zendesk';
import helpDeskEddyEndpoints from './endpoints/helpDeskEddy';
import infinityEndpoints from './endpoints/infinity';
import brightPatternEndpoints from './endpoints/brightPattern';
import appellationDashboardPageEndpoints from './endpoints/dashboardPages/appellationDashboardPage';
import autofailDashboardPageEndpoints from './endpoints/dashboardPages/autofailDashboardPage';
import unitDashboardPageEndpoints from './endpoints/dashboardPages/unitDashboardPage';
import unitByTypeDashboardPageEndpoints from './endpoints/dashboardPages/unitsByTypeDashboardPage';
import entitiesEndpoints from './endpoints/entities';
import nestedReviewEndpoints from './endpoints/nestedReview';
import helpDeskEddyEndpointsV3 from './endpoints/helpDeskEddyV3';
import userCustomFieldsEndpoints from './endpoints/userCustomFields';
import datasetsEndpoints from './endpoints/dataset';
import aiSettingsEndpoints from './endpoints/aiSettings';
import exportsV3Endpoints from './endpoints/exportV3';
import manuallyStartTranscriptionEndpoints from './endpoints/manuallyStartTranscription';
import promptsEndpoints from './endpoints/prompts';
import uisEndpoints from './endpoints/uis';
import naumenEndpoints from './endpoints/naumen';
import datasetsFileListEndpoints from './endpoints/datasetFileList';
import datasetsBindingEndpoints from './endpoints/datasetBinding';
import ednaEndpoints from './endpoints/edna';
import phoneCallsEndpointsV3 from './endpoints/callsV3';
import workPlanTaskConfigurationsV3 from './endpoints/workPlanTaskConfigurationsV3';
import s2Endpoints from './endpoints/s2';
import pyrusEndpoints from './endpoints/pyrus';
import communicationReviewsEndpoint from './endpoints/communicationReviews';
import aiTaskAnalyticsErrorsEndpoints from './endpoints/aiTaskAnalyticsErrorEndpoints';
import communicationChainsEndpoints from './endpoints/communicationChains';
import communicationChainsV3Endpoints from './endpoints/communicationChainsEndpoint';
import userFieldsAnalyticsCommunicationsEndpoint from './endpoints/userFieldsAnalyticsCommunications';

export const API_V1_PATH = '/api/v1';
export const API_V2_PATH = '/api/v2';
export const API_V3_PATH = '/api/v3';
export const API_V4_PATH = '/api/v4';

const apiEndpoints = {
  ...commentsEndpoints(API_V1_PATH),
  ...unitsEndpoints(API_V1_PATH),
  ...reviewsEndpoints(API_V1_PATH),
  ...organizationEndpoints(API_V1_PATH),
  ...checklistsEndpoints(API_V1_PATH),
  ...checklistDefinitionsEndpoints(API_V1_PATH),
  ...phoneCallsEndpoints(API_V1_PATH),
  ...phoneCallsEndpointsV3(API_V3_PATH),
  ...assignmentsEndpoints(API_V1_PATH),
  ...usersEndpoints(API_V1_PATH),
  ...analyticsEndpoints(API_V1_PATH),
  ...integrationsEndpoints(API_V1_PATH),
  ...tasksEndpoints(API_V1_PATH),
  ...taskDefinitionsEndpoints(API_V1_PATH),
  ...taskPriorityEndpoints(API_V1_PATH),
  ...dashboardEndpoints(API_V1_PATH),
  ...dashboardRowsEndpoints(API_V1_PATH),
  ...dashboardWidgetsEndpoints(API_V1_PATH),
  ...dashboardAnalyticsEndpoints(API_V1_PATH),
  ...clientsEndpoints(API_V1_PATH),
  ...clientInteractionsEndpoints(API_V1_PATH),
  ...bitrixEndpoints(API_V1_PATH),
  ...mangoEndpoints(API_V1_PATH),
  ...backgroundJobsEndpoints(API_V1_PATH),
  ...textCommunicationsEndpoints(API_V1_PATH),
  ...paymentEndpoints(API_V1_PATH),
  ...communicationTablesEndpoints(API_V1_PATH),
  ...paymentEndpoints(API_V1_PATH),
  ...feedbackEndpoints(API_V1_PATH),
  ...searchEndpoints(API_V1_PATH),
  ...customCommunicationsEndpoints(API_V1_PATH),
  ...rolesEndpoints(API_V1_PATH),
  ...notificationSettingsEndpoints(API_V1_PATH),
  ...amoEndpoints(API_V1_PATH),
  ...reviewLibrariesEndpoints(API_V1_PATH),
  ...reviewLibraryItemsEndpoints(API_V1_PATH),
  ...reviewLibraryItemsEndpoints(API_V1_PATH),
  ...questionToGroupBindingsEndpoints(API_V1_PATH),
  ...questionsEndpoints(API_V1_PATH),
  ...questionGroupsEndpoints(API_V1_PATH),
  ...exportsEndpoints(API_V1_PATH),
  ...levelsEndpoints(API_V1_PATH),
  ...authPasswordEndpoints(API_V1_PATH),
  ...foldersEndpoints(API_V1_PATH),
  ...folderItemsEndpoints(API_V1_PATH),
  ...calibrationSessionsEndpoints(API_V1_PATH),
  ...statusesEndpoints(API_V1_PATH),
  ...customFieldsEndpoints(API_V1_PATH),
  ...salePipelinesEndpoints(API_V1_PATH),
  ...phoneCallChainsEndpoints(API_V1_PATH),
  ...userAccountBindingsEndpoints(API_V1_PATH),
  ...workPlanTaskConfigurationsEndpoints(API_V1_PATH),
  ...workPlanTaskConfigurationsV3(API_V3_PATH),
  ...workPlanTaskAssignmentsEndpoints(API_V1_PATH),
  ...sipuniEndpoints(API_V1_PATH),
  ...uploadedFilesEndpoints(API_V1_PATH),
  ...commentTemplatesEndpoints(API_V1_PATH),
  ...appellationsEndpoints(API_V1_PATH),
  ...appellationsSettingsEndpoints(API_V1_PATH),
  ...appellationCommentsEndpoints(API_V1_PATH),
  ...usedeskEndpoints(API_V1_PATH),
  ...binotelEndpoints(API_V1_PATH),
  ...zendeskEndpoints(API_V1_PATH),
  ...helpDeskEddyEndpoints(API_V1_PATH),
  ...infinityEndpoints(API_V1_PATH),
  ...brightPatternEndpoints(API_V1_PATH),
  ...appellationDashboardPageEndpoints(API_V1_PATH),
  ...autofailDashboardPageEndpoints(API_V1_PATH),
  ...unitDashboardPageEndpoints(API_V1_PATH),
  ...unitByTypeDashboardPageEndpoints(API_V1_PATH),
  ...entitiesEndpoints(API_V1_PATH),
  ...helpDeskEddyEndpointsV3(API_V3_PATH),
  ...userCustomFieldsEndpoints(API_V3_PATH),
  ...datasetsEndpoints(API_V3_PATH),
  ...aiSettingsEndpoints(API_V3_PATH),
  ...exportsV3Endpoints(API_V3_PATH),
  ...manuallyStartTranscriptionEndpoints(API_V3_PATH),
  ...nestedReviewEndpoints(API_V2_PATH),
  ...promptsEndpoints(API_V3_PATH),
  ...uisEndpoints(API_V1_PATH),
  ...naumenEndpoints(API_V3_PATH),
  ...datasetsFileListEndpoints(API_V3_PATH),
  ...datasetsBindingEndpoints(API_V3_PATH),
  ...ednaEndpoints(API_V3_PATH),
  ...s2Endpoints(API_V4_PATH),
  ...pyrusEndpoints(API_V4_PATH),
  ...communicationReviewsEndpoint(API_V3_PATH),
  ...aiTaskAnalyticsErrorsEndpoints(API_V3_PATH),
  ...communicationChainsEndpoints(API_V1_PATH),
  ...communicationChainsV3Endpoints(API_V3_PATH),
  ...userFieldsAnalyticsCommunicationsEndpoint(API_V3_PATH)
};

// registration
export const registrationWithDemoData = `${apiUrl}${API_V1_PATH}/organization/register_with_data`;
export const registrationWithoutDemoData = `${apiUrl}${API_V1_PATH}/organization/register_without_data`;

export const clearEndpointCache = endpointName => invalidateRequests(apiEndpoints[endpointName]);

export const callUploadEndpoint = `${apiUrl}${API_V1_PATH}/calls/upload`;
export const exportToExcelTableCommunicationEndpoint = `${apiUrl}${API_V3_PATH}/export`;
export const handlerEndpoint = `${apiUrl}${API_V3_PATH}/handler.php`;
export const exportToExcelEndpoint = `https://excel-exporter.qolio.ru`;
export const deleteEmployeeRequest = `${apiUrl}${API_V3_PATH}/users`;
export const amocrmCustomFieldsEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_custom_fields`;
export const amocrmGetUsersStatusEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_get_users_status`;
export const amocrmStatusEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_status`;
export const amocrmSynchronizationEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_synchronization`;
export const naumenSynchronizationEndpoint = `${apiUrl}${API_V3_PATH}/naumen_synchronization`;
export const ednaSynchronizationEndpoint = `${apiUrl}${API_V3_PATH}/edna_synchronization`;
export const s2SynchronizationEndpoint = `${apiUrl}${API_V4_PATH}/`;
export const amocrmBulkActionFieldsEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_bulk_action_fields`;
export const amocrmConnectCustomFieldEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_connect_custom_field`;
export const amocrmUnConnectCustomFieldEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_un_connect_custom_field`;
export const amocrmEnableEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_enable`;
export const amocrmDisableEndpoint = `${apiUrl}${API_V3_PATH}/amocrm_disable`;
export const naumenEnableEndpoint = `${apiUrl}${API_V3_PATH}/naumen_enable`;
export const naumenDisableEndpoint = `${apiUrl}${API_V3_PATH}/naumen_disable`;
export const ednaEnableEndpoint = `${apiUrl}${API_V3_PATH}/edna_enable`;
export const ednaDisableEndpoint = `${apiUrl}${API_V3_PATH}/edna_disable`;
export const integrationDeleteEndpoint = `${apiUrl}${API_V3_PATH}/integration_delete`;
export const importFromFileEndpoint = `${apiUrl}${API_V3_PATH}/import_from_file`;
export const getImportExampleFileEndpoint = `${apiUrl}${API_V3_PATH}/get_import_example_file`;
export const createDatasetEndpoint = `${apiUrl}${API_V3_PATH}/ai_dataset/add`;
export const updateDatasetEndpoint = `${apiUrl}${API_V3_PATH}/ai_dataset/update`;
export const deleteDatasetEndpoint = `${apiUrl}${API_V3_PATH}/ai_dataset/delete`;
export const libraryCountEndpoint = `${apiUrl}${API_V3_PATH}/libraries/total`;
export const mangoInstallEndpoint = `${apiUrl}${API_V3_PATH}/mango_install`;
export const mangoGetUsersStatusEndpoint = `${apiUrl}${API_V3_PATH}/mango_get_users_status`;
export const mangoStatusEndpoint = `${apiUrl}${API_V3_PATH}/mango_status`;
export const mangoSynchronizationEndpoint = `${apiUrl}${API_V3_PATH}/mango_synchronization`;
export const mangoEnableEndpoint = `${apiUrl}${API_V3_PATH}/mango_enable`;
export const mangoDisableEndpoint = `${apiUrl}${API_V3_PATH}/mango_disable`;
export const toggleInternalEndpoint = `${apiUrl}${API_V3_PATH}/mango_internal`;
export const getDelayedEndpoint = `${apiUrl}${API_V3_PATH}/ai_dataset/delayed`;
export const getDatasetFileListEndpoint = `${apiUrl}${API_V3_PATH}/ai_dataset/files`;
export const getLogsAiTaskEndpoint = `${apiUrl}${API_V3_PATH}/ai_tasks/get_logs`;
export const downloadCallFromMediaUrl = `${apiUrl}${API_V3_PATH}/get_media`;
export const workTimeUserTrackingInit = `${apiUrl}${API_V3_PATH}/worktime/init`;
export const workTimeUserTracking = `${apiUrl}${API_V3_PATH}/worktime`;
export const callUploadEndpointV3 = `${apiUrl}${API_V3_PATH}/upload/upload`;
export const exportToExcelDashboardsEndpoint = `${apiUrl}${API_V3_PATH}/export/widgets/`;
export const getApiKeyEndpoint = `https://smmrz.ru/api/v1/get_api_key`;
export const getThreadEndpoint = 'https://smmrz.ru/api/threads/read';
export const createThreadEndpoint = 'https://smmrz.ru/api/threads/create';
export const updateThreadEndpoint = 'https://smmrz.ru/api/threads/update';
export const clearThreadEndpoint = 'https://smmrz.ru/api/threads/clear';
export const deleteThreadEndpoint = 'https://smmrz.ru/api/threads/delete';
// communication chains endpoints
export const addCommunicationChainsEndpoint = `${apiUrl}${API_V1_PATH}/communication_chains`;
export const updateCommunicationChainsEndpoint = `${apiUrl}${API_V1_PATH}/communication_chains/add_communication`;
export const deleteCommunicationFromChainEndpoint = `${apiUrl}${API_V1_PATH}/communication_chains/delete_communication`;
export const getCommunicationChains = `${apiUrl}${API_V2_PATH}/communications_tables/`;
export const createMassCommunicationChainsEndpoint = `${apiUrl}${API_V3_PATH}/bulk_action`;
export const checkMassCommunicationChainsEndpoint = `${apiUrl}${API_V3_PATH}/bulk_action/check`;

const timezone = moment().format('Z');

// TODO: replace headers object to authHeaders util
const config = {
  baseUrl: `${apiUrl}`,
  configureHeaders(headers) {
    return {
      ...headers,
      'Content-Type': 'application/json',
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
      TIMEZONE: timezone,
      'x-organization-id': localStorage.getItem('organizationId')
    };
  },
  afterReject({ status, headers, body, ...props }) {
    console.log({ status, headers, body, ...props });

    handleError(status, body);
    return Promise.reject({ status, headers, body });
  }
};

const api = buildApi(apiEndpoints, config);

export default api;

export const buildResourceApiRequest = resourceApiRequestsBuilder({ api });
