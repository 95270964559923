import { message, Skeleton } from 'antd';
import AddToCalibrationModal from 'components/AddToCalibrationModal';
import ChecklistManager from 'components/Checklist/ChecklistManager';
import ChecklistManagerHead from 'components/Checklist/ChecklistManager/ChecklistManagerHead';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { CHECKLIST_MANAGER_STATES, PERMISSIONS } from 'core/utils/constants';
import { every, get, isEmpty, isEqual, isNil, pick } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import useEvent from '@react-hook/event';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { commentsResource } from 'redux/resources/comments';
import { getCurrentUser } from 'redux/selectors/users';
import {
  setChecklistManagerState,
  setInitialState as setInitialChecklistManagerState
} from 'redux/ui/checklistManager/reducer';
import { setCommunication, visitInteraction } from 'redux/ui/clientInteractionDrawer/reducer';
import { submitReview } from 'redux/ui/clientInteractionPage/operations';
import {
  addComment,
  deleteComment,
  setAddingComment,
  setInitialState,
  updateComment
} from 'redux/ui/clientInteractionPage/reducer';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import ChecklistManagerFooter from 'components/Checklist/ChecklistManager/ChecklistManagerFooter';
import { getChecklistDefinitionQuestions } from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { updatePlayerState } from 'redux/ui/recordPlayer/reducer';
import {
  deleteAnswersByFormIdIndexDB,
  deleteCommentByIdIndexDB,
  deleteCommentsByFormIdIndexDB,
  updateCommentTextByIdIndexDB
} from 'core/indexDB/indexDB';
import CommunicationEntityPage from './CommunicationEntityPage';

const defaultInteraction = {};

const ClientInteractionChainPage = ({
  Content,
  clientInteraction = defaultInteraction,
  id: propsId,
  loading,
  fromDrawer = false,
  reviewId
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: paramsId } = useParams();
  const id = propsId || paramsId;

  useEffect(() => {
    if (window.location.pathname !== '/client-interactions') {
      dispatch(
        checklistDefinitionsResource.operations.loadWithInclude({
          pagination: false
        })
      );
    }
  }, []);

  const ref = React.useRef();
  const refBody = React.useRef();
  const [scrollbarWidth, setScrollbarWidth] = useState(null);

  const review = useSelector(state => state.reviewsResource.byIds[reviewId]);

  const allowCommenting = useSelector(state => {
    const user = getCurrentUser(state);
    const isPermissionInclude =
      get(user, 'role.permissions', []).includes(PERMISSIONS.CAN_MAKE_REVIEW) ||
      get(user, 'role.permissions', []).includes(PERMISSIONS.CAN_REPLY_COMMENTS);

    return isPermissionInclude && review ? review?.reviewerId === user.id : isPermissionInclude;
  });

  const comments = useSelector(state => state.uiClientInteractionPage.commentsByIds, isEqual);

  const isEmptyQuestionsValues = useSelector(state =>
    every(state.uiChecklistManager.questionIdToAnswerValue, isNil)
  );

  const isEmptyQuestionsComments = isEmpty(
    Object.values(comments).filter(comment => get(comment, 'metadata.questionId', undefined))
  );

  const currentChecklist = useSelector(state => state.uiChecklistManager.currentChecklist);

  const addingCommentsToQuestionId = useSelector(
    state => state.uiChecklistManager.addingCommentsToQuestionId
  );

  const checklistManagerState = useSelector(
    state => state.uiChecklistManager.checklistManagerState
  );

  const addingComment = useSelector(state => state.uiClientInteractionPage.isAddingComment);

  useEffect(() => {
    dispatch(visitInteraction(id));
    return () => {
      dispatch(setInitialState());
      dispatch(setInitialChecklistManagerState());
    };
  }, []);

  useEffect(() => {
    const resizeOb = new ResizeObserver(entries => {
      // since we are observing only a single element, so we access the first element in entries array
      const el = entries?.[0]?.target;
      if (el && scrollbarWidth === null) {
        // current width & height
        const { offsetWidth, clientWidth } = el;
        const newScrollbarWidth = (offsetWidth || 0) - (clientWidth || 0);
        setScrollbarWidth(newScrollbarWidth);
      }
    });

    ref.current && resizeOb.observe(ref.current);

    return () => {
      ref.current && resizeOb.unobserve(ref.current);
    };
  }, [clientInteraction]);

  useEffect(() => {
    const resizeOb = new ResizeObserver(entries => {
      // since we are observing only a single element, so we access the first element in entries array //
      const el = entries?.[0]?.target;
      if (el && scrollbarWidth === null) {
        // current width & height
        const { offsetWidth, clientWidth } = el;
        const newScrollbarWidth = (offsetWidth || 0) - (clientWidth || 0);
        setScrollbarWidth(newScrollbarWidth);
      }
    });

    refBody.current && resizeOb.observe(refBody.current);

    return () => {
      refBody.current && resizeOb.unobserve(refBody.current);
    };
  }, [clientInteraction]);

  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );
  const checklistDefinitionId = currentChecklist?.checklistDefinitionId;
  const { questionIdToAnswerValue } = useSelector(state => state.uiChecklistManager, isEqual);

  const { playedSeconds } = useSelector(
    state => pick(state.uiRecordPlayer, ['playedSeconds']),
    isEqual
  );

  let questions = [];

  const checklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistQuestions = useSelector(
    state => getChecklistDefinitionQuestions(state, checklistDefinition),
    isEqual
  );

  if (!isEmpty(currentChecklist) && !isEmpty(checklistDefinition)) {
    questions = checklistDefinition ? checklistQuestions : [];
  }

  const questionsWithValuesAndBindings = questions.map(question => ({
    ...question,
    value: questionIdToAnswerValue[question.id],
    percentage: get(question, 'binding.percentage', 1)
  }));

  const goToReview = useCallback(
    async reviewId => {
      await dispatch(updatePlayerState({ wasPlayed: false, isPlaying: false }));
      fromDrawer
        ? dispatch(setCommunication({ type: 'review', id: reviewId }))
        : history.push(`/reviews/${reviewId}?t=${playedSeconds}`);
    },
    [dispatch]
  );

  const onChecklistSave = useCallback(async () => {
    if (isEmptyQuestionsValues) {
      message.warning(t('clientInteractionPage.messages.emptyQuestionsComments'));
      return;
    }

    if (isEmptyQuestionsValues && !isEmptyQuestionsComments) {
      message.warning(t('clientInteractionPage.messages.emptyQuestionsValues'));
      return;
    }

    if (currentChecklist?.checklistDefinitionId) {
      await deleteAnswersByFormIdIndexDB(id, currentChecklist?.checklistDefinitionId);
      await deleteCommentsByFormIdIndexDB(id, currentChecklist?.checklistDefinitionId);
    }

    const reviewId = await dispatch(
      submitReview({
        id: review?.id || id,
        review,
        fromDrawer,
        questionsWithValuesAndBindings,
        paramsId
      })
    );

    if (!reviewId) return;

    if (reviewId !== id) {
      return goToReview(reviewId);
    }

    dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.SAVED));
  }, [dispatch, id, review, comments, isEmptyQuestionsValues, isEmptyQuestionsComments]);

  const onCommentSave = useCallback(
    comment => {
      dispatch(addComment(comment));
      if (isEmpty(comment?.metadata?.questionId)) dispatch(setAddingComment(false));
    },
    [dispatch]
  );

  const onAddCommentButton = useCallback(() => {
    dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
    dispatch(setAddingComment(true));
  }, [dispatch]);

  useEvent(document, 'keydown', e => {
    const drawer = document.getElementsByClassName('ant-drawer')[0];
    const isEditorKeyPress = e => {
      return (
        e.target.classList.contains('public-DraftEditor-content') ||
        e.target.classList.contains('ant-input') ||
        e.target.classList.contains('ant-select-selection-search-input')
      );
    };

    if (e.code === 'Space' && e.shiftKey && !isEditorKeyPress(e) && !addingCommentsToQuestionId) {
      if (!review || (review && allowCommenting)) {
        if (review) dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
        if (
          clientInteraction.type === 'phone-calls' ||
          clientInteraction.type === 'client-interactions'
        ) {
          dispatch(setAddingComment(true));
          document.getElementsByClassName('public-DraftEditor-content')[0].focus();
        }
      }
    }

    if (e.code === 'Escape' && addingComment) {
      dispatch(setAddingComment(false));
      if (drawer) drawer.focus();
    }
  });

  const onCancelComment = useCallback(() => {
    dispatch(setAddingComment(false));
  }, [dispatch]);

  const onDeleteComment = useCallback(
    ({ id, commentType }) => {
      if (
        checklistManagerState !== CHECKLIST_MANAGER_STATES.EDITING &&
        commentType === 'review_comment'
      ) {
        return message.warn(t('clientInteractionPage.messages.needToChangeEditMode'));
      }

      if (Object.values(comments).length < 2 && isEmpty(currentChecklist)) {
        dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.INITIAL));
        dispatch(setAddingComment(false));
      }

      if (!review) {
        deleteCommentByIdIndexDB(id);
      }
      dispatch(deleteComment({ id }));
    },
    [dispatch, comments, checklistManagerState]
  );

  const onUpdateComment = useCallback(
    async comment => {
      let newComment = comment;
      if (checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED) {
        try {
          newComment = await dispatch(commentsResource.operations.updateById(comment));
          message.success(t('clientInteractionPage.messages.commentSuccessfullyUpdated'));
        } catch (error) {
          message.error(t('clientInteractionPage.messages.updateCommentFailed'));
        }
      } else {
        dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
      }

      if (!review) {
        await updateCommentTextByIdIndexDB(comment);
      }
      dispatch(updateComment(newComment));
    },
    [dispatch, checklistManagerState]
  );

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  return (
    <SRow
      display="flex"
      minHeight={`calc(100vh - ${fromDrawer ? '0px' : '48px'})`}
      justifyContent="space-between"
      wrap={false}
    >
      <SCol flex="auto" overflowY="scroll" height={`calc(100vh - ${fromDrawer ? '0px' : '48px'})`}>
        <SRow
          ref={refBody}
          margin={fromDrawer ? '0' : ''}
          padding={`16px ${16 - scrollbarWidth || 0}px 16px 16px`}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <SCol span={24} flex="auto">
            {Content ? (
              <CommunicationEntityPage
                clientInteraction={clientInteraction}
                review={review}
                communicationChainId={id}
              />
            ) : (
              <SCard>
                <Skeleton active />
              </SCard>
            )}
            <AddToCalibrationModal clientInteraction={clientInteraction} />
          </SCol>
        </SRow>
      </SCol>
      <SCol
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{
          background: '#FFFFFF',
          border: '1px solid #DFE1E8',
          boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.08)',
          minWidth: '560px'
        }}
        width="min(50%, 56px)"
        height={`calc(100vh - ${fromDrawer ? '0px' : '48px'})`}
      >
        <SCol flex="none" span={24} padding="16px">
          <ChecklistManagerHead
            review={review}
            fromDrawer={fromDrawer}
            communication={clientInteraction}
            id={review?.id}
          />
        </SCol>
        <SCol
          ref={ref}
          flex="auto"
          padding={`0 ${16 - scrollbarWidth || 0}px 0px 16px`}
          style={{ overflowY: 'scroll' }}
          overflowX="hidden"
        >
          <ChecklistManager
            onSubmit={onChecklistSave}
            onCommentSave={onCommentSave}
            onCancelComment={onCancelComment}
            onDeleteComment={onDeleteComment}
            onUpdateComment={onUpdateComment}
            onAddCommentButton={onAddCommentButton}
            allowCommenting={allowCommenting}
            comments={comments}
            communication={clientInteraction}
            reviewId={id}
          />
        </SCol>
        <SCol flex="none" width="100%" span={24}>
          <ChecklistManagerFooter
            chain
            review={review}
            reviewId={review?.id}
            onSubmit={onChecklistSave}
          />
        </SCol>
      </SCol>
    </SRow>
  );
};

export default React.memo(ClientInteractionChainPage, isEqual);
