import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Select, Col, DatePicker, Form, Button, Space, Input } from 'antd';
import SCard from 'components/Standard/SCard';
import { connect, useSelector } from 'react-redux';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';

import { useTranslation } from 'react-i18next';
import { get, intersectionWith, isEmpty, isEqual, trim } from 'lodash';
import { FlagFilled, MessageFilled } from '@ant-design/icons';
import moment from 'moment';
import CustomFieldsNotifications from './components/CustomFieldsNotifications';

import QuestionsNotifications from './components/QuestionsNotifications';
import ChecklistDefinitionsNotifications from './components/ChecklistDefinitionsNotifications';
import { StyledSelect } from '../../../../DashboardPage/styled';
import {
  CALLS_DIRECTIONS,
  CLIENT_INTERACTION_TYPES,
  FLAGS_COLORS_LITERALS_RUS,
  FLAGS_TO_COLORS_RELATIONS
} from '../../../../../core/utils/constants';
import CommunicationTypes from '../../../../AiSettings/communicationFilters/CommunicationTypes';
import { selectSearch } from '../../../../../core/utils/selectSearch';
import FilterName from '../../../../ClientInteractionsPage/components/FilterName';
import RangeInputs from '../../../../../components/Inputs/RangeInputs';
import DurationPicker from '../../../../../components/Inputs/DurationPicker';
import FunnelStatusAndStage, {
  getCustomFieldNameByKey
} from '../../../../AiSettings/communicationFilters/FunnelStatusAndStage';
import Icon from '../../../../../components/Icon';
import { taskDefinitionsResource } from '../../../../../redux/resources/taskDefinitions';
import CustomTag from '../../../../../components/Tags/CustomTag';
import RangeInputDate from './shared/RangeInputDate';
import RangeInputNumber from './shared/RangeInputNumber';
import { getCustomFieldsByIds } from '../../../../../redux/selectors/customFields';
import { salePipelinesResource } from '../../../../../redux/resources/salesPipelines';
import FlagsNotifications from './components/FlagsNotifications';
import { customFieldsResource } from '../../../../../redux/resources/customFields';

const { Title, Text } = Typography;
const { Option } = Select;

const NotificationsCustomFieldsTg = ({
  field,
  form,
  loadTasks,
  tasks,
  salePipelines,
  statusesByIds,
  loadSalePipelines,
  loadCustomFields,
  customFields,
  users,
  status
}) => {
  const { t } = useTranslation();
  const [selectedParameters, setSelectedParameters] = useState([]);

  const currentUserAttributes = useSelector(state => state.reduxTokenAuth.currentUser.attributes);
  const organizationId = currentUserAttributes?.user['organization-id'];

  useEffect(() => {
    const loadInitialData = async () => {
      if (isEmpty(tasks)) {
        await loadTasks({ pagination: false });
      }
      if (isEmpty(salePipelines)) {
        await loadSalePipelines({ pagination: 'false', include: 'statuses' });
      }
      if (isEmpty(customFields)) {
        await loadCustomFields({
          organization_id: organizationId,
          pagination: false
        });
      }
    };

    loadInitialData();
  }, [organizationId]);

  useEffect(() => {
    const currentValue = form.getFieldValue([field.name, 'parametersNotification']);
    if (currentValue) {
      setSelectedParameters(currentValue);
    }
  }, []);

  const parametersNotification = useMemo(
    () => [
      { value: 'custom_fields', label: t('Кастомные поля') },
      { value: 'tasks', label: t('Теги') },
      { value: 'questions', label: t('Критерии') },
      { value: 'checklist_definitions', label: t('Форма оценок') },
      { value: 'direction', label: t('Направление звонка') },
      { value: 'duration', label: t('Продолжительность') },
      { value: 'communications_types', label: t('Тип коммуникации') },
      { value: 'funnel_communication', label: t('Воронка коммуникации') },
      { value: 'stage_communication', label: t('Этап коммуникации') },
      { value: 'flags', label: t('Флаги') },
      { value: 'contact_status', label: t('Статус контакта') },
      { value: 'date_and_time_communication', label: t('Дата и время коммуникации') },
      { value: 'operators', label: t('Проверяемый') },
      { value: 'reviewers', label: t('Проверяющий') }
    ],
    [t]
  );

  const renderUserOption = user => {
    const userName = user?.name;
    const email = user?.email;

    return (
      <Select.Option key={user.id} value={user.id}>
        {!isEmpty(trim(userName)) ? userName : email}
      </Select.Option>
    );
  };

  const parametersNotificationsTypeToComponentRenderer = useMemo(
    () => ({
      custom_fields: () => <CustomFieldsNotifications field={field} form={form} />,
      tasks: () => (
        <Form.Item name={[field.name, 'tasks']} label={t('Теги')}>
          <Select
            placeholder={t('Выберите теги')}
            mode="multiple"
            style={{ width: '100%' }}
            allowClear
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys => t('Теги: {{count}}', { count: selectedKeys.length })}
            optionLabelProp="label"
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
          >
            {Object.values(tasks).map(({ id, labelName, labelColor }) => (
              <Select.Option key={id} value={id} label={labelName}>
                <CustomTag color={labelColor} text={labelName} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
      questions: () => <QuestionsNotifications field={field} form={form} />,
      checklist_definitions: () => <ChecklistDefinitionsNotifications field={field} form={form} />,
      direction: () => (
        <Form.Item
          name={[field.name, 'client_interaction', 'direction']}
          label="Направление звонка"
        >
          <StyledSelect placeholder={t('dashboardPage.widget.modal.form.directionPlaceHolder')}>
            {Object.values(CALLS_DIRECTIONS).map(item => (
              <Option key={item.value} value={item.value}>
                {t(item.title)}
              </Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ),
      duration: () => (
        <Form.Item
          name={[...(Array.isArray(field.name) ? field.name : [field.name]), 'duration']}
          label={t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.duration')}
        >
          <RangeInputNumber
            field={{
              ...field,
              name: [...(Array.isArray(field.name) ? field.name : [field.name]), 'duration']
            }}
          />
        </Form.Item>
      ),
      communications_types: () => (
        <Form.Item
          name={[field.name, 'client_interaction', 'communication_type']}
          label="Тип коммуникации"
        >
          <StyledSelect
            disabled={false}
            mode="multiple"
            allowClear
            optionLabelProp="label"
            placeholder={t(
              'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
            )}
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t(
                'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypesShort'
              )}: ${selectedKeys.length}`
            }
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {Object.values(CLIENT_INTERACTION_TYPES).map(item => (
              <Option
                value={item.value}
                key={item.value}
                label={`${t(
                  'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
                )}: ${t(item.title)}`}
              >
                {t(item.title)}
              </Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ),
      funnel_communication: () => (
        <Form.Item
          name={[field.name, 'client_interaction', 'funnel_communication']}
          label="Воронка коммуникации"
        >
          <StyledSelect
            disabled={false}
            mode="multiple"
            allowClear
            optionLabelProp="label"
            placeholder="Воронка коммуникаций"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys => `Воронка коммуникации: ${selectedKeys.length}`}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {salePipelines.map(salePipeline => (
              <Select.Option key={salePipeline.id} value={salePipeline.id}>
                {salePipeline.name}
              </Select.Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ),
      stage_communication: () => (
        <Form.Item
          name={[field.name, 'client_interaction', 'stage_communication']}
          label="Этап коммуникации"
        >
          <StyledSelect
            disabled={false}
            mode="multiple"
            allowClear
            optionLabelProp="label"
            placeholder="Этапы коммуникаций"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys => `Этап коммуникации: ${selectedKeys.length}`}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {Object.values(statusesByIds).map(status => (
              <Select.Option key={status.id} value={status.id}>
                {status.name}
              </Select.Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ),
      flags: () => <FlagsNotifications field={field} form={form} />,
      contact_status: () => (
        <Form.Item name={[field.name, 'client_interaction', 'status_ids']} label="Статус контакта">
          <StyledSelect
            allowClear
            mode="multiple"
            placeholder={t('aiSettingsPage.taskSettings.contactStatus')}
            size="middle"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('aiSettingsPage.taskSettings.contactStatus')}: ${selectedKeys.length}`
            }
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {Object.values(status).map(unit => (
              <Select.Option key={unit.id} value={unit.id}>
                {unit.name}
              </Select.Option>
            ))}
          </StyledSelect>
        </Form.Item>
      ),
      date_and_time_communication: () => (
        <Form.Item
          name={[...(Array.isArray(field.name) ? field.name : [field.name]), 'started_at']}
          label="Дата и время коммуникации"
        >
          <RangeInputDate
            field={{
              ...field,
              name: [...(Array.isArray(field.name) ? field.name : [field.name]), 'started_at']
            }}
            form={form}
          />
        </Form.Item>
      ),
      operators: () => (
        <Form.Item name={[field.name, 'operators']} label={t('Проверяемый')}>
          <StyledSelect
            allowClear
            mode="multiple"
            placeholder={t('Выберите проверяемого')}
            size="middle"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              t('Проверяемый: {{count}}', { count: selectedKeys.length })
            }
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {Object.values(users).map(renderUserOption)}
          </StyledSelect>
        </Form.Item>
      ),
      reviewers: () => (
        <Form.Item name={[field.name, 'reviewers']} label={t('Проверяющий')}>
          <StyledSelect
            allowClear
            mode="multiple"
            placeholder={t('Выберите проверяющего')}
            size="middle"
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              t('Проверяющий: {{count}}', { count: selectedKeys.length })
            }
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {Object.values(users).map(renderUserOption)}
          </StyledSelect>
        </Form.Item>
      )
    }),
    [field, form, tasks, users, t, salePipelines, statusesByIds, status]
  );

  const handleParametersChange = values => {
    setSelectedParameters(values);
    form.setFieldsValue({
      [field.name]: {
        ...form.getFieldValue(field.name),
        parametersNotification: values
      }
    });
  };

  return (
    <SRow gutter={[0, 16]}>
      <SCol span={24}>
        <Form.Item
          name={[field.name, 'parametersNotification']}
          label={t('Получать уведомления по')}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t('Выберите параметры')}
            allowClear
            size="middle"
            onChange={handleParametersChange}
          >
            {parametersNotification.map(param => (
              <Option key={param.value} value={param.value}>
                {param.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </SCol>

      {selectedParameters.map((param, index) => {
        const Component = get(parametersNotificationsTypeToComponentRenderer, param);

        return Component ? (
          <Col span={16} key={param}>
            <Component />
            {selectedParameters.length > 1 && index < selectedParameters.length - 1 && (
              <SRow justifyContent="start" marginTop={16}>
                {t('И')}
              </SRow>
            )}
          </Col>
        ) : null;
      })}
    </SRow>
  );
};

const mapStateToProps = state => ({
  tasks: state.taskDefinitionsResource.byIds,
  salePipelines: Object.values(state.salePipelinesResource.byIds),
  statusesByIds: state.salesPipelineStatusesResource.byIds,
  customFields: state.customFieldsResource.byIds,
  users: state.usersResource.byIds,
  status: state.statusesResource.byIds
});

const mapDispatchToProps = {
  loadTasks: taskDefinitionsResource.operations.load,
  loadSalePipelines: salePipelinesResource.operations.load,
  loadCustomFields: customFieldsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsCustomFieldsTg);
