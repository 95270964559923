import { Skeleton } from 'antd';
import { COMMUNICATION_TYPES, PERMISSIONS } from 'core/utils/constants';
import { get, isEmpty } from 'lodash';
import CustomCommunicationReviewPage from 'pages/CustomCommunicationReviewPage';
import Forbidden from 'pages/ErrorPages/Forbidden';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCurrentUser } from 'redux/selectors/users';
import { loadAppealReviewById, loadReviewById } from 'redux/ui/clientInteractionPage/operations';
import * as actions from 'redux/ui/clientInteractionPage/reducer';
import SCard from 'components/Standard/SCard';
import { updateTableRow } from 'redux/ui/clientInteractions/operations';
import ClientInteractionPage from './ClientInteractionPage';
import contentTypeToComponent from './contentTypeToComponent';
import CommunicationChainPage from './CommunicationChainPage';

const ReviewPage = ({
  id,
  offsetTop,
  affixTarget,
  shouldLoad = true,
  fromDrawer = false,
  appealId,
  match
}) => {
  const reviewId = id || match?.params?.id;

  const dispatch = useDispatch();

  const currentUser = useSelector(state => getCurrentUser(state));
  const contentType = useSelector(state => state.uiClientInteractionPage.contentType);
  const reviews = useSelector(state => state.reviewsResource);
  const review = reviews.byIds[reviewId];
  const loading = reviews.loading || reviews.loadByIdStarted || isEmpty(review) || review.loading;
  const clientInteractionById = useSelector(
    state => state.clientInteractionsResource.byIds[review?.clientInteractionId]
  );
  const clientInteraction = useSelector(state => ({
    ...get(state.phoneCallsResource.byIds, review?.clientInteractionId, {}),
    ...get(state.textCommunicationsResource.byIds, review?.clientInteractionId, {}),
    ...clientInteractionById
  }));

  const loadData = async () => {
    const review = await dispatch(
      loadReviewById({
        id: reviewId,
        shouldLoad,
        include: [
          'reviewer',
          'custom_communication',
          'text_communication',
          'phone_call.phone_call_transcription.parts',
          'client_interaction.operator.unit',
          'client_interaction.text_communication_parts.operator',
          'checklist.answers.question',
          'checklist.checklist_definition.question_groups.question_to_group_bindings.question',
          'tasks.task_definition',
          'comments.uploaded-files',
          'comments.author'
        ].join(',')
      })
    );
    await dispatch(updateTableRow({ clientInteractionId: review?.clientInteractionId }));
  };

  const loadAppealData = async () => {
    const review = await dispatch(
      loadAppealReviewById({
        id: appealId,
        shouldLoad,
        include: [
          'comments',
          'comments.uploaded-files',
          'actions',
          'actions.question',
          'actions.creator',
          'actions.watcher',
          'watchers',
          'appellant',
          'objections',
          'assigned-to',
          'review.reviewer',
          'review.custom_communication',
          'review.text_communication',
          'review.phone_call',
          'review.phone_call.phone_call_transcription.parts',
          'review.client_interaction',
          'review.client_interaction.operator',
          'review.client_interaction.operator.unit',
          'review.client_interaction.text_communication_parts.operator',
          'review.checklist.questions',
          'review.checklist.answers.question',
          'review.checklist.checklist_definition.question_groups.question_to_group_bindings.question',
          'review.comments.uploaded-files',
          'review.task_definition',
          'review.tasks.task_definition',
          'review.comments'
        ].join(',')
      })
    );
    await dispatch(updateTableRow({ clientInteractionId: review?.clientInteractionId }));
  };

  useEffect(() => {
    appealId || localStorage.getItem('d_rev') === '1' ? loadAppealData() : loadData();
    localStorage.removeItem('d_rev');
    return () => dispatch(actions.setInitialState());
  }, []);

  const { t } = useTranslation();

  if (loading) {
    return (
      <SCard>
        <Skeleton active />
      </SCard>
    );
  }

  if (
    ![review.reviewerId, clientInteraction?.operatorId].includes(currentUser.id) &&
    !get(currentUser, 'role.permissions', []).includes(PERMISSIONS.CAN_SEE_UNIT_CLIENT_INTERACTIONS)
  ) {
    return <Forbidden />;
  }

  if (clientInteraction.clientInteractionType === COMMUNICATION_TYPES.CUSTOM) {
    return (
      <CustomCommunicationReviewPage
        communication={clientInteraction}
        review={review}
        fromDrawer={fromDrawer}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.customCommunicationReviewPage.titles.review')}</title>
      </Helmet>
      {clientInteraction.communicationType !== 'communication_chain' && (
        <ClientInteractionPage
          Content={contentTypeToComponent(contentType)}
          id={reviewId}
          clientInteraction={clientInteraction}
          loading={loading}
          offsetTop={offsetTop}
          affixTarget={affixTarget}
          fromDrawer={fromDrawer}
          shouldLoad={shouldLoad}
        />
      )}
      {clientInteraction.communicationType === 'communication_chain' && (
        <CommunicationChainPage
          Content={contentTypeToComponent(contentType)}
          id={reviewId}
          clientInteraction={clientInteraction}
          loading={loading}
          offsetTop={offsetTop}
          affixTarget={affixTarget}
          fromDrawer={fromDrawer}
          shouldLoad={shouldLoad}
          chain={clientInteraction.communicationType === 'communication_chain'}
        />
      )}
    </>
  );
};

export default withRouter(ReviewPage);
