import React from 'react';
import { Table } from 'antd';
import SCard from 'components/Standard/SCard';

// Кол-во коммуникаций
const getAmount = (communicationType, clientInteractions) => {
  const communications = clientInteractions.filter(
    item => item?.communicationType === communicationType
  );
  return communications?.length > 0 ? communications.length : 0;
};

// Число с 1 знаком после запятой либо null
const getSlicedValue = value => {
  return value !== null && !isNaN(value) ? Number(value.toFixed(1)) : null;
};

const getScore = value => {
  if (value.score === null) {
    value.score = 'N/A';
  }
  return value;
};

// Для каждого типа коммуникации получаем значения и заносим в таблицу
const getValues = (communicationType, communication, tableRows, clientInteraction) => {
  if (clientInteraction?.formulaResult?.formulasResult?.[communicationType]) {
    if (communication.score || communication.amount > 0) {
      tableRows.push(communication);
    }

    getScore(communication);
  } else if (communication.score || communication.amount > 0) {
    tableRows.push(communication);
  }
};

const ChainTable = ({ clientInteraction, clientInteractions }) => {
  const tableRows = [];

  let tableColumns = [
    {
      title: 'Тип коммуникации',
      dataIndex: 'type',
      key: 'type',
      align: 'center'
    },
    {
      title: 'Кол-во',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center'
    },
    {
      title: 'Оценка',
      dataIndex: 'score',
      key: 'score',
      align: 'center'
    }
  ];

  const phoneCall = clientInteraction?.scoreResult?.phoneCall;
  const chat = clientInteraction?.scoreResult?.chat;
  const ticket = clientInteraction?.scoreResult?.ticket;
  const other = clientInteraction?.scoreResult?.other;
  const chain = clientInteraction?.scoreResult?.communicationChain;
  const email = clientInteraction?.scoreResult?.email;

  const calls = {
    key: '1',
    type: 'Звонки',
    amount: getAmount('phone_call', clientInteractions),
    score: getSlicedValue(phoneCall)
  };

  getValues('phoneCall', calls, tableRows, clientInteraction);

  const chats = {
    key: '2',
    type: 'Чаты',
    amount: getAmount('chat', clientInteractions),
    score: getSlicedValue(chat)
  };

  getValues('chat', chats, tableRows, clientInteraction);

  const tickets = {
    key: '3',
    type: 'Тикеты',
    amount: getAmount('ticket', clientInteractions),
    score: getSlicedValue(ticket)
  };

  getValues('ticket', tickets, tableRows, clientInteraction);

  const others = {
    key: '4',
    type: 'Другое',
    amount: getAmount('other', clientInteractions),
    score: getSlicedValue(other)
  };

  getValues('other', others, tableRows, clientInteraction);

  const chains = {
    key: '5',
    type: 'Цепочки',
    amount: getAmount('communication_chain', clientInteractions),
    score: getSlicedValue(chain)
  };

  getValues('communicationChain', chains, tableRows, clientInteraction);

  const emails = {
    key: '6',
    type: 'Письма',
    amount: getAmount('email', clientInteractions),
    score: getSlicedValue(email)
  };

  getValues('email', emails, tableRows, clientInteraction);

  if (tableRows.every(item => item.score === null)) {
    tableColumns = tableColumns.filter(column => column.key !== 'score');
  }

  return (
    tableRows.length > 0 && (
      <SCard
        bordered="1px solid #e4e6ed"
        shadowed="0px 0px 12px rgba(0, 0, 0, 0.08)"
        headPadding="8px 24px"
        rounded="8px"
        marginBottom="10px"
      >
        <Table columns={tableColumns} dataSource={tableRows} pagination={false} size="small" />
      </SCard>
    )
  );
};

export default ChainTable;
