import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row, Select, Space, Typography } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Plus, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual, pickBy } from 'lodash';
import { selectSearch } from '../../../../../../core/utils/selectSearch';
import { checklistDefinitionsResource } from '../../../../../../redux/resources/checklistDefinitions';
import RangeInputNumber from '../shared/RangeInputNumber';
import { SCol, SRow } from '../../../../../../components/Standard';

const ChecklistDefinitionsNotifications = ({
  loadChecklistDefinitions,
  checklistDefinitions,
  form,
  field
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isEmpty(checklistDefinitions)) {
      loadChecklistDefinitions({ pagination: false });
    }
  }, []);

  const handleDefinitionChange = (value, subField) => {
    const currentDefinitions = form.getFieldValue([field.name, 'checklist_definitions']) || {};
    form.setFieldsValue({
      [field.name]: {
        checklist_definitions: {
          ...currentDefinitions,
          [subField.name]: {
            id: value || { value: null, label: '', key: null },
            range: { min: null, max: null }
          }
        }
      }
    });
  };

  const getUsedDefinitionIds = () => {
    const definitionsData = form.getFieldValue([field.name, 'checklist_definitions']) || {};
    return Object.values(definitionsData)
      .filter(item => item?.id?.value)
      .map(item => item.id.value);
  };

  return (
    <Form.List name={[field.name, 'checklist_definitions']}>
      {(subFields, { add, remove }) => (
        <>
          {subFields.map(subField => {
            const selectedDefinition =
              form.getFieldValue([field.name, 'checklist_definitions', subField.name]) || {};
            const selectedId = selectedDefinition.id?.value;

            return (
              <SRow key={subField.key} align="middle" gutter={[8, 8]}>
                <SCol span={12}>
                  <Form.Item noStyle name={[subField.name, 'id']}>
                    <Select
                      placeholder={t('Выберите Форму оценки')}
                      size="middle"
                      allowClear
                      showSearch
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        selectSearch({ input, option, searchProp: 'children' })
                      }
                      style={{ width: '100%' }}
                      labelInValue
                      onChange={value => handleDefinitionChange(value, subField)}
                    >
                      {Object.values(checklistDefinitions)
                        .filter(item => {
                          const usedIds = getUsedDefinitionIds();
                          return !usedIds.includes(item.id) || item.id === selectedId;
                        })
                        .map(definition => (
                          <Select.Option
                            key={definition.id}
                            value={definition.id}
                            label={definition.name}
                          >
                            {definition.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </SCol>
                <SCol span={11}>
                  <RangeInputNumber field={subField} />
                </SCol>
                <SCol span={1}>
                  <CloseOutlined onClick={() => remove(subField.name)} />
                </SCol>
              </SRow>
            );
          })}
          <Button
            type="dashed"
            onClick={() =>
              add({
                id: { value: null, label: '', key: null },
                range: { min: null, max: null }
              })
            }
            block
          >
            + {t('Добавить формы оценки')}
          </Button>
        </>
      )}
    </Form.List>
  );
};

const mapStateToProps = state => ({
  checklistDefinitions: state.checklistDefinitionsResource.byIds
});

const mapDispatchToProps = {
  loadChecklistDefinitions: checklistDefinitionsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDefinitionsNotifications);
