import React, { useEffect, useState } from 'react';
import { Col, Form, message, Modal, Progress, Row, Select } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { getUIClientInteractions } from 'redux/selectors/uiClientInterationsTable';
import { DeleteOutlined, InfoCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import { utils } from 'react-media-player';
import { isEqual } from 'lodash';
import UserPreview from 'components/UserPreview';
import STable from 'components/Standard/STable';
import ClientInteractionLink from 'components/ClientInteractionLink';
import {
  addCommunicationChainsEndpoint,
  checkMassCommunicationChainsEndpoint,
  createMassCommunicationChainsEndpoint,
  getCommunicationChains,
  updateCommunicationChainsEndpoint
} from 'core/api';
import Icon from 'components/Icon';
import { IconButton } from 'components/Buttons';
import { SText } from 'components/Standard';
import { CALL_DIRECTION_LITERALS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { selectSearch } from 'core/utils/selectSearch';
import DateSpecificSelector from 'components/Inputs/DateSpecificSelector';
import SecondsSpecificSelector from 'components/Inputs/SecondsSpecificSelector';
import { getCurrentUser } from 'redux/selectors/users';
import { DeactivatedUserLinkToCommunication } from './DeactivatedLinkToCommunication';
import { SInput } from './styled';

const { Option } = Select;

const ModalWindowCheckbox = ({
  isOpen,
  setIsOpen,
  selectedModal,
  setSelectedModal,
  selection,
  hasConflicts,
  users,
  activeFilter,
  setActiveFilter,
  tableId
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const currentUser = useSelector(getCurrentUser, isEqual);
  const [allTableRows, setAllTableRows] = useState([]);
  const [selectedCommunications, setSelectedCommunications] = useState([]);
  const [loadingCommunicationChains, setLoadingCommunicationChains] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { selected } = selection;
  const { tableLoading, tableRows } = useSelector(getUIClientInteractions, shallowEqual);
  const tablesLoading = useSelector(state => state.communicationTablesResource.loading);
  const { loading: listLoading } = useSelector(state => state.clientInteractionsList, shallowEqual);
  const loading = listLoading || tableLoading || tablesLoading;
  const dataSource = hasConflicts && !loading ? [] : allTableRows;
  const activeTable = localStorage.getItem('DEALAPP_ACTIVE_TABLE_ID');
  const [communicationChains, setCommunicationChains] = useState([]);

  useEffect(() => {
    if (tableRows) {
      setAllTableRows(prevAllTableRows => {
        const mergedRows = [...prevAllTableRows, ...tableRows];

        const uniqueRows = mergedRows.reduce((acc, row) => {
          const isUnique = !acc.some(item => JSON.stringify(item) === JSON.stringify(row));
          return isUnique ? [...acc, row] : acc;
        }, []);

        return uniqueRows;
      });
    }
  }, [tableRows]);

  useEffect(() => {
    fetch(
      `${getCommunicationChains}${activeTable}?filters[communications_types]=communication_chain&pagination=false`,
      {
        headers: {
          'Content-Type': 'application/json',
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid')
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        const validateCommunicationChains = data.included.filter(
          item => item.attributes.client_interaction_type === 'communication_chain'
        );
        setCommunicationChains(validateCommunicationChains);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const filteredCommunications = dataSource.filter(item => selected.includes(item.id));

    const uniqueCommunications = filteredCommunications.filter(
      (item, index, self) => index === self.findIndex(t => t.id === item.id)
    );

    setSelectedCommunications(uniqueCommunications);
  }, [selected, allTableRows]);

  const closeModal = () => {
    form.resetFields();
    setSelectedModal(null);
    setActiveFilter(null);
    setIsOpen(false);
    setSelectedItemId(null);
    setLoadingCommunicationChains(false);
  };

  const handleDelete = id => {
    const updatedChains = selectedCommunications.filter(chain => chain.id !== id);
    selection.toggle(id);
    setSelectedCommunications(updatedChains);
  };

  // Получение заголовков для запроса
  const getHeaders = () => ({
    'access-token': localStorage.getItem('access-token'),
    client: localStorage.getItem('client'),
    uid: localStorage.getItem('uid'),
    'Content-Type': 'application/json'
  });

  const handleSubmit = async formData => {
    const {
      duration,
      startedAt: startedAtFormData,
      operator,
      clientPhoneNumber,
      communicationChainId
    } = formData;

    // Форматирование даты и времени
    const formatDuration = moment.utc(duration).format('HH:mm:ss.SSSZ');
    const formatStartedAt = moment.utc(startedAtFormData).format('YYYY-MM-DD[T]');
    const startedAt = `${formatStartedAt}${formatDuration}`;

    // Выполнение HTTP-запроса
    const fetchRequest = async (url, method, body) => {
      try {
        const response = await fetch(url, {
          method,
          headers: getHeaders(),
          body: JSON.stringify(body)
        });
        const data = await response.json();
        if (data?.errors && Array.isArray(data.errors) && data.errors.length > 0) {
          if (data?.errors[0].status === 422) {
            return 422;
          }
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return data;
      } catch (err) {
        message.error(t('errorPages.internalServerError.title'));
        console.error('Error:', err);
        throw err;
      }
    };

    // Обновление selectedCommunications
    const updateSelectedCommunications = async chainId => {
      setLoadingCommunicationChains(true);
      try {
        const requests = selectedCommunications.map(chain =>
          fetchRequest(updateCommunicationChainsEndpoint, 'PUT', {
            communication_id: chain.id,
            communication_chain_id: chainId
          })
        );
        await Promise.all(requests);
        message.success(
          communicationChainId
            ? t('communicationChain.createChainModal.successAdd')
            : t('communicationChain.createChainModal.successCreate')
        );
      } catch (err) {
        message.error(t('errorPages.internalServerError.title'));
        console.error('Error updating communications:', err);
      } finally {
        setLoadingCommunicationChains(false);
        closeModal();
      }
    };

    try {
      if (communicationChainId) {
        // Если выбрана существующая цепочка коммуникаций
        await updateSelectedCommunications(communicationChainId);
      } else {
        // Если создается новая цепочка коммуникаций
        const data = {
          organization_id: organizationId,
          communication_type: 'communication_chain',
          operator_id: operator,
          started_at: startedAt,
          client_phone_number: clientPhoneNumber
        };
        const response = await fetchRequest(addCommunicationChainsEndpoint, 'POST', data);
        if (response === 422) {
          return message.info(t('communicationChain.infoMessages.numberAlreadyExists'), 5);
        }
        const newCommunicationChainId = response.data.id;
        await updateSelectedCommunications(newCommunicationChainId);
      }
    } catch (err) {
      message.error(t('errorPages.internalServerError.title'));
      setLoadingCommunicationChains(false);
      console.error('Error in handleSubmit:', err);
    }
  };

  const handleSubmitActiveFilter = async formData => {
    const {
      duration,
      startedAt: startedAtFormData,
      clientPhoneNumber,
      communicationChainId
    } = formData;

    // Форматирование даты и времени
    const formatDuration = moment.utc(duration).format('HH:mm:ss.SSS');
    const formatStartedAt = moment.utc(startedAtFormData).format('YYYY-MM-DD[T]');
    const startedAt = `${formatStartedAt}${formatDuration}`;

    // Формирование тела запроса
    const createRequestBody = communicationChainId => {
      const baseBody = {
        action: 'chain',
        organization_id: organizationId,
        user_email: currentUser.email,
        communication_table_id: tableId
      };

      return communicationChainId
        ? { ...baseBody, chain_id: communicationChainId } // add communications in chain
        : { ...baseBody, phone: clientPhoneNumber, started_at: startedAt }; // create new chain
    };

    // Обработка ответа от сервера
    const handleServerResponse = async data => {
      const { requestId } = data;
      let bulkActionInterval;
      let bulkActionCheckModal;
      let bulkActionPercent = 0;

      const closeModalAndShowMessage = isAdd => {
        Modal.destroyAll();
        closeModal();
        message.success(
          isAdd
            ? t('communicationChain.createChainModal.successAdd')
            : t('communicationChain.createChainModal.successCreate')
        );
      };

      const updateProgressModal = (percent, isCompleted = false) => {
        bulkActionCheckModal.update({
          confirmLoading: !isCompleted,
          content: <Progress percent={percent} status={isCompleted ? 'success' : 'active'} />
        });
      };

      const startBulkActionPolling = () => {
        bulkActionInterval = setInterval(async () => {
          try {
            bulkActionPercent = await fetch(checkMassCommunicationChainsEndpoint, {
              method: 'POST',
              headers: getHeaders(),
              body: JSON.stringify({ request_id: requestId })
            })
              .then(response => response.json())
              .then(data => Number(data.percent));

            if (bulkActionPercent === 100) {
              clearInterval(bulkActionInterval);
              updateProgressModal(bulkActionPercent, true);
              closeModalAndShowMessage(!!communicationChainId);
            } else {
              updateProgressModal(bulkActionPercent);
            }
          } catch (error) {
            clearInterval(bulkActionInterval);
            Modal.destroyAll();
          }
        }, 2000);
      };

      const showInitialModal = () => {
        bulkActionCheckModal = Modal.confirm({
          confirmLoading: true,
          title: t('communicationChain.createChainModal.bulkActionCheckModal.title'),
          autoFocusButton: null,
          okButtonProps: { style: { display: 'none' } },
          cancelText: t('communicationChain.createChainModal.cancel'),
          content: <Progress percent={bulkActionPercent} status="active" />,
          onCancel: () => {
            clearInterval(bulkActionInterval);
            Modal.confirm({
              title: t('communicationChain.createChainModal.bulkActionCheckModal.title'),
              okText: t('communicationChain.createChainModal.ok'),
              cancelText: t('communicationChain.createChainModal.cancel'),
              content: t(
                'communicationChain.createChainModal.bulkActionCheckModal.cancelTextModal'
              ),
              onOk: () => Modal.destroyAll(),
              onCancel: () => {
                startBulkActionPolling();
              }
            });
          }
        });
      };

      await closeModal();
      showInitialModal();
      startBulkActionPolling();
    };

    try {
      setLoadingCommunicationChains(true);
      const response = await fetch(createMassCommunicationChainsEndpoint, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(createRequestBody(communicationChainId))
      });

      const data = await response.json();
      await handleServerResponse(data);
    } catch (err) {
      message.error(t('errorPages.internalServerError.title'));
      console.error('Error:', err);
      setLoadingCommunicationChains(false);
    }
  };

  const columns = [
    {
      title: (
        <>
          <UnorderedListOutlined />
          Тип
        </>
      ),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (type, { reviewId, operator, id, communicationType, clientInteractionType }) =>
        operator?.active ? (
          <ClientInteractionLink
            communication={{ communicationType, clientInteractionType }}
            isReview={!!reviewId}
            id={reviewId || id}
            isNewPage
          />
        ) : (
          <DeactivatedUserLinkToCommunication>
            <ClientInteractionLink
              communication={{ communicationType, clientInteractionType }}
              isReview={!!reviewId}
              id={reviewId || id}
              isNewPage
            />
          </DeactivatedUserLinkToCommunication>
        )
    },
    {
      title: t('communicationChain.createChainModal.columns.operator.title'),
      dataIndex: 'operator',
      key: 'operator',
      width: 200,
      ellipsis: true,
      render: (text, record) => (
        <UserPreview disabled userId={record.operatorId} showAvatar truncateSize={25} />
      )
    },
    {
      title: t('communicationChain.createChainModal.columns.direction.title'),
      dataIndex: 'direction',
      key: 'direction',
      width: 120,
      ellipsis: true,
      render: (text, record) => <SText>{t(CALL_DIRECTION_LITERALS[record.direction])}</SText>
    },
    {
      title: t('communicationChain.createChainModal.columns.duration.title'),
      dataIndex: 'duration',
      key: 'duration',
      width: 70,
      ellipsis: true,
      render: (text, record) =>
        record.duration ? <Text strong>{utils.formatTime(record.duration)}</Text> : ''
    },
    {
      title: t('communicationChain.createChainModal.columns.clientPhoneNumber.title'),
      dataIndex: 'clientPhoneNumber',
      key: 'clientPhoneNumber',
      width: 150,
      ellipsis: true,
      render: (text, record) => record.clientPhoneNumber
    },
    {
      title: t('communicationChain.createChainModal.columns.score.title'),
      dataIndex: 'score',
      key: 'score',
      width: 100,
      ellipsis: true,
      render: (data, record) =>
        record.reviewStatus === 'in_progress'
          ? t('communicationChain.createChainModal.resultIsCalculated')
          : !Number.isNaN(record?.reviewChecklist?.score) &&
            (beatifyFloat(record?.reviewChecklist?.score) ?? data)
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 20,
      render: id => (
        <DeleteOutlined
          style={{ cursor: 'pointer' }}
          onClick={() =>
            selectedCommunications.length <= 2
              ? message.info(t('communicationChain.infoMessages.notEnoughCommunications'), 4)
              : handleDelete(id)
          }
        />
      )
    }
  ];

  const initialValues = {
    duration: moment(),
    startedAt: moment()
  };

  const handleSelectChange = value => {
    setSelectedItemId(value);
  };

  const handleDisableHoursMinutes = (selectedHour, action) => {
    if (action === 'hours') {
      const currentHour = moment().hour();
      const disabledHours = [];
      for (let i = currentHour + 1; i < 24; i++) {
        disabledHours.push(i);
      }
      return disabledHours;
    }
    if (action === 'minutes') {
      if (selectedHour === moment().hour()) {
        const currentMinute = moment().minute();
        const disabledMinutes = [];
        for (let i = currentMinute + 1; i < 60; i++) {
          disabledMinutes.push(i);
        }
        return disabledMinutes;
      }
      return [];
    }
  };

  return (
    <>
      {isOpen && selectedModal === 'communicationChains' && (
        <Modal
          width="960px"
          visible={isOpen}
          maskClosable
          destroyOnClose
          onCancel={closeModal}
          onOk={form.submit}
          confirmLoading={loadingCommunicationChains}
          title={t('communicationChain.createChainModal.title')}
          cancelText={t('communicationChain.createChainModal.cancel')}
          okText={
            !selectedItemId
              ? t('communicationChain.createChainModal.create')
              : t('communicationChain.createChainModal.add')
          }
        >
          <Form
            onFinish={activeFilter ? handleSubmitActiveFilter : handleSubmit}
            initialValues={initialValues}
            layout="vertical"
            form={form}
          >
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  name="communicationChainId"
                  label={
                    <>
                      {t('communicationChain.createChainModal.title')}
                      <IconButton
                        tooltip={{
                          title: t(
                            'communicationChain.createChainModal.fields.communicationChainId.tooltip'
                          ),
                          overlayStyle: { width: '393px' }
                        }}
                        button={{
                          icon: <Icon icon={InfoCircleOutlined} />,
                          size: 'icon'
                        }}
                      />
                    </>
                  }
                >
                  <Select
                    allowClear
                    placeholder={t(
                      'communicationChain.createChainModal.fields.communicationChainId.placeholder'
                    )}
                    loading={loading}
                    showSearch
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      selectSearch({ input, option, searchProp: 'children' })
                    }
                    onChange={handleSelectChange}
                  >
                    {communicationChains.map(item => {
                      const user = Object.values(users).find(
                        user => user.id === item.relationships.operator.data.id
                      );
                      return (
                        <Option
                          key={item.id}
                          value={item.id}
                          label={
                            item.attributes.client_phone_number
                              ? `${item?.attributes.client_phone_number} - ${user?.email || 'N/A'}`
                              : `${user?.email || 'N/A'}`
                          }
                        >
                          {item.attributes.client_phone_number
                            ? `${item?.attributes.client_phone_number} - ${user?.email || 'N/A'}`
                            : `${user?.email || 'N/A'}`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {!selectedItemId && (
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="clientPhoneNumber"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'communicationChain.createChainModal.fields.clientPhoneNumber.emptyFieldMessage'
                        )
                      }
                    ]}
                  >
                    <SInput
                      placeholder={t(
                        'communicationChain.createChainModal.fields.clientPhoneNumber.placeholder'
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    name="operator"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'communicationChain.createChainModal.fields.operator.emptyFieldMessage'
                        )
                      }
                    ]}
                  >
                    <Select
                      allowClear
                      placeholder={t(
                        'communicationChain.createChainModal.fields.operator.placeholder'
                      )}
                      loading={loading}
                      showSearch
                      optionLabelProp="label"
                      filterOption={(input, option) =>
                        selectSearch({ input, option, searchProp: 'children' })
                      }
                    >
                      {Object.values(users).map(item => (
                        <Option key={item.email} value={item.email} label={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: '13px' }}>
                  <Form.Item
                    name="startedAt"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'communicationChain.createChainModal.fields.startedAt.emptyFieldMessage'
                        )
                      }
                    ]}
                  >
                    <DateSpecificSelector
                      allowClear
                      format="DD/MM/YYYY"
                      style={{ width: '100%' }}
                      showToday
                      placeholder={t(
                        'communicationChain.createChainModal.fields.startedAt.placeholder'
                      )}
                      disabledDate={current => current && current > moment().endOf('day')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="duration"
                    rules={[
                      {
                        required: true,
                        message: t(
                          'communicationChain.createChainModal.fields.duration.emptyFieldMessage'
                        )
                      }
                    ]}
                  >
                    <SecondsSpecificSelector
                      allowClear
                      format="HH:mm:ss"
                      style={{ width: '100%' }}
                      defaultOpenValue={moment().startOf('day')}
                      placeholder={t(
                        'communicationChain.createChainModal.fields.duration.placeholder'
                      )}
                      changeOnSelect
                      disabledHours={() => handleDisableHoursMinutes(null, 'hours')}
                      disabledMinutes={selectedHour =>
                        handleDisableHoursMinutes(selectedHour, 'minutes')
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {/* Если это действие не для активного фильтра то отображаем Выбранные коммуникации */}
            {!activeFilter && (
              <Form.Item label={t('communicationChain.createChainModal.selectedCommunications')}>
                <STable
                  size="small"
                  rowKey="id"
                  columns={columns}
                  dataSource={selectedCommunications}
                  tableLayout="fixed"
                  pagination={false}
                  loading={loading}
                  border
                  scroll={{ y: 462 }}
                />
              </Form.Item>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ModalWindowCheckbox;
