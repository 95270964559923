import { StopOutlined } from '@ant-design/icons';
import { Radio, Select, Space, Tooltip } from 'antd';
import { SCALE_TYPES } from 'core/utils/constants';
import { get, isEmpty, isNil } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SButton from 'components/Standard/SButton';
import SRow from 'components/Standard/SRow';

const CHANGE_VIEW_VALUES = {
  CHARACTERS: 32,
  RATING_VALUES_LENGTH: 5
};

const MIN_WIDTH_HEIGHT = 32;
const BORDER_RADIUS = 40;

const RoundButton = styled(SButton)`
  & {
    &.ant-btn-round {
      padding: 0 8px;
      min-width: ${MIN_WIDTH_HEIGHT}px;
      min-height: ${MIN_WIDTH_HEIGHT}px;

      border: 1px solid #dfe1e8;
      border-radius: ${BORDER_RADIUS}px;
      width: max-content;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      & span {
        display: initial;
      }
    }
  }
`;

const RadioGroup = styled(Radio.Group)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
  }
  && label.ant-radio-button-wrapper {
    display: flex;
    padding: 4px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    min-width: ${MIN_WIDTH_HEIGHT}px;
    min-height: ${MIN_WIDTH_HEIGHT}px;

    border: 1px solid #dfe1e8;
    border-radius: ${BORDER_RADIUS}px;
    &:not(.ant-radio-button-wrapper-checked) {
      background: #fafbfc;
    }

    .ant-radio-button-input {
      display: none;
    }

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.ant-radio-button-wrapper-disabled {
      &.ant-radio-button-wrapper-checked {
        background-color: #c2c4cc;
        color: #ffffff;
      }
    }
  }
`;

const StyleSpace = styled(Space)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    & > .ant-space-item {
      max-width: ${({ showNull = false }) => (showNull ? 'calc(100% - 4px)' : '100%')};
      width: max-content;
      &:nth-child(2) {
        max-width: max-content;
        width: ${({ showNull = false }) =>
          showNull ? 'calc(100% - 40px)' : ' -webkit-fill-available'};
      }
    }
  }
`;

const RateValues = ({
  disabled,
  size = 'medium',
  ratingValues = [],
  ratingValuesViews = [],
  scaleType,
  value,
  valuesDisplayType = 'numbers',
  onChange,
  allowMultiple = false,
  showNull = true
}) => {
  const { t } = useTranslation();
  const onClick = ({ target }) => onChange(target.value);

  const onClickMultiple = rate => {
    if (!Array.isArray(value)) return; // Проверяем, что value массив

    const newValue = isNil(rate) ? null : parseFloat(rate);

    onChange(
      value.includes(newValue)
        ? value.filter(current => current !== newValue)
        : [...value, newValue]
    );
  };

  const valuesViews = get(
    SCALE_TYPES,
    [scaleType, valuesDisplayType],
    isEmpty(ratingValuesViews) ? ratingValues : ratingValuesViews
  );

  const getValueView = ({ rate }) => {
    if (scaleType === SCALE_TYPES.custom_binary.type) {
      return valuesDisplayType === 'numbers' ? rate : valuesViews[ratingValues.indexOf(rate)];
    }
    const fullRatingValues = get(SCALE_TYPES, [scaleType, 'numbers'], ratingValues);

    // return truncateString(valuesViews[fullRatingValues.indexOf(rate)] || '', 5);
    return valuesViews[fullRatingValues.indexOf(rate)] || rate;
  };

  if (
    ratingValues.length > CHANGE_VIEW_VALUES.RATING_VALUES_LENGTH &&
    !allowMultiple &&
    scaleType === SCALE_TYPES.custom_with_views.type
  ) {
    return (
      <Select
        onChange={onChange}
        style={{ width: '100%', minWidth: '100px' }}
        value={value}
        disabled={disabled}
        placeholder={t('components.rateValues.text.placeholder')}
      >
        {(showNull ? [null, ...ratingValues] : ratingValues).map(rate => (
          <Select.Option value={rate} key={rate}>
            {getValueView({ rate }) || 'N/A'}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (allowMultiple) {
    return (
      <SRow type="flex" width="100%">
        <StyleSpace
          size={[4, 4]}
          wrap
          showNull={showNull}
          style={{ display: 'flex', height: 'max-content' }}
        >
          {showNull && (
            <RoundButton
              key={null}
              type={value?.includes('null') || value?.includes(null) ? 'primary' : 'default'}
              value={null}
              onClick={() => onClickMultiple(null)}
              shape="round"
            >
              <StopOutlined />
            </RoundButton>
          )}

          {ratingValues.map(rate => (
            <Tooltip key={rate} title={t(getValueView({ rate }))} style={{ width: '100%' }}>
              <RoundButton
                value={rate}
                type={value?.includes(rate) ? 'primary' : 'default'}
                onClick={() => onClickMultiple(rate)}
                shape="round"
              >
                {t(getValueView({ rate }))}
              </RoundButton>
            </Tooltip>
          ))}
        </StyleSpace>
      </SRow>
    );
  }

  return (
    <RadioGroup
      disabled={disabled}
      size={size}
      onChange={onClick}
      value={value}
      buttonStyle="solid"
    >
      <SRow type="flex" width="100%">
        <StyleSpace
          size={[4, 4]}
          wrap
          showNull={showNull}
          style={{ display: 'flex', height: 'max-content' }}
        >
          {showNull && (
            <Radio.Button key="disabled" value={null}>
              <StopOutlined />
            </Radio.Button>
          )}

          {ratingValues.map((rate, index) => (
            <Tooltip key={index} title={t(getValueView({ rate }))} style={{ width: '100%' }}>
              <Radio.Button
                value={rate}
                style={{
                  width: `calc(50%, ${100 / ratingValues.length}%)`
                }}
              >
                {t(getValueView({ rate, index }))}
              </Radio.Button>
            </Tooltip>
          ))}
        </StyleSpace>
      </SRow>
    </RadioGroup>
  );
};

export default React.memo(RateValues);
