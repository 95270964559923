import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, message } from 'antd';
import styled from 'styled-components';
import { isEmpty, debounce } from 'lodash';
import Text from 'antd/lib/typography/Text';
import { userCustomFieldsResource } from '../redux/resources/userCustomFields';
import { updateUiWidgetFilters } from '../redux/ui/widgetSettingsModal/reducer';
import CustomFieldValue from './CustomFieldsValue';
import { StyledSelect } from './WidgetUserFields';
import { selectSearch } from '../core/utils/selectSearch';
import { customDecamelizeObjectKeys } from '../core/utils/customDecamelize';

const FilterContainer = styled.div`
  width: 100%;
  margin: 8px 0;
`;

const WidgetUserFieldsValues = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { filters } = useSelector(state => state.uiWidgetSettingsModal);
  const customFields = useSelector(state => Object.values(state.userCustomFieldsResource.byIds));
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );

  useEffect(() => {
    if (isEmpty(customFields)) {
      dispatch(
        userCustomFieldsResource.operations.load({
          organization_id: organizationId,
          pagination: false,
          sort: '-created_at'
        })
      );
    }

    if (filters?.userFieldsFilter) {
      form.setFieldsValue(customDecamelizeObjectKeys(filters.userFieldsFilterValues));
    }
  }, []);

  const onValuesChange = useCallback(
    debounce(values => {
      dispatch(updateUiWidgetFilters({ userFieldsFilterValues: values }));
    }, 500),
    []
  );

  return (
    <Col span={24}>
      <Text strong>Фильтр по пользовательским полям</Text>
      <Form
        form={form}
        onValuesChange={(changedValues, allValues) => {
          onValuesChange(allValues);
        }}
      >
        {filters?.userFieldsFilter?.map(fieldId => {
          const field = customFields.find(f => f.id === fieldId);
          if (!field) return null;
          return (
            <Form.Item key={field.id} name={field.key} style={{ marginBottom: 8 }}>
              <CustomFieldValue
                fieldName={field.name}
                fieldType={field.fieldType}
                value={filters.userFieldsFilterValues[field.key] ?? null}
              />
            </Form.Item>
          );
        })}

        <FilterContainer>
          <StyledSelect
            mode="multiple"
            allowClear
            optionLabelProp="label"
            placeholder="Фильтр по пользовательским полям"
            value={filters?.userFieldsFilter || []}
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys => `Полей: ${selectedKeys.length}`}
            onChange={userFieldsFilter => {
              if (userFieldsFilter?.length <= 10) {
                const selectedKeys = userFieldsFilter
                  .map(fieldId => customFields.find(f => f.id === fieldId)?.key)
                  .filter(Boolean);

                const updatedValues = Object.fromEntries(
                  Object.entries(filters.userFieldsFilterValues || {}).filter(([key]) =>
                    selectedKeys.includes(key)
                  )
                );
                dispatch(updateUiWidgetFilters({ userFieldsFilterValues: updatedValues }));
                return dispatch(updateUiWidgetFilters({ userFieldsFilter }));
              }
              message.info('Можно выбрать не более 10 пользовательских полей');
            }}
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {customFields.map(item => (
              <StyledSelect.Option value={item.id} key={item.id} label={item.name}>
                {item.name}
              </StyledSelect.Option>
            ))}
          </StyledSelect>
        </FilterContainer>
      </Form>
    </Col>
  );
};

export default WidgetUserFieldsValues;
