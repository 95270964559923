import { Copy, Trash2, Edit, File, Inbox, Settings } from 'react-feather';
import { Button, Col, Dropdown, Input, Menu, message, Modal, Tooltip, Typography } from 'antd';
import {
  CHECKLIST_DEFINITION_STATUS,
  RATING_CALCULATION,
  RATING_METHOD,
  RATING_MODE,
  SCALE_TYPES
} from 'core/utils/constants';
import { flatten, get, isEmpty, sumBy, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions, operations } from 'redux/lists/checklistDefinitionsList';
import { checklistDefinitionsResource } from 'redux/resources/checklistDefinitions';
import { setEditingColorZonesBeforePublish } from 'redux/ui/checklistEditor/reducer';
import ChecklistDefinitionStatusTag from 'components/Checklist/ChecklistDefinitionStatusTag';
import ColorZonesBeforePublishModal from 'components/ColorZones/ColorZonesBeforePublishModal';
import UserPreview from 'components/UserPreview';
import Icon from 'components/Icon';
import STable from 'components/Standard/STable';
import SText from 'components/Standard/SText';
import { IconButton } from 'components/Buttons';
import {
  InfoCircleOutlined,
  LoadingOutlined,
  MoreOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { operations as promptsListOperations } from 'redux/lists/promptsList';
import { operations as promptsQuestionAllListOperations } from 'redux/lists/promptsQuestionsAllList';
import { operations as promptsCustomFieldAllListOperations } from 'redux/lists/promptsCustomFieldsAllList';

const { Text } = Typography;
const sortableColumns = ['createdAt'];

const ChecklistsList = ({
  history,
  page,
  loading,
  updatePage,
  loadChecklistDefinitions,
  loadPrompts,
  loadAllQuestions,
  loadAllCustomFields,
  checklistDefinitions,
  checklistDefinitionsByIds,
  updateChecklistDefinition,
  deleteChecklistDefinition,
  copyChecklistDefinition,
  clearList,
  questionGroupsByIds,
  questionsByIds,
  bindingsByIds,
  setEditingColorZonesBeforePublish,
  totalCount,
  updateSort
}) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const sort = useSelector(state => state.checklistDefinitionsList.sort);
  const [searchText, setSearchText] = useState('');
  const [searchFlag, setSearchFlag] = useState(false);
  useEffect(() => {
    clearList();
    return clearList;
  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    loadChecklistDefinitions({
      page,
      include: 'creator,question_groups.question_to_group_bindings.question',
      sort,
      // sort: '-created_at',
      search_input: searchText
    });
    loadPrompts({
      organization_id: organizationId,
      pagination: false
    });
    // all questions
    loadAllQuestions({
      organization_id: organizationId,
      type: 'questions',
      availability: 'all_availability'
    });
    // all custom fields
    loadAllCustomFields({
      organization_id: organizationId,
      type: 'custom_fields',
      availability: 'all_availability'
    });
  }, [page.number, page.size, searchFlag, sort]);

  const createCopy = async ({ id, name }) => {
    try {
      const result = await copyChecklistDefinition({ id, name });
      if (result.type === 'checklist-definitions') {
        history.push(`/checklist-editor/${result.id}`);
        message.success(t('components.checklistsList.messages.copySuccessfullyCreated'));
      }
    } catch (error) {
      console.log(error);
      message.error(t('components.checklistsList.messages.createCopyFailed'));
    }
  };

  // ! страница списка чек-листов
  const showPublishModal = (id, checklistProps = {}) => {
    Modal.destroyAll();
    return Modal.confirm({
      title: t('components.checklistsList.publishChecklistConfirm.title'),
      content: t('components.checklistsList.publishChecklistConfirm.description'),
      onOk: async () => {
        await updateChecklistDefinition({
          status: CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          id,
          ...checklistProps
        });
        setEditingColorZonesBeforePublish({});
      },
      okText: t('components.checklistsList.publishChecklistConfirm.ok'),
      cancelText: t('components.checklistsList.publishChecklistConfirm.cancel')
    });
  };

  const onPublish = async ({ checklistDefinitionId, colorZones }) => {
    await showPublishModal(checklistDefinitionId, { colorZones });
  };

  const handleMenuClick = ({ key, id }) => {
    const actions = {
      edit: () => history.push(`checklist-editor/${id}`),
      settings: () => history.push(`checklist-settings/${id}`),
      publish: () => {
        // TODO: move this flow to common components

        const checklistDefinition = checklistDefinitionsByIds[id];
        const {
          ratingMethod,
          scaleType,
          ratingCalculation,
          status,
          ratingMode
        } = checklistDefinition;

        const questionGroups = get(checklistDefinition, 'questionGroupsIds', []).reduce(
          (acc, id) => (questionGroupsByIds[id] ? [...acc, questionGroupsByIds[id]] : acc),
          []
        );

        const bindings = flatten(
          questionGroups.map(({ questionToGroupBindingsIds }) =>
            questionToGroupBindingsIds.map(bindingId => bindingsByIds[bindingId])
          )
        );

        const questions = bindings
          .map(binding => get(questionsByIds, binding.questionId))
          .filter(Boolean);

        const sumPercentage = sumBy(bindings, 'percentage');

        if (ratingMethod === RATING_METHOD.WEIGHTED && sumPercentage < 100)
          return message.warning(
            t('components.checklistsList.messages.sumPercentageLessThanHundren')
          );

        if (isEmpty(questions)) {
          return message.warning(t('components.checklistsList.messages.emptyQuestions'));
        }

        if (
          status === CHECKLIST_DEFINITION_STATUS.DRAFT.value &&
          (scaleType === SCALE_TYPES.custom.type ||
            scaleType === SCALE_TYPES.custom_binary.type ||
            ratingCalculation === RATING_CALCULATION.SUM) &&
          ratingMode === RATING_MODE.NUMBERS
        ) {
          return setEditingColorZonesBeforePublish({
            checklistDefinitionId: id,
            colorZones:
              get(checklistDefinition, 'colorZones', SCALE_TYPES[scaleType].colorZones) ||
              SCALE_TYPES.max_5.colorZones
          });
        }

        return showPublishModal(id);
      },
      archive: () =>
        Modal.confirm({
          okText: t('components.checklistsList.confirmChecklistArchive.ok'),
          cancelText: t('components.checklistsList.confirmChecklistArchive.cancel'),
          title: t('components.checklistsList.confirmChecklistArchive.title'),
          content: t('components.checklistsList.confirmChecklistArchive.description'),
          onOk: () =>
            updateChecklistDefinition({
              id,
              name: get(checklistDefinitionsByIds, `${id}.name`, undefined),
              unitsIds: get(checklistDefinitionsByIds, `${id}.unitsIds`, undefined),
              status: 'archived'
            })
        }),
      copy: () => {
        createCopy({
          id,
          name: `${get(checklistDefinitionsByIds, `${id}.name`, '')} (Копия)`
        });
      },
      delete: () =>
        Modal.confirm({
          title: t('components.checklistsList.confirmChecklistDelete.title'),
          content: t('components.checklistsList.confirmChecklistDelete.description'),
          okText: t('components.checklistsList.confirmChecklistDelete.ok'),
          cancelText: t('components.checklistsList.confirmChecklistDelete.cancel'),
          okType: 'danger',
          onOk: async () => deleteChecklistDefinition({ id })
        })
      //   TODO: add copy
      //   Modal
    };

    actions[key]();
  };

  const getMenu = ({ id }) => (
    <Menu onClick={({ key }) => handleMenuClick({ key, id })}>
      {checklistDefinitionsByIds[id]?.status !== CHECKLIST_DEFINITION_STATUS.PUBLISHED.value && (
        <Menu.Item key="publish">
          <Icon icon={File} />
          <span>{t('components.checklistsList.menu.publish')}</span>
        </Menu.Item>
      )}

      <Menu.Item key="edit">
        <Icon icon={Edit} />
        <span>
          {checklistDefinitionsByIds[id]?.status === CHECKLIST_DEFINITION_STATUS.DRAFT.value
            ? t('components.checklistsList.menu.edit')
            : t('components.checklistsList.menu.view')}
          {t('components.checklistsList.checklistQuestionsStr')}
        </span>
      </Menu.Item>

      <Menu.Item key="settings">
        <Icon icon={Settings} />
        <span>{t('components.checklistsList.menu.settings')}</span>
      </Menu.Item>

      {checklistDefinitionsByIds[id]?.status !== CHECKLIST_DEFINITION_STATUS.ARCHIVED.value && (
        <Menu.Item key="copy">
          <Icon icon={Copy} />
          <span>{t('components.checklistsList.menu.copy')}</span>
        </Menu.Item>
      )}

      {checklistDefinitionsByIds[id]?.status !== CHECKLIST_DEFINITION_STATUS.ARCHIVED.value && (
        <Menu.Item key="archive">
          <Icon icon={Inbox} />
          <span>{t('components.checklistsList.menu.archive')}</span>
        </Menu.Item>
      )}

      {checklistDefinitionsByIds[id]?.status === CHECKLIST_DEFINITION_STATUS.DRAFT.value && (
        <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
          <Icon icon={Trash2} />
          <span>{t('components.checklistsList.menu.delete')}</span>
        </Menu.Item>
      )}
    </Menu>
  );

  const handleTableChange = (pagination, filters, sorter) => {
    const { pageSize, current } = pagination;
    updatePage({ size: pageSize.toString(), number: current.toString() });
    dispatch(updateSort(sorter));
  };

  const handleSearch = () => {
    setSearchFlag(!searchFlag);
    // Выставляем 1 страницу при поиске
    updatePage({ size: page?.size, number: '1' });
  };

  const handleInputChange = e => {
    setSearchText(e.target.value.toLowerCase());
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const columns = [
    {
      title: t('components.checklistsList.listColumns.name'),
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ellipsis: {
        showTitle: false
      },
      render: (name, { description }) => (
        <>
          <Tooltip title={name}>
            <SText>{name}</SText>
          </Tooltip>
          {!isEmpty(description) && (
            <IconButton
              tooltip={{ title: description }}
              button={{
                icon: <InfoCircleOutlined />,
                size: 'icon'
              }}
            />
          )}
        </>
      )
    },
    {
      title: t('components.checklistsList.listColumns.author'),
      dataIndex: 'creatorId',
      key: 'creatorId',
      width: 150,
      ellipsis: true,
      render: creatorId => <UserPreview disabled userId={creatorId} showAvatar truncateSize={25} />
    },
    {
      title: t('components.checklistsList.listColumns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 60,
      render: createdAt => <Text>{moment(createdAt).format('DD/MM/YYYY')}</Text>,
      sorter: sortableColumns.includes('createdAt')
    },
    {
      title: t('components.checklistsList.listColumns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: status => <ChecklistDefinitionStatusTag status={status} />
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: 25,
      fixed: 'right',
      render: (_, { id }) => {
        return (
          <Dropdown overlay={getMenu({ id })} trigger={['click']}>
            <Button icon={<Icon icon={MoreOutlined} />} />
          </Dropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col style={{ paddingBottom: '14px' }}>
        <Input
          value={searchText}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          style={{ width: 200 }}
        />
        <Button type="primary" onClick={handleSearch}>
          {loading ? <LoadingOutlined /> : <SearchOutlined />}
        </Button>
      </Col>
      <STable
        dataSource={checklistDefinitions}
        rowKey="id"
        size="small"
        loading={loading}
        columns={columns}
        border
        pagination={{
          pageSize: parseInt(page.size),
          current: parseInt(page.number),
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ['10', '25', '50']
        }}
        onChange={handleTableChange}
        // * NOTE: it doesn't work on resize
        scroll={{ y: window.innerWidth > 1320 ? 'calc(100vh - 286px)' : 'calc(100vh - 356px)' }}
      />
      <ColorZonesBeforePublishModal onOk={onPublish} />
    </>
  );
};

const mapStateToProps = state => {
  const { editingColorZonesBeforePublish } = state.uiChecklistEditor;
  const { ids, loading, page, totalCount } = state.checklistDefinitionsList;
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;
  const checklistDefinitions = uniq(ids)
    .map(id => checklistDefinitionsByIds[id])
    .filter(Boolean);

  return {
    checklistDefinitions,
    bindingsByIds: state.questionToGroupBindingsResource.byIds,
    questionGroupsByIds: state.questionGroupsResource.byIds,
    questionsByIds: state.questionsResource.byIds,
    checklistDefinitionsByIds,
    loading,
    page,
    editingColorZonesBeforePublish,
    totalCount: parseInt(totalCount) || 0
  };
};

const mapDispatchToProps = {
  updatePage: actions.updatePage,
  clearList: actions.clearList,
  loadChecklistDefinitions: operations.load,
  loadPrompts: promptsListOperations.load,
  loadAllQuestions: promptsQuestionAllListOperations.load,
  loadAllCustomFields: promptsCustomFieldAllListOperations.load,
  updateSort: actions.updateSort,
  updateChecklistDefinition: checklistDefinitionsResource.operations.updateById,
  deleteChecklistDefinition: checklistDefinitionsResource.operations.deleteById,
  copyChecklistDefinition: checklistDefinitionsResource.operations.copyById,
  setEditingColorZonesBeforePublish
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistsList));
