import { typeToRoute } from 'components/ClientInteractionLink';
import { CLIENT_INTERACTIONS_TYPES, COMMUNICATION_TYPES } from './constants';

export const getCommunicationType = (
  communicationType,
  clientInteractionType,
  isReview,
  isReview_
) => {
  if (!isReview_ && isReview) {
    return COMMUNICATION_TYPES.REVIEW;
  }
  if (clientInteractionType === COMMUNICATION_TYPES.CUSTOM) {
    return COMMUNICATION_TYPES.TEXT;
  }
  if (communicationType === CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN && !isReview) {
    return CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN;
  }
  if (communicationType === CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN && isReview) {
    return COMMUNICATION_TYPES.REVIEW;
  }
  return clientInteractionType;
};

export const getLink = (link, communicationType, clientInteractionType, id, isReview, query) => {
  if (link) return link;

  if (communicationType === CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN && !isReview) {
    return `communication-chains/${id}`;
  }

  if (communicationType === CLIENT_INTERACTIONS_TYPES.COMMUNICATION_CHAIN) {
    return (
      link || `/${isReview ? 'reviews' : typeToRoute[clientInteractionType] || 'reviews'}/${id}`
    );
  }

  return `/${
    isReview ? 'reviews' : typeToRoute[clientInteractionType] || 'reviews'
  }/${id}?${query}`;
};
