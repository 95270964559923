import { ContainerOutlined } from '@ant-design/icons';
import { Button, Modal, Skeleton, Tabs, Tooltip } from 'antd';
import IconButton from 'components/IconButton';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customFieldsResource } from 'redux/resources/customFields';
import { getCustomFieldsByKeys } from 'redux/selectors/customFields';
import styled from 'styled-components';
import CustomFieldsTab from './tabs/CustomFieldsTab';
import PhoneCallChain from './tabs/PhoneCallChain';
import SCard from '../Standard/SCard';
import { clientInteractionsResource } from '../../redux/resources/clientInteractions';
import CustomFieldsModal from './CustomFieldsModal';

const CommunicationInfoModal = ({ id, customFields = {}, phoneCallChainId = null, loading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [visible, setVisible] = useState(false);
  const customFieldsByKeys = useSelector(state => getCustomFieldsByKeys(state), isEqual);
  const [isEditMode, setIsEditMode] = useState(false);
  const [fieldValues, setFieldValues] = useState(customFields);
  const canShowCustomFields = !isEmpty(customFields);
  const [editMode, setEditMode] = useState(false);
  const [isActiveModeForSave, setIsActiveModeForSave] = useState(false);

  useEffect(() => {
    return () => {
      if (isEmpty(customFieldsByKeys)) {
        dispatch(
          customFieldsResource.operations.load({
            organization_id: organizationId,
            pagination: false
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      setVisible(false);
    };
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    setIsActiveModeForSave(false);
    setEditMode(false);
  };

  const handleSave = async () => {
    await dispatch(
      clientInteractionsResource.operations.updateClientInteractionCustomFields({
        client_interaction_id: id,
        organization_id: organizationId,
        fieldValues: JSON.stringify(fieldValues)
      })
    ).then(res => {
      if (res?.status === 'success') {
        setIsActiveModeForSave(false);
        setIsEditMode(false);
      }
    });
  };

  return (
    <>
      <Tooltip title={t('components.recordPlayer.viewCustomFields.tooltip')}>
        <IconButton
          icon={<ContainerOutlined />}
          shape="circle-outline"
          size="small"
          onClick={showModal}
          disabled={loading}
        />
      </Tooltip>
      <Modal
        visible={visible}
        maskClosable
        destroyOnClose
        onCancel={hideModal}
        bodyStyle={{ minHeight: 240 }}
        width={780}
        onOk={hideModal}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {isEditMode ? (
              <Button onClick={() => setIsEditMode(false)}>Выключить режим редактирования</Button>
            ) : (
              <Button onClick={() => setIsEditMode(true)}>Включить режим редактирования</Button>
            )}
            {isActiveModeForSave ? (
              <Button type="primary" onClick={handleSave}>
                {t('general.save')}
              </Button>
            ) : (
              <Button type="primary" onClick={hideModal}>
                {t('components.communicationInfoModal.ok')}
              </Button>
            )}
          </div>
        }
      >
        {isEmpty(customFieldsByKeys) ? (
          <SCard>
            <Skeleton active />
          </SCard>
        ) : (
          <StyledTabs
            defaultActiveKey={canShowCustomFields ? 'customFields' : 'phoneCallChain'}
            tabPosition="top"
            centered
          >
            <Tabs.TabPane
              tab={t('components.communicationInfoModal.title')}
              key="customFields"
              disabled={!canShowCustomFields}
            >
              {isEditMode ? (
                <CustomFieldsModal
                  customFields={customFields}
                  customFieldsByKeys={customFieldsByKeys}
                  setIsEditMode={setIsActiveModeForSave}
                  isEditMode={isActiveModeForSave}
                  fieldValues={fieldValues}
                  setFieldValues={setFieldValues}
                />
              ) : (
                <CustomFieldsTab
                  customFields={customFields}
                  customFieldsByKeys={customFieldsByKeys}
                />
              )}
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t('components.communicationInfoModal.chain')}
              key="phoneCallChain"
              disabled={!phoneCallChainId}
            >
              <PhoneCallChain callId={id} phoneCallChainId={phoneCallChainId} />
            </Tabs.TabPane>
          </StyledTabs>
        )}
      </Modal>
    </>
  );
};

const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    margin-top: -4px;
  }
  & .ant-tabs-nav {
    border-bottom: 1px solid var(--gray-border);
  }
  & .ant-tabs-nav .ant-tabs-tab {
    padding: 0 16px 12px 16px;
  }
`;

export default CommunicationInfoModal;
