import { Col, Input } from 'antd';
import { COLUMN_TYPES } from 'core/utils/constants';
import { debounce, difference } from 'lodash';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getCustomFieldsByKeysForReviews } from 'redux/selectors/customFields';
import { addColumn } from 'redux/ui/clientInteractions/reducer';
import { useTranslation } from 'react-i18next';
import { customFieldsResource } from 'redux/resources/customFields';
import { SettingsRow } from './styled';
import CustomFieldsColumns from './CustomFieldsColumns';
import ChecklistDefinitionsColumns from './ChecklistDefinitionsColumns';
import BasicColumns from './BasicColumns';

const { Search } = Input;

const AddColumn = ({
  tableColumns,
  addColumn,
  customFieldsByKeys = {},
  checklistDefinitionsIds = {},
  questionsChecklist = {},
  loadCustomFields,
  currentPage,
  setCurrentPage,
  totalPages,
  setTotalPages
}) => {
  const organizationId = useSelector(
    state => state.reduxTokenAuth.currentUser.attributes.user['organization-id']
  );
  const [searchQuery, setSearchQuery] = useState('');
  const { t } = useTranslation();
  const allColumnKeys = [
    ...Object.keys(COLUMN_TYPES),
    ...Object.keys(customFieldsByKeys),
    ...Object.keys(checklistDefinitionsIds)
  ];

  const availableColumns = difference(allColumnKeys, tableColumns);

  const getColumnTitle = column => {
    return questionsChecklist[column]
      ? questionsChecklist[column]?.name
      : t(COLUMN_TYPES[column]?.name) ||
          customFieldsByKeys[column]?.name ||
          checklistDefinitionsIds[column]?.name ||
          '';
  };
  const filteredColumns = availableColumns.filter(column =>
    getColumnTitle(column)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const basicColumns = filteredColumns.filter(
    column => !checklistDefinitionsIds[column] && !column.includes('custom_field/')
  );
  const checklistDefinitionsColumns = filteredColumns.filter(
    column => checklistDefinitionsIds[column] && !column.includes('custom_field/')
  );
  const customFieldsColumns = filteredColumns.filter(column => column.includes('custom_field/'));

  const handleSearchCustomFields = debounce(search => {
    loadCustomFields({
      organization_id: organizationId,
      page_number: 1,
      page_size: 10,
      search_input: search
    });
  }, 350);

  const handleSearchChange = e => {
    const searchValue = e.target.value || '';
    handleSearchCustomFields(searchValue);
    setSearchQuery(searchValue);
  };

  return (
    <SettingsRow>
      <Col span={24}>
        <Search
          placeholder={t('clientInteractionsPage.drawer.columns.searchColumns')}
          onChange={handleSearchChange}
          style={{ marginBottom: '16px' }}
        />

        <BasicColumns
          basicColumns={basicColumns}
          addColumn={addColumn}
          customFieldsByKeys={customFieldsByKeys}
          checklistDefinitionsIds={checklistDefinitionsIds}
          questionsChecklist={questionsChecklist}
        />

        <ChecklistDefinitionsColumns
          checklistDefinitionsIds={checklistDefinitionsIds}
          checklistDefinitionsColumns={checklistDefinitionsColumns}
          questionsChecklist={questionsChecklist}
          addColumn={addColumn}
          tableColumns={tableColumns}
          customFieldsByKeys={customFieldsByKeys}
        />

        <CustomFieldsColumns
          addColumn={addColumn}
          customFieldsColumns={customFieldsColumns}
          searchQuery={searchQuery}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
          customFieldsByKeys={customFieldsByKeys}
          checklistDefinitionsIds={checklistDefinitionsIds}
          questionsChecklist={questionsChecklist}
        />
      </Col>
    </SettingsRow>
  );
};

const mapStateToProps = state => {
  return {
    customFieldsByKeys: getCustomFieldsByKeysForReviews(state, true),
    tableColumns: state.uiClientInteractions.tableColumns,
    checklistDefinitionsIds: state.checklistDefinitionsResource.byIds,
    questionsChecklist: state.questionsResource.byIds
  };
};

const mapDispatchToProps = {
  addColumn,
  loadCustomFields: customFieldsResource.operations.load
};

export default connect(mapStateToProps, mapDispatchToProps)(AddColumn);
