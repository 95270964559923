import { Divider, Dropdown, Progress } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SText from 'components/Standard/SText';
import { useDispatch, useSelector } from 'react-redux';
import { workPlanTaskAssignmentsResource } from 'redux/resources/workPlanTaskAssignments';
import { getCurrentUserAssignmentsByTaskStatus } from 'redux/selectors/workPlanTasks';
import { isEqual, sum, orderBy, take } from 'lodash';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';
import moment from 'core/moment';
import { beatifyFloat } from 'core/utils/convertFloat';
import { loadData } from '../../../core/utils/loadData';

const WorkPlanTasksMenu = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const currentUserId = useSelector(state => state.reduxTokenAuth.currentUser.attributes.id);
  const tasks = useSelector(state => Object.values(state.workPlanTasksResource.byIds), isEqual);
  const reviewsByIds = useSelector(state => state.reviewsResource.byIds, isEqual);
  const workPlanTaskAssignments = useSelector(state => state.workPlanTaskAssignmentsResource);

  const loadAssignments = () => {
    dispatch(
      workPlanTaskAssignmentsResource.operations.load({
        pagination: false,
        filters: {
          reviewersIds: currentUserId,
          taskStatuses: [
            WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.in_progress.value,
            WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.pending.value
          ]
        },
        include: 'task.task_configuration'
      })
    );
  };

  const inProgressAssignments = useSelector(
    state =>
      orderBy(
        getCurrentUserAssignmentsByTaskStatus(state, {
          status: WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.in_progress.value
        }),
        'taskTimeFrom'
      ),
    isEqual
  );

  const pendingAssignments = useSelector(
    state =>
      orderBy(
        getCurrentUserAssignmentsByTaskStatus(state, {
          status: WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.pending.value
        }),
        'taskTimeFrom'
      ),
    isEqual
  );

  useEffect(() => {
    if (currentUserId && loadData(workPlanTaskAssignments)) loadAssignments();
  }, [currentUserId, tasks.length, Object.keys(reviewsByIds).length]);

  const getProgressPercentage = () => {
    const completedItemsCount = sum(
      inProgressAssignments.map(item => item?.completedTaskItemsCount)
    );
    const totalItemsCount = sum(inProgressAssignments.map(item => item?.totalTaskItemsCount));

    if (!completedItemsCount || completedItemsCount === 0) return 0;
    return beatifyFloat((completedItemsCount / totalItemsCount) * 100);
  };

  const assignmentsList = (
    <SCard style={{ width: 400 }}>
      <SRow margin="8px" type="flex" align="middle" justify="space-between">
        <SCol span={24}>
          <SText strong>{`${t('workPlanTasks.menu.total')}: ${getProgressPercentage()}%`}</SText>
        </SCol>
        {inProgressAssignments?.length !== 0 && (
          <>
            <SCol span={24}>
              <Divider style={{ margin: '12px 0px' }} />
            </SCol>
            <SCol span={24}>
              <SText strong style={{ fontSize: 12 }}>
                {`${t('workPlanTasks.menu.inProgress')}: `}
              </SText>
              {take(inProgressAssignments, 5).map(item => (
                <SRow
                  key={item?.configurationName}
                  type="flex"
                  align="middle"
                  justify="space-between"
                >
                  <SCol span={20}>
                    <SText ellipsis width="100%">
                      {item?.configurationName}
                    </SText>
                  </SCol>
                  <SCol span={4} textAlign="right">
                    <SText type="secondary" fontSize="12px">
                      {`${item?.completedTaskItemsCount}/${item?.totalTaskItemsCount}`}
                    </SText>
                  </SCol>
                </SRow>
              ))}
            </SCol>
          </>
        )}
        {pendingAssignments?.length !== 0 && (
          <>
            <SCol span={24}>
              <Divider style={{ margin: '12px 0px 10px 0px' }} />
            </SCol>
            <SCol span={24}>
              <SText strong fontSize="12px">
                {`${t('workPlanTasks.menu.pending')}: `}
              </SText>
              {take(pendingAssignments, 5).map(item => (
                <SRow
                  key={item?.configurationName}
                  type="flex"
                  align="middle"
                  justify="space-between"
                >
                  <SCol span={16}>
                    <SText ellipsis width="100%">{`${item?.configurationName}: `}</SText>
                  </SCol>
                  <SCol span={8} display="flex" justifyContent="flex-end">
                    <SText type="secondary" fontSize="12px">
                      {`${moment(item?.taskTimeFrom).format('DD/MM/YYYY, HH:mm')}`}
                    </SText>
                  </SCol>
                </SRow>
              ))}
            </SCol>
          </>
        )}
      </SRow>
    </SCard>
  );

  if ([...inProgressAssignments, ...pendingAssignments].length === 0) return null;

  return (
    <SRow type="flex" justify="end">
      <SCol span={24}>
        <StyledDropdown
          overlay={assignmentsList}
          trigger={['click']}
          placement="bottomRight"
          style={{ height: 48 }}
        >
          <StyledProgress
            type="circle"
            percent={getProgressPercentage()}
            width={35}
            format={res => `${res}%`}
          />
        </StyledDropdown>
      </SCol>
    </SRow>
  );
};

const StyledProgress = styled(Progress)`
  &.ant-progress {
    margin-bottom: 17px;
    cursor: pointer;
  }
  &.ant-progress .ant-progress-inner span {
    color: #ffffff;
    font-size: 10px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  margin-right: 10px;
  color: #a6adb4;
  :hover {
    color: white;
  }
  max-width: 155px;
`;

export default WorkPlanTasksMenu;
