export default {
  messages: {
    success: {
      appealSended: 'Ricorso inviato',
      appealCanceled: 'Ricorso annullato',
      appealDeleted: 'Ricorso cancellato',
      processingCompleted: 'Elaborazione completata',
      commentSaved: 'Commento salvato',
      commentAdded: 'Commento aggiunto',
      answerSended: 'Risposta inviata',
      templateUpdated: 'Modello aggiornato',
      templateAdded: 'Modello aggiunto',
      templateDeleted: 'Modello eliminato',
      integrationCompleted: 'Integrazione completata',
      filtersApplied: 'Filtri applicati',
      newTokenGenerated: 'Nuovo token generato',
      userMoved: "L'utente si è spostato con successo",
      taskStopped: 'Il compito è interrotto',
      taskCompleted: 'Compito completato',
      configurationDeleted: 'Configurazione eliminata',
      repeatingCanceled: 'Ripetizioni interrotte',
      settingsUpdated: 'Parametri aggiornati',
      transcriptionSent: 'Comunicazione inviata per la trascrizione',
      transcriptionUnsupportedCallLength:
        'Le chiamate di durata inferiore a 25 secondi non vengono trascritte o analizzate.'
    },
    warning: {
      failedTemplateUpdate: 'Impossibile aggiornare il modello',
      failedTemplateSave: 'Impossibile salvare il modello',
      failedSettingsUpdate: 'Impossibile aggiornare i parametri',
      limitExportReviews:
        'Stai tentando di scaricare più di 5000 valutazioni. Abbreviare il periodo per ridurre il numero di valutazioni esportate.'
    },
    error: {
      failedInstallIntegration: "Impossibile completare l'integrazione. Contattaci a info@qolio.io",
      failedRegisterOrganization:
        "Impossibile registrare l'organizzazione. Riprova o scrivici a info@qolio.io",
      failedMoveUser: "Impossibile spostare l'utente",
      failedStopTask: "Impossibile interrompere l'attività",
      failedDeleteConfiguration: 'Impossibile eliminare la configurazione',
      failedStopRepeating: 'Impossibile smettere di ripetere',
      failedCloseTask: "Impossibile completare l'attività"
    }
  }
};
