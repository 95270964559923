import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Col, Empty, Form, Input, Row, Tooltip, Typography } from 'antd';
import {
  getCustomFieldByKey,
  getCustomFieldsFieldValue,
  getCustomFieldValue,
  isCustomFieldVisible
} from 'core/utils/isCustomFieldVisible';
import { CUSTOM_FIELDS_TYPES } from '../../core/utils/constants';
import QolioAiIcon from '../QolioAiIcon';

const { Text } = Typography;

const CustomFieldsModal = ({
  customFields,
  customFieldsByKeys,
  isEditMode,
  setIsEditMode,
  fieldValues,
  setFieldValues
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const initialValues = {};
    Object.keys(customFields).forEach(key => {
      const customField = getCustomFieldByKey(customFieldsByKeys, key);
      const value = getCustomFieldValue(customFields, customField);
      initialValues[customField?.key] = {
        value,
        type: customField?.fieldType,
        id: customField?.id
      };
    });
    setFieldValues(initialValues);
  }, [customFields, customFieldsByKeys, setFieldValues]);

  const handleChange = (key, value, isTypeChange = false) => {
    setFieldValues(prev => ({
      ...prev,
      [key]: isTypeChange ? { ...prev[key], type: value } : { ...prev[key], value }
    }));
    if (!isEditMode) {
      setIsEditMode(true);
    }
  };

  const customFieldsOptions = Object.values(CUSTOM_FIELDS_TYPES).filter(
    type => type !== CUSTOM_FIELDS_TYPES.SELECT && type !== CUSTOM_FIELDS_TYPES.ENUM
  );

  if (isEmpty(customFields))
    return (
      <Row justify="center" align="middle">
        <Col>
          <Empty />
        </Col>
      </Row>
    );

  return (
    <Form layout="horizontal" style={{ width: '100%' }}>
      <Row>
        {Object.keys(customFields).map(key => {
          const customField = getCustomFieldByKey(customFieldsByKeys, key);
          const customFieldValue = getCustomFieldsFieldValue(fieldValues, customField);
          if (!isCustomFieldVisible(customField)) {
            return '';
          }

          return (
            <Col span={24} key={customField?.key}>
              <Form.Item labelAlign="left" style={{ margin: '4px' }}>
                <Row>
                  <Col span={8} flex="auto" style={{ alignItems: 'center', display: 'flex' }}>
                    {customField?.usedForAi && (
                      <QolioAiIcon styles={{ marginRight: '5px', paddingBottom: '2px' }} />
                    )}
                    <Tooltip title={customField?.name}>
                      <Text
                        strong
                        style={{
                          textOverflow: 'ellipsis',
                          width: '100%',
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                      >
                        {customField?.name}
                      </Text>
                    </Tooltip>
                  </Col>
                  <Col span={24} flex="auto" style={{ display: 'flex' }}>
                    <Input.TextArea
                      style={{
                        width: '100%',
                        maxHeight: '100px',
                        minHeight: '32px',
                        height: '32px'
                      }}
                      value={customFieldValue}
                      onChange={e => handleChange(customField?.key, e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

export default CustomFieldsModal;
