import { get } from 'redux-bees';

const userFieldsAnalyticsCommunicationsEndpoint = apiPath => ({
  getUserFieldsAnalyticsCommunications: {
    method: get,
    path: `${apiPath}/communication_table/widget`
  }
});

export default userFieldsAnalyticsCommunicationsEndpoint;
