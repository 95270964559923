export default {
  integrationsSettingsPage: {
    proceed: 'Continuar',
    save: 'Guardar',
    delete: 'Eliminar',
    sync: 'Sincronizar',
    selectSyncPeriod: 'Seleccionar período de sincronización',
    checkboxIsUpdateOldData: 'Actualizar datos antiguos',
    syncInternal: 'Also sync internal calls',
    deleteConfirm: {
      title: '¿Eliminar integración?',
      ok: 'Sí',
      cancel: 'No'
    },
    nameAndType: {
      name: 'Nombre',
      integrationName: 'Introduce el nombre de la integración.',
      type: 'Tipo',
      integrationType: 'Seleccione el tipo de integración',
      clientSecretPlaceholder: 'Ingrese Client Secret',
      clientProductCodePlaceholder: 'Ingrese Código producto',
      clientSaltPlaceholder: 'Ingrese Llave',
      clientProductCode: 'Código producto',
      clientSalt: 'Llave',
      enterPlaceholderTemplate: 'Ingrese',
      yourB24address: 'Tu dirección Bitrix 24',
      mangoApiKey: 'Código producto',
      mangoSalt: 'Llave',
      errors: {
        emptyURL: 'Por favor ingrese Endpoint',
        invalidURL: 'Has introducido una Endpoint no válida'
      }
    },
    integrationPage: {
      title: 'Integración',
      sync: 'Sincronización',
      manualSync: 'Manual synchronization',
      fields: 'Campos',
      users: 'Usuarios',
      integrationSettings: 'Configuración de integración',
      manualLinkText: 'Instrucciones para la integración',
      manualArticles: 'Instrucciones para configurar integraciones',
      customFieldsSettings: {
        title: 'Configuración de campos personalizados',
        addField: 'Agregar campo',
        deleteConfirm: {
          title: 'Eliminar campo personalizado',
          okText: 'Eliminar'
        },
        editor: {
          fieldName: 'Nombre de campo',
          fieldKey: 'Clave de campo',
          fieldType: 'Tipo de campo',
          usedForFilters: 'Se usa para filtrar',
          usedForReviews: 'Se usa para calificaciones',
          usedForAI: 'Utilizar para el procesamiento de AI',
          save: 'Guardar',
          edit: 'Editar',
          delete: 'Eliminar',
          deactivate: 'Desactivar',
          activate: 'Activar'
        },
        deactivateModal: {
          title: 'Desactivación del campo',
          content: '¿Está seguro de que desea desactivar el campo?',
          ok: 'Aceptar',
          messages: {
            fieldDeactivated: 'Campo desactivado',
            fieldActivated: 'Campo activado'
          }
        },
        exceededLimitModal: {
          title: 'Límite excedido',
          contentStart: 'La organización no puede tener más de ',
          contentEnd:
            'Por favor, elimina o desactiva otros campos personalizados para agregar nuevos.',
          ok: 'Aceptar'
        },
        validationModal: {
          title: 'Error de validación',
          keyOrNameAlreadyExists: 'Ya existe un campo adicional con esta clave o nombre',
          incorrectFormat:
            "Formato incorrecto: la clave de los campos adicionales solo puede contener letras latinas minúsculas, números y los símbolo '_'."
        }
      },
      limitSelectedFields: 'Límite en el número de campos adicionales',
      integrationFieldsList: {
        empty: 'El nombre del campo no está establecido',
        name: 'Nombre',
        type: 'Tipo'
      },
      testRequest: {
        title: 'Solicitud de prueba',
        success: 'Comunicación agregada al sistema, disponible en este enlace',
        failure: 'No se pudo agregar la comunicación de prueba',
        makeRequest: 'Hacer una solicitud'
      },
      updateConnection: 'Actualizar conexión',
      switchOn: 'Turn on synchronization',
      switchOff: 'Turn off synchronization',
      amocrmLink: 'Link to amoCRM account',
      amocrmLastSynced: 'Last synced',
      amocrmIntegrationCreated: 'Created at',
      amocrmConnectionStatus: 'Status',
      naumenConnectionStatus: 'Conexión a la cuenta Naumen',
      ednaConnectionStatus: 'Conexión a la cuenta Edna',
      mangoConnectionStatus: 'Status',
      amocrmActive: 'Active',
      amocrmInactive: 'Inactive',
      messages: {
        emptyIntegrationList: 'La lista de integraciones está vacía'
      }
    },
    messages: {
      integrationSuccessfullyCreate: 'La integración se creó correctamente',
      integrationSuccessfullyUpdated: 'La integración se ha actualizado con éxito.',
      integrationCreateFailed: 'No se pudo crear la integración'
    },
    addIntegration: {
      title: 'Agregar integración',
      yourIntegrations: 'Sus integraciones'
    },
    manageUserAccounts: {
      totalUsers: 'Usuarios',
      activeUsers: 'Activo',
      selectAll: 'Seleccionar todo',
      columns: {
        user: 'Empleado',
        email: 'Correo electrónico',
        id: 'ID',
        sync: 'Sincronizar',
        status: 'Estado en el sistema externo'
      },
      add: 'Agregar',
      downloadCSV: 'Ejemplo de descarga de CSV',
      manual: 'Manualmente',
      fromCSV: 'A través de CSV',
      sendingFile: 'Enviando un archivo ...',
      csvModal: {
        title: 'Felicitaciones',
        content: '¡Ha subido a todos los usuarios desde su archivo CSV!',
        okText: 'Continuar'
      },
      usersAlreadyInSystem: 'Qolio ya tiene usuarios con este correo electrónico',
      accountToUsers: 'Estas cuentas se agregarán a los usuarios',
      brokenEmail: 'El usuario no tiene una dirección de correo electrónico'
    },
    createCustomIntegration: {
      addUsersTitle: 'Agragar usuario'
    },
    createSaasIntegration: {
      errorMessage: 'Se produjo un error al sincronizar datos con',
      errorMessageContent:
        'Vuelva a instalar la aplicación o envíenos un correo electrónico a info@qolio.io',
      sync: 'Sincronizar'
    },
    customUserAccountForm: {
      enterEmail: 'Introduzca el correo electrónico',
      enterCorrectEmail: 'Introduzca el correo electrónico correcto',
      sync: 'Sincronizado',
      notSync: 'No sincroniza',
      enterId: 'Ingrese id'
    },
    sipuniAccountsImported:
      'Las cuentas de usuario se importan. Para configurarlos, debe vincularlos a cuentas en Qolio. Para hacer esto, ve a ',
    mangoAccountsImported:
      'Las cuentas de usuario se importan. Para configurarlos, debe vincularlos a cuentas en Qolio. Para hacer esto, ve a ',
    urlAndToken: { generate: 'Generar' },
    infoAlert: {
      message: 'La configuración de sincronización manual no está disponible.',
      description:
        'Para su organización, la sincronización se produce todos los días a las {{time}}.',
      syncAlreadyInProgress:
        'La sincronización está actualmente en progreso. Espere a que se complete o intente actualizar la página.',
      setTheRange:
        'Por favor, establezca un rango para la sincronización de datos. Tenga en cuenta que cuanto mayor sea el rango especificado, más tiempo tomará la sincronización. Siempre puede aumentar el rango de sincronización de datos en la configuración de integración.'
    },
    unsupportedField: 'Campo no admitido',
    changeChanel: {
      title: 'Cambio de canales',
      description:
        'Esta opción permite intercambiar los canales del gerente y del cliente en el texto de transcripción de la comunicación.'
    }
  }
};
