export default {
  communicationChain: {
    createChainModal: {
      title: 'Cadenas de comunicación',
      cancel: 'Cancelar',
      create: 'Crear',
      ok: 'Aceptar',
      successCreate: 'Cadena de comunicación creada con éxito',
      add: 'Agregar',
      successAdd: 'Cadena de comunicación actualizada con éxito',
      selectedCommunications: 'Comunicaciones seleccionadas',
      resultIsCalculated: 'El resultado de la verificación está siendo calculado',
      bulkActionCheckModal: {
        title: 'Crear cadena de comunicación',
        cancelTextModal:
          '¿Estás seguro de que quieres cancelar la creación de la cadena de comunicación?'
      },
      columns: {
        operator: {
          title: 'Nombre completo'
        },
        direction: {
          title: 'Dirección'
        },
        duration: {
          title: 'Duración'
        },
        clientPhoneNumber: {
          title: 'Número de teléfono'
        },
        score: {
          title: 'Puntuación'
        }
      },
      fields: {
        communicationChainId: {
          tooltip:
            'Este campo es opcional. Si es necesario, puedes seleccionar la CC requerida a la que se agregarán las comunicaciones seleccionadas.',
          placeholder: 'Seleccionar CC'
        },
        clientPhoneNumber: {
          placeholder: 'Número de cadena',
          emptyFieldMessage: 'Por favor, ingresa el número de cadena'
        },
        operator: {
          placeholder: 'Empleado',
          emptyFieldMessage: 'Por favor, selecciona un empleado'
        },
        startedAt: {
          placeholder: 'Fecha de comunicación',
          emptyFieldMessage: 'Por favor, selecciona la fecha de comunicación'
        },
        duration: {
          placeholder: 'Duración',
          emptyFieldMessage: 'Por favor, selecciona la duración'
        }
      }
    },
    infoMessages: {
      notEnoughCommunications:
        'Para crear una cadena de comunicación, se requieren al menos dos comunicaciones.',
      numberAlreadyExists: 'Una cadena de comunicación con este número ya existe.'
    },
    chainPage: {
      removeCommunicationModal: {
        title: '¿Eliminar la comunicación de la cadena?',
        ok: 'Eliminar',
        cancel: 'Cancelar',
        messages: {
          deleteSuccess: 'Comunicación eliminada'
        }
      },
      columns: {
        direction: {
          title: 'Dirección'
        },
        duration: {
          title: 'Duración'
        }
      }
    }
  }
};
